import React, { useState } from 'react';
import './Modeltraining.css';

const Modaltraining = () => {
    const [status,setstatus]=useState('Training not started');
    const [trainingfile,settrainingfile]=useState(null);

    const handlesettrainingfile=async(ev)=>{
          const file=ev.target.files[0];
          settrainingfile(file);

          console.log(trainingfile);
        
    }

    const handletraining=async()=>{
        const formData=new FormData();
        formData.append('file',trainingfile);
        formData.append('domain',localStorage.getItem('domainfortraining'));
        formData.append('authid',localStorage.getItem('adminauthid'));
        formData.append('apptoken',localStorage.getItem('apptokenfortraining'));

        fetch('https://do.zopins.com/finetunemodal',{
          method:'POST',
          body:formData
        })
        .then(response=>{
          response.json();
          setstatus('Data sumbited for training. Training may take 10 min to 24 hours. Please wait.')
          settrainingfile(null);
          console.log(response)
        })
        .catch(error=>{
          console.log(error);
        })
        settrainingfile(null);
        setstatus('Data sumbited for training. Training may take 10 min to 24 hours. Please wait.')
    }
    
  return <>
  <div className='trainingcontainer'>
    To train your chatbot , scrap the data of your website and use the generated file or create a custom data file in create Q/A and use that file .
    &nbsp;<span style={{color:'#0f0'}}>(Note - Training may take 10 minutes to 24 hours depending upon the size of your scrapped data.)</span>

   {/* <div>
    <input type="text" disabled className='trainingurlinput' placeholder={localStorage.getItem('domainfortraining')}/>
    <button className='scrapwebsitebtn'>Scrap website and download data</button>
   </div> */}
   <div className='traininghead'>Train your chatbot. Select a file and start a training job.</div>
   <div className="file-main">
   <div className="fileInputBox">
        <label id='fileLabel' htmlFor="fileInput">
        {trainingfile?<>
         {trainingfile.name}
        </>:<>
        Select File Here
        </>}
        </label>
        <input type="file" id='fileInput' name='fileInput' className='fileinputfortraining' accept='.jsonl'
        onChange={(ev)=>{
            handlesettrainingfile(ev)
        }}
        onChangeCapture={(ev)=>{
            handlesettrainingfile(ev)
        }}
        />
        <button className='fileinputbtn'
        onClick={()=>{handletraining()}}
        >Start training job</button>
   </div>
   <div className="training-status">
    Training Status : {status}
   </div>
   </div>
  </div>
  </>
}

export default Modaltraining;
