import { useCallback, useState } from "react";
import { Handle, Position} from "reactflow";


function Questionandanswer({ data, isConnectable }) {


  const fbauthid = localStorage.getItem("adminauthid");

  console.log(data);

  return (
    <>
      <div className="templatenodebox">
        <Handle
        type="target" 
        position={Position.Top} 
        className="targethandle"
        style={{ backgroundColor: "white", border: "2px solid green" }}
          isConnectable={isConnectable}
        />
        <div className="chatbotnode">

        <div className="nodequestion">
            <div className="nodelabel">Question</div>
            {data.message.value.question}
            </div>
                <div className="nodeanswer">
                    <div className="nodelabel">Answer</div>
                    {data.message.value.answer.message}
                    </div>
                  {data.message.value.answer.buttons && (
                    <>
                      {data.message.value.answer.buttons.map((button, index) => {
                        return (
                          <>
                            <div className="aitempbuttons" key={index + 1}>
                              <div className="aitempbtns">
                                {button.text}
                              </div>
                                <Handle
                                  type="source"
                                  id={button.text + fbauthid}
                                  style={{
                                    backgroundColor: "white",
                                    border: "2px solid green",
                                position:'relative',
                                    // position: "absolute",
                                    top:  -(index + 4) ,
                                    marginTop:"-4%",
                                    left:'100%'

                                  }}
                                  position={Position.Right}
                                  isConnectable={isConnectable}
                                >

                                </Handle>
                            </div>
                          </>
                        );
                      })}
                    </>
                  )}  

        </div>
        {!data.message.value.answer.buttons[0] && <>
            <Handle 
        type="source" 
        position={Position.Bottom} 
        className="targethandle"
        style={{ backgroundColor: "white", border: "2px solid green" }}
        id="b" isConnectable={isConnectable}/>
        
        </>}
      </div>
    </>
  );
}

export default Questionandanswer;
