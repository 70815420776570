import React, { useState } from 'react';

import './Webscrapping.css'
const Webscrapping = () => {

    return <>
        <div className='trainingcontainer'>
          Scrap your website data and download the jsonl file to train your chatbot. After Scrapping is done , a file with .jsonl extension will be automatically downloaded. Use that file to train your chatbot.
            <div>
    <input type="text" disabled className='trainingurlinput' placeholder='Enter your website url'/>
    <button className='scrapwebsitebtn'>Scrap website and download data</button>
   </div>
        </div>
    </>
}

export default Webscrapping;
