import { useCallback } from 'react';
import { Handle, Position, NodeToolbar } from 'reactflow';
import { useSelector } from 'react-redux';

const handleStyle = {
  left: 10
};
const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
};

function TextUpdaterNode2({ data, isConnectable }) {
  const fbauthid = localStorage.getItem('adminauthid')
  const aiactivetemplate = useSelector(state => state.user.aiactivetemplate);
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }
    , []);

  console.log('ai template in temp nodes', aiactivetemplate);

  return (
    <div className="templatenodebox">
      <Handle type="target"
        isConnectable={isConnectable}
        //  className='outputhandle'

        style={{ backgroundColor: 'white', border: '2px solid green' }}
      />
      <div className='templatenode'>
        <div className='templatedata'

        >
          {
            data.message.value.components.map(comp => {
              return <>
                {comp.type === 'HEADER' && <>
                  <div className='aitemphead'>{comp.text}</div>
                </>}

                {comp.type === 'BODY' && <>
                  <div className='aitempmessage'>
                    {comp.text}
                    {/* You have connectd w3axis . To know more about us , please visit our website or contact us. */}
                  </div>
                </>}

                {comp.type === 'BUTTONS' && <>
                  {
                    comp.buttons.map((button, index) => {
                      return <>
                        <div className='aitempbuttons' key={index + 1}>
                          <div className='aitempbtns'>


                            {button.text}
                          </div>
                            <Handle
                              type="source"
                              id={button.text + fbauthid}
                              
                              style={{
                                backgroundColor: "white",
                                border: "2px solid green",
                            position:'relative',
                                // position: "absolute",
                                top:  -(index + 6) ,
                                marginTop:"-4%",
                                left:'95%'

                              }}
                              position={Position.Right}
                              isConnectable={isConnectable}
                            >
                            </Handle>
                        </div>
                      </>

                    })
                  }
                </>}
              </>

            })
          }
        </div>
      </div>
    </div>
  );
}

export default TextUpdaterNode2;