import React, { useEffect,useState } from 'react'
import './Navbar.css'
import { Outlet } from 'react-router-dom'
import { Link,useNavigate } from 'react-router-dom'
import {Drawer,Divider} from 'rsuite'
import {signInWithPopup,GoogleAuthProvider} from 'firebase/auth'

import {useMediaQuery,useModelState} from '../../Custom_hooks/Custom'
import { auth } from '../../Firebase/firebase'

const Navbar = () => {
  const {isOpen,open,close}=useModelState(); 
  const isMobile=useMediaQuery('(max-width:412px)');
  const [isBlurred, setIsBlurred] = useState(false);

    const navigate=useNavigate();

    const handleScroll = () => {
      if (window.scrollY >= (window.innerHeight)/4) {
        setIsBlurred(true);
      } else {
        setIsBlurred(false);
      }
    };

    const handlemainnavigation=(path)=>{
       navigate(path);
       close();
    }
    const fetchuser=async()=>{
      const gotemail=localStorage.getItem('useremail');
      console.log(gotemail);
      fetch(`https://do.zopins.com/finduser`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: gotemail,
        })
      })
    .then(response=>{
     console.log(response);
   //  handlecompanycreation();
    })
    .catch(error=>{
      console.log({ message: "Database:Error(auth/user exists.)" });
      return  alert('User with this email already exists . Please signup with another email or try login.');
    })
    }
    const handlegooglesignup=async()=>{
      const provider = new GoogleAuthProvider();
      try {
        signInWithPopup(auth,provider)
        .then(async(usercredential)=>{
          localStorage.setItem('useremail',usercredential.user.email);
          localStorage.setItem('username',usercredential.user.displayName);
          localStorage.setItem('verifiedbygoogle','true');
        })
        .then(async(result)=>{
          console.log(result);
          const gotemail=localStorage.getItem('useremail');
          console.log(gotemail);
          fetch(`https://do.zopins.com/finduser`, {
            method: "POST",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email:localStorage.getItem('useremail'),
            })
          })
        .then(response=>{
         console.log(response);
         if(response.status===200){
           navigate('/signup');
         }
         else{
           console.log({ message: "Database:Error(auth/user exists.)" });
          alert('User with this email already exists . Please signup with another email or try login.');
         }
        })
        .catch(error=>{
          console.log(error);
        })
        })
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(()=>{
      localStorage.setItem('verifiedbygoogle','false')
         localStorage.removeItem('useremail');
         localStorage.removeItem('username');
         window.addEventListener('scroll', handleScroll);
         return () => {
           window.removeEventListener('scroll', handleScroll);
         };


    },[])

    const handleScrolltoheight = () => {
      window.scrollTo({
        top: 900, 
        behavior: 'smooth'
      });
    };
    const handleScrolltoheightindesktop = () => {
      window.scrollTo({
        top: 550, 
        behavior: 'smooth'
      });
    };
    
  return <>
  <Drawer open={isOpen} onClose={close} placement="left" style={{height:'100%',zIndex:'2000',width:'100%'}}>
       
       <div  style={{backgroundColor:"#041821",width:'100%',height:"15vw",position:'absolute',boxShadow:'2px 2px 12px gray'}}>
          <div style={{marginLeft:'30%'}}>
          <div to='/' >
           <div className="gradient_logo" 
           style={{fontSize:'10vw',color:'#16AEE8',fontWeight:'bolder',marginLeft:'35%'}}
           >ZOPINS</div>
           </div>
          </div>
       </div>
       
       <Drawer.Body style={{color:'#041821',marginTop:'-1.5%'}}>
            <br /><br />
           <div style={{marginTop:'6%',marginLeft:'-8%'}}><div onClick={()=>{handlemainnavigation('/')}} style={{fontSize:'6vw',color:'#041821',textDecoration:'none',fontWeight:'bolder'}}>Home</div>
           <Divider/>
           </div>
           <div style={{marginTop:'6%',marginLeft:'-8%'}} >
            <div   onClick={()=>{
             // handlemainnavigation('/services')
              handleScrolltoheight()
              close();
              }} style={{fontSize:'6vw',color:'#041821',textDecoration:'none',fontWeight:'bolder'}}>Services</div>
           
           <Divider/>
           </div>
           <div style={{marginTop:'6%',marginLeft:'-8%'}}>
           <div  onClick={()=>{handlemainnavigation('/privacypolicy')}} style={{fontSize:'6vw',color:'#041821',textDecoration:'none',fontWeight:'bolder'}}>Privacy Policy</div>
           
           <Divider/>
           </div>
           <div style={{marginTop:'6%',marginLeft:'-8%'}}>
           <div   onClick={()=>{handlemainnavigation('/termsandconditions')}} style={{fontSize:'6vw',color:'#041821',textDecoration:'none',fontWeight:'bolder'}}>Terms and Conditions</div>
           
           <Divider/>
           </div>
           <div style={{marginTop:'6%',marginLeft:'-8%'}}>
           <div onClick={()=>{handlemainnavigation('/aboutus')}}  style={{fontSize:'6vw',color:'#041821',textDecoration:'none',fontWeight:'bolder'}}>About Us</div>
           
           <Divider/>
           </div>
           <div style={{marginTop:'6%',marginLeft:'-8%'}}>
           <div  onClick={()=>{handlemainnavigation('/login')}}  style={{fontSize:'6vw',color:'#16AEE8',textDecoration:'none',fontWeight:'bolder'}}>Login</div>
           
           <Divider/>
           </div>
           <div style={{marginTop:'6%',marginLeft:'-8%'}}>
           <div  
                onClick={()=>{handlegooglesignup()}}
             style={{fontSize:'6vw',color:'#16AEE8',textDecoration:'none',fontWeight:'bolder'}}>Signup</div>
           
           <Divider/>
           </div>
       </Drawer.Body>
     </Drawer>
 <div className={!isBlurred?"navbarcontainer":"blurnavbar"}
 >
   <div className='navelements'
   >

    <img src="https://i.postimg.cc/XvK4p6Yy/z-logo-wire-Recovered-Recovered-1.png" alt="Company Logo" className='logo' />
    <div className='navs' 
      style={isMobile?{display:'none'}:{}}
    >
    <div className='navele' 
    onClick={()=>{navigate('/')}}
    >
      {/* <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30"
style={{fill:"#16aee8"}}
className='navicons'
>
    <path d="M 15 2 A 1 1 0 0 0 14.300781 2.2851562 L 3.3925781 11.207031 A 1 1 0 0 0 3.3554688 11.236328 L 3.3183594 11.267578 L 3.3183594 11.269531 A 1 1 0 0 0 3 12 A 1 1 0 0 0 4 13 L 5 13 L 5 24 C 5 25.105 5.895 26 7 26 L 23 26 C 24.105 26 25 25.105 25 24 L 25 13 L 26 13 A 1 1 0 0 0 27 12 A 1 1 0 0 0 26.681641 11.267578 L 26.666016 11.255859 A 1 1 0 0 0 26.597656 11.199219 L 25 9.8925781 L 25 6 C 25 5.448 24.552 5 24 5 L 23 5 C 22.448 5 22 5.448 22 6 L 22 7.4394531 L 15.677734 2.2675781 A 1 1 0 0 0 15 2 z M 18 15 L 22 15 L 22 23 L 18 23 L 18 15 z"></path>
</svg> */}
<div className='navele'
onClick={()=>{
  localStorage.setItem('verifiedbygoogle','false');
  localStorage.removeItem('useremail');
  localStorage.removeItem('username');
}}
>Home</div>
      </div>
    <div className='navele'
    onClick={()=>{
      handleScrolltoheightindesktop()
    }}
    >Services</div>
    <div className='navele'
    onClick={()=>{navigate('/privacypolicy')}}
    >Privacy</div>
    <div className='navele'
    onClick={()=>{navigate('/termsandconditions')}}
    
    >Terms </div>

    {/* <div className='navele'>About us</div> */}
    </div>
    {isMobile?<>
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48"
      className='homemenu'
      onClick={open}
      >
<linearGradient id="9iHXMuvV7brSX7hFt~tsna_Rdp3AydLFY2A_gr1" x1="12.066" x2="34.891" y1=".066" y2="22.891" gradientUnits="userSpaceOnUse"><stop offset=".237" stop-color="#3bc9f3"></stop><stop offset=".85" stop-color="#1591d8"></stop></linearGradient><path fill="url(#9iHXMuvV7brSX7hFt~tsna_Rdp3AydLFY2A_gr1)" d="M43,15H5c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h38c1.1,0,2,0.9,2,2v2C45,14.1,44.1,15,43,15z"></path><linearGradient id="9iHXMuvV7brSX7hFt~tsnb_Rdp3AydLFY2A_gr2" x1="12.066" x2="34.891" y1="12.066" y2="34.891" gradientUnits="userSpaceOnUse"><stop offset=".237" stop-color="#3bc9f3"></stop><stop offset=".85" stop-color="#1591d8"></stop></linearGradient><path fill="url(#9iHXMuvV7brSX7hFt~tsnb_Rdp3AydLFY2A_gr2)" d="M43,27H5c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h38c1.1,0,2,0.9,2,2v2C45,26.1,44.1,27,43,27z"></path><linearGradient id="9iHXMuvV7brSX7hFt~tsnc_Rdp3AydLFY2A_gr3" x1="12.066" x2="34.891" y1="24.066" y2="46.891" gradientUnits="userSpaceOnUse"><stop offset=".237" stop-color="#3bc9f3"></stop><stop offset=".85" stop-color="#1591d8"></stop></linearGradient><path fill="url(#9iHXMuvV7brSX7hFt~tsnc_Rdp3AydLFY2A_gr3)" d="M43,39H5c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h38c1.1,0,2,0.9,2,2v2C45,38.1,44.1,39,43,39z"></path>
</svg>
    </>:<>
    <div className='navauths'
    >
      {
       localStorage.getItem('verifiedbygoogle')==='false'?<>
      <div className='navlogin signupbtn'
    onClick={()=>{handlegooglesignup()}}
    >
      Google Signup 
    </div>
       </>:<>
       
       </>
      }

    <div className='navlogin'
     onClick={()=>{navigate('/login')}}
    >
      LOGIN
    </div>

    </div>
    </>}

   </div>

         
        </div>
        <Outlet/>
  </>
}

export default Navbar

