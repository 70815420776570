import "./Aiflow.css";
import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { useCustomState } from "./Aicustomhook";
import ReactFlow, {
  addEdge,
  Background,
  ReactFlowProvider,
  useStoreApi,
  updateEdge,
  getConnectedEdges,
  MiniMap,
} from "reactflow";
import {useMediaQuery} from '../../Custom_hooks/Custom'
import { useDispatch, useSelector } from "react-redux";
import { setaiactivetemplate, setainodes } from "../../Reduxstore/store";

import "reactflow/dist/style.css";
import Flowstart from "./Flowstart";
import TextUpdaterNode2 from "./TemplateNode";
import Simpletext from "./Simpletext";
import Questionandanswer from "./Questionandanswer";
import Outgoingtextmessage from "./Outgoingtextmsg";

let id = 0;
const getId = () => `dndnode_${id++}`;

const MIN_DISTANCE = 150;

const Flow = ({ usedin, domainfornodeflow }) => {

  console.log("domainfornode", domainfornodeflow);
  const dispatch = useDispatch();
  const isMobile=useMediaQuery('(max-width:412px)');
  const [nodetype, setnodetype] = useState("");
  const edgeUpdateSuccessful = useRef(true);
  const [istempleteboxactive, setistemplateboxactive] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [popup, setpopup] = useState(false);
  const [flowname, setflowname] = useState();
  const [isflowsaved, setisflowsaved] = useState(false);
  const [loader, setloader] = useState(false);
  const [chatbotnodes, setchatbotnodes] = useState([]);
  const store = useStoreApi();
  const { nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange } =
    useCustomState();
  const [myflows, setmyflows] = useState([]);
  const [activeflow, setactiveflow] = useState(null);
  const [chatbotnode,setchatbotnode]=useState(null);
  // const [nodes, setNodes,onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges,onEdgesChange] = useEdgesState(initialEdges);

  const waba_id = useSelector((state) => state.user.wabaid);
  const aiactivetemp = useSelector((state) => state.user.aiactivetemplate);
  const ainodes = useSelector((state) => state.user.ainodes);
  const onConnect = useCallback((params) => {
    setEdges((eds) =>
      addEdge({ ...params, animated: true, style: { stroke: "white" } }, eds)
    );
  }, []);

  const [approvedtemplates, setapprovedtemplates] = useState([]);
  const nodeType = useMemo(
    () => ({
      questionandanswer: Questionandanswer,
      whatsapptemplate: TextUpdaterNode2,
      textselector: Simpletext,
      flowstart: Flowstart,
      outgoingtextmessage: Outgoingtextmessage,
    }),
    []
  );

  const connectedEdges = getConnectedEdges(nodes, edges);
  console.log("connected edges", connectedEdges);
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const fetchtemplates = async () => {
    const response = await fetch(
      `https://do.zopins.com/myalltemplates/${waba_id}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-origin": "*",
        },
      }
    );
    const data = await response.json();
    setapprovedtemplates(data.data);
    console.log("templates", data.data);
  };

  useEffect(() => {
    //console.log('new nodes',nodes);
    dispatch(setainodes(nodes));
    fetchtemplates();
    const getmyflows = async () => {
      const response = await fetch(
        `https://do.zopins.com/getaiflow/${localStorage.getItem(
          "adminauthid"
        )}`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-origin": "*",
          },
        }
      );
      const data = await response.json();
      setmyflows(data.Aiflow);
    };
    getmyflows();

    if(usedin==='bots'){
      fetchsavednodes();
    }
  }, [nodes]);

  const fetchtemplatedata = async (tempid) => {
    try {
      const response = await fetch(
        `https://do.zopins.com/gettemplate/${tempid}`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-origin": "*",
          },
        }
      );
      const data = await response.json();
      dispatch(setaiactivetemplate(data));
      //  console.log('ai active template',aiactivetemp);
    } catch (error) {
      console.log(error);
    }
  };

  const getClosestEdge = useCallback((node) => {
    const { nodeInternals } = store.getState();
    const storeNodes = Array.from(nodeInternals.values());

    const closestNode = storeNodes.reduce(
      (res, n) => {
        if (n.id !== node.id) {
          const dx = n.positionAbsolute.x - node.positionAbsolute.x;
          const dy = n.positionAbsolute.y - node.positionAbsolute.y;
          const d = Math.sqrt(dx * dx + dy * dy);

          if (d < res.distance && d < MIN_DISTANCE) {
            res.distance = d;
            res.node = n;
          }
        }

        return res;
      },
      {
        distance: Number.MAX_VALUE,
        node: null,
      }
    );

    if (!closestNode.node) {
      return null;
    }

    const closeNodeIsSource =
      closestNode.node.positionAbsolute.x < node.positionAbsolute.x;

    return {
      id: closeNodeIsSource
        ? `${closestNode.node.id}-${node.id}`
        : `${node.id}-${closestNode.node.id}`,
      source: closeNodeIsSource ? closestNode.node.id : node.id,
      target: closeNodeIsSource ? node.id : closestNode.node.id,
      animated: true,
      type: "straight",
      style: { stroke: "red" },
    };
  }, []);

  const onNodeDrag = useCallback(
    (_, node) => {
      const closeEdge = getClosestEdge(node);

      setEdges((es) => {
        const nextEdges = es.filter((e) => e.className !== "temp");

        if (
          closeEdge &&
          !nextEdges.find(
            (ne) =>
              ne.source === closeEdge.source && ne.target === closeEdge.target
          )
        ) {
          closeEdge.className = "temp";
          nextEdges.push(closeEdge);
        }

        return nextEdges;
      });
    },
    [getClosestEdge, setEdges]
  );

  const onNodeDragStop = useCallback(
    (_, node) => {
      const closeEdge = getClosestEdge(node);
      setEdges((es) => {
        const nextEdges = es.filter((e) => e.className !== "temp");

        if (
          closeEdge &&
          !nextEdges.find(
            (ne) =>
              ne.source === closeEdge.source && ne.target === closeEdge.target
          )
        ) {
          nextEdges.push(closeEdge);
        }
        return nextEdges;
      });
    },
    [getClosestEdge]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = (event) => {
    event.preventDefault();
    const type = event.dataTransfer.getData("application/reactflow");
    if (!type) return;
    const position = reactFlowInstance.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });
    let nodedata;
    if(usedin==='bots'){
      nodedata = {
        id: getId(),
        message: {
          type: "node",
          value: chatbotnode.details,
        },
      };
    }
    else{
      if (type === "whatsapptemplate") {
        nodedata = {
          id: getId(),
          message: {
            type: "template",
            value: aiactivetemp,
          },
        };
      } else {
        nodedata = {
          id: getId(),
          message: {
            type: "text",
            value: "",
          },
        };
      }

    }
    const newNode = {
      id: getId(),
      type: type,
      position: position,
      data: nodedata,
    };
    setNodes([...ainodes, newNode]);
    dispatch(setainodes([...ainodes, newNode]));
  };

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const handlegetainame = () => {

    setpopup(!popup);
  };

  const handlesaveaiflow = async () => {
    setloader(true);
    //console.log(ainodes);
    const data = {
      auth_id: localStorage.getItem("adminauthid"),
      flowdata: {
        name: flowname,
        isapplied: true,
        nodes: ainodes,
        connection: edges,
      },
    };
    fetch(`https://do.zopins.com/saveflow`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        console.log(response);
        setloader(false);
        setisflowsaved(true);
        setInterval(() => {
          setisflowsaved(false);
        }, 3000);
        setpopup(!popup);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchsavednodes = async () => {
    try {
      const response = await fetch(
        `https://do.zopins.com/getsavednodes/${localStorage.getItem('domainapptoken')}`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-origin": "*",
          },
        }
      );
      const data = await response.json();
      console.log(data);
      setchatbotnodes(data.chatbot_nodes)
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>

    {isMobile && <>
      <div className="alert">
        You can not use this feature in mobile. Please use a desktop.
      </div>
    </>
    }
    {!isMobile && <>
      {popup && (
        <>
          <div className="namepopup">
            <div className="flowlable">Enter Ai flow name</div>
            <input
              type="text"
              placeholder="Enter name"
              className="flowinput"
              value={flowname}
              onChange={(ev) => {
                setflowname(ev.target.value);
              }}
              onInputCapture={(ev) => {
                setflowname(ev.target.value);
              }}
            />
            <div
              className="savebtn2"
              onClick={() => {
                handlesaveaiflow();
              }}
            >
              {loader ? (
                <>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </>
              ) : isflowsaved ? (
                <>Flow Saved</>
              ) : (
                <>Submit</>
              )}
            </div>
          </div>
        </>
      )}
      {usedin === "bots"  && (
        <>
          <div className="activeflowname">{localStorage.getItem('domainfornodes')}</div>
        </>
      )}
      {activeflow && (
        <>
          <div className="activeflowname">Template - {activeflow.name}</div>
        </>
      )}
      {activeflow ? (
        <>
          <div
            className="saveflowbtn"
            onClick={() => {
              setactiveflow(null);
            }}
            style={{ backgroundColor: "green" }}
          >
            New Flow
          </div>
        </>
      ) : (
        <>
          <div
            className="saveflowbtn"
            onClick={() => {
              handlegetainame();
            }}
          >
            Save Flow
          </div>
        </>
      )}
      <div className="myaiflows">
        <div className="myflowshead">My AI Flows</div>
        <div className="myflowsmaincontainer">
          {usedin !== "bots" && (
            <>
              {myflows &&
                myflows.map((flow, index) => {
                  return (
                    <div
                      key={index}
                      className="myflowscontainer"
                      // style={activeflow.name===flow.name?{color:'green',fontSize:'1.2vw'}:{}}
                      onClick={() => {
                        setactiveflow(flow);
                        console.log(activeflow);
                      }}
                      onClickCapture={() => {
                        setactiveflow(flow);
                        console.log(activeflow);
                      }}
                    >
                      <div className="myflowname">{flow.name}</div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
      <div
        className="aitemplatescontainer"
        onClick={() => setistemplateboxactive(!istempleteboxactive)}
      >
        <div className="aitemplatesbox">
          <div
            style={{ color: "white", marginLeft: "3%", marginBottom: "15%" }}
          >
            {usedin === "bots" ? (
              <>Select Nodes for Flow</>
            ) : (
              <>Setup Your AI Chat Flow</>
            )}
          </div>

          {usedin !== "bots" && (
            <>
              <div
                className="nodes flowstarter"
                draggable
                onDragStart={(e) => onDragStart(e, "flowstart")}
                onDoubleClick={() => {
                  setnodetype("flowstart");
                  console.log(nodetype);
                }}
              >
                Flow Start
              </div>
              <div
                className="nodes simple"
                draggable
                onDragStart={(e) => onDragStart(e, "textselector")}
                onDoubleClick={() => {
                  setnodetype("textselector");
                  console.log(nodetype);
                }}
              >
                Incoming text message
              </div>
              <div
                className="nodes simple"
                draggable
                onDragStart={(e) => onDragStart(e, "outgoingtextmessage")}
                onDoubleClick={() => {
                  setnodetype("outgoingtextmessage");
                  console.log(nodetype);
                }}
                onClickCapture={() => {
                  setnodetype("outgoingtextmessage");
                  console.log(nodetype);
                }}
              >
                Outgoing text message
              </div>
            </>
          )}
          {/* {
            usedin==='bots' && <>
              <div style={{ color: "white", marginLeft: "2%" }}>
                Custom Node
              </div>
            </>
          } */}

          {/* {usedin === "bots" && (
            <>
              <div
                className="nodes simple"
                draggable
                onDragStart={(e) => onDragStart(e, "questionandanswer")}
                onDoubleClick={() => {
                  setnodetype("questionandanswer");
                  console.log(nodetype);
                }}
                onClickCapture={() => {
                  setnodetype("questionandanswer");
                  console.log(nodetype);
                }}
              >
               
              </div>
              <hr style={{marginLeft:'3%'}} />
            </>
          )} */}

          {usedin !== "bots" && (
            <>
              <div style={{ color: "white", marginLeft: "2%" }}>
                Meta Templates
              </div>
              {approvedtemplates &&
                approvedtemplates.map((node, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="nodes"
                        draggable
                        onDragStart={(e) => onDragStart(e, "whatsapptemplate")}
                        onDoubleClick={() => {
                          fetchtemplatedata(node.id);
                          setnodetype("whatsapptemplate");
                        }}
                        onClick={() => {
                          fetchtemplatedata(node.id);
                        }}
                        onClickCapture={() => {
                          fetchtemplatedata(node.id);
                        }}
                        onMouseOver={() => {
                          fetchtemplatedata(node.id);
                        }}
                      >
                        {node.name}
                      </div>
                    </>
                  );
                })}
            </>
          )}

          {usedin === "bots" && (
            <>
              <div style={{ color: "white", marginLeft: "2%" }}>
                Your Nodes
              </div>
              {chatbotnodes &&
                chatbotnodes.map((node, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="nodes"
                        draggable
                        onDragStart={(e) => onDragStart(e, "questionandanswer")}
                        onDoubleClick={() => {
                          setnodetype("questionandanswer");
                          setchatbotnode(node);
                          console.log(chatbotnode)
                        }}
                        onClick={() => {
                          setchatbotnode(node);
                          console.log(chatbotnode)
                        }}
                        onClickCapture={() => {
                          setchatbotnode(node);
                          console.log(chatbotnode)
                        }}
                        onMouseOver={() => {
                          setchatbotnode(node);
                          console.log(chatbotnode)
                        }}
                      >
                        {node.name}
                      </div>
                    </>
                  );
                })}
            </>
          )}
        </div>
      </div>
      {activeflow ? (
        <>
          <ReactFlow
            nodes={activeflow.nodes}
            edges={activeflow.connection}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDrag={onNodeDrag}
            onNodeDragStop={onNodeDragStop}
            onConnect={onConnect}
            style={{ background: "black" }}
            nodeTypes={nodeType}
          >
            <Background color="white" gap={15} />
          </ReactFlow>
        </>
      ) : (
        <>
          <ReactFlow
            nodes={ainodes}
            edges={edges}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDrag={onNodeDrag}
            onNodeDragStop={onNodeDragStop}
            onConnect={onConnect}
            style={{ background: "black" }}
            nodeTypes={nodeType}
            
          >
            <Background color="white" gap={15} />
          </ReactFlow>
        </>
      )}
      </>}
    </>

  );
};

const Aiflow = ({ usedin }) => {
  return (
    <>
      <div className="aimaincontainer">
        <ReactFlowProvider>
          <Flow usedin={usedin} />
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default Aiflow;
