import React from 'react'
import { useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import './Elements.css'
const Superdashboard = () => {
    

  return <>
    <div className='dashboardcontainer'>
    <div className='linechart'>
            <div className='newuserhead'>Number of Companies Increased Per Day</div>
            <LineChart
      xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
      series={[
        {
          data: [2, 5.5, 2, 8.5, 1.5, 5],
        },
      ]}
      width={400}
      height={250}
    />
        </div>
        <div className='linechart'>
            <div className='newuserhead' >Companies Data Analysis for Crm Usage</div>
            <div  className='piechart'>
            <PieChart
  series={[
    {
      data: [
        { id: 0, value: 10, label: 'series A' },
        { id: 1, value: 15, label: 'series B' },
        { id: 2, value: 20, label: 'series C' },
      ],
    },
  ]}
  width={400}
  height={200}
/>
            </div>
        </div>

    </div>
        <div className='analysis'>
          <div className='analyaishead'>Analysis</div>

        </div>
  </>
}

export default Superdashboard
