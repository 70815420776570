import{
    useNodesState,
    useEdgesState,
  
  } from 'reactflow';

  const initialNodes = [];
const initialEdges = [];

export const useCustomState = () => {
    const [nodes, setNodes,onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges,onEdgesChange] = useEdgesState(initialEdges);

  return { nodes, setNodes,onNodesChange,edges, setEdges,onEdgesChange};
};