import React, { useState } from 'react';
import './Supersidebar.css';
import {Outlet} from 'react-router-dom';
import Superadminhome from '../Elements/Superadminhome';
import { useDispatch } from 'react-redux';
import {setissupervalidated} from '../../Reduxstore/store'

import { useNavigate } from 'react-router-dom';

const Supersidebar = () => {
  const dispatch=useDispatch()
    const [active,setactive]=useState('dashboard');
    const navigate=useNavigate();
  return <>
    <div className='supersidebarcontainer'>
      <div className='supersidehead'>
        {/* <div className='logo'>
            <img src="https://i.postimg.cc/0QWt3s4w/z-logo-wire-Recovered-Recovered.png" alt="" className='mainlogo'/>
        </div> */}
        <div className='logoname'>
          Zopins
        </div>
        <div className='supernavcontainer'>
            <div className='supernavs'
            onClick={()=>setactive('dashboard')}
            style={active==='dashboard'?{backgroundColor:'#054864',border:'1px solid #07394f'}:{}}
            >
            <img width="64" height="64" src="https://img.icons8.com/pastel-glyph/64/16aee8/restaurant-menu.png" alt="restaurant-menu" className='supericons'/>
            <div className='navname'>Dashboard</div>
            </div>

            <div className='supernavs'
            onClick={()=>setactive('users')}
            style={active==='users'?{backgroundColor:'#054864',border:'1px solid #07394f'}:{}}
            >
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50"
            className='supericons'
style={{fill:"#16aee8"}}>
<path d="M 3 0 C 2.449219 0 2 0.449219 2 1 L 2 37 C 2 37.554688 2.449219 38 3 38 L 32.625 38 L 46.34375 49.75 C 46.527344 49.910156 46.765625 50 47 50 C 47.140625 50 47.273438 49.96875 47.40625 49.90625 C 47.761719 49.742188 48 49.390625 48 49 L 48 1 C 48 0.449219 47.554688 0 47 0 Z M 25 9 C 27.210938 9 29 10.789063 29 13 C 29 15.210938 27.210938 17 25 17 C 22.789063 17 21 15.210938 21 13 C 21 10.789063 22.789063 9 25 9 Z M 14 15 C 15.65625 15 17 16.34375 17 18 C 17 19.65625 15.65625 21 14 21 C 12.34375 21 11 19.65625 11 18 C 11 16.34375 12.34375 15 14 15 Z M 36 15 C 37.65625 15 39 16.34375 39 18 C 39 19.65625 37.65625 21 36 21 C 34.34375 21 33 19.65625 33 18 C 33 16.34375 34.34375 15 36 15 Z M 25 21 C 25.421875 21 25.804688 21.027344 26.1875 21.0625 C 26.433594 21.085938 26.679688 21.121094 26.90625 21.15625 C 26.925781 21.160156 26.949219 21.152344 26.96875 21.15625 C 27.019531 21.164063 27.074219 21.179688 27.125 21.1875 C 27.359375 21.230469 27.597656 21.257813 27.8125 21.3125 C 27.835938 21.316406 27.851563 21.339844 27.875 21.34375 C 31.222656 22.234375 32 24.625 32 25.59375 L 32 27 L 18 27 L 18 25.59375 C 18 24.625 18.777344 22.234375 22.125 21.34375 C 22.148438 21.335938 22.164063 21.320313 22.1875 21.3125 C 22.402344 21.261719 22.644531 21.226563 22.875 21.1875 C 22.929688 21.179688 22.976563 21.164063 23.03125 21.15625 C 23.050781 21.152344 23.074219 21.160156 23.09375 21.15625 C 23.320313 21.121094 23.566406 21.085938 23.8125 21.0625 C 24.1875 21.027344 24.578125 21 25 21 Z M 14 23 C 15.019531 23 15.851563 23.183594 16.53125 23.4375 C 16.15625 24.277344 16 25.058594 16 25.59375 L 16 27 L 9 27 L 9 26.09375 C 9 25.292969 10.199219 23 14 23 Z M 36 23 C 39.800781 23 41 25.292969 41 26.09375 L 41 27 L 34 27 L 34 25.59375 C 34 25.058594 33.84375 24.277344 33.46875 23.4375 C 34.148438 23.183594 34.980469 23 36 23 Z"></path>
</svg>
  
            <div className='navname'>Users</div>
            </div>
            <div className='supernavs'
            onClick={()=>setactive('settings')}
            style={active==='settings'?{backgroundColor:'#054864',border:'1px solid #07394f'}:{}} 
            >
            <img width="64" height="64" src="https://img.icons8.com/ios/50/16aee8/book--v1.png" alt="external-logout-user-interface-febrian-hidayat-glyph-febrian-hidayat" className='supericons'/>
            <div className='navname'>Settings</div>
            </div>

            <div className='supernavs'>
            <img width="64" height="64" src="https://img.icons8.com/external-febrian-hidayat-glyph-febrian-hidayat/64/16aee8/external-logout-user-interface-febrian-hidayat-glyph-febrian-hidayat.png" alt="external-logout-user-interface-febrian-hidayat-glyph-febrian-hidayat" className='supericons'/>
            <div className='navname'
             onClick={()=>{
              navigate('/');
              dispatch(setissupervalidated(false))
            
            }}
            >Logout</div>
            </div>

        </div>

      </div>

    </div>
    <Superadminhome active={active}/>

    <Outlet/>
  </>
}

export default Supersidebar;
