import { useCallback, useState } from 'react';
import { Handle, Position,NodeToolbar} from 'reactflow';

import { useSelector,useDispatch} from 'react-redux';
import {setainodes,setnewnodes} from '../../Reduxstore/store'
const handleStyle = { left: 10 };

function Flowstart({ data, isConnectable }) {

  const dispatch=useDispatch();
  let ainodess=useSelector(state=>state.user.ainodes);
    const [text,settext]=useState('');
    const [savedtext,setsavedtext]=useState('');
    const [issaved,setissaved]=useState(false)

  const onChange =(evt) => {
    settext(evt.target.value);
  };

  const handlesavetext=()=>{
    setsavedtext(text)
    setissaved(!issaved);
    OnNodeUpdate();
  }
  const OnNodeUpdate=async()=>{
 
    const newMessage=text;
   const updatedNodes =ainodess.map(node => {
       if (node.data.id === data.id) {
           const newdata={id:data.id,message:newMessage}
           data=newdata;
           console.log(data);
           return {
               ...node,
               data: {
                 ...node.data,
                 message: {
                  type:'text',
                  value:newMessage
                 }
               }
             };
       //  return { ...node, data: { ...node.data, message: newMessage } };
       }
       return node;
     });

     console.log(updatedNodes)
     
     dispatch(setainodes(updatedNodes));
   settext('')
 }

  return (
    <div className="text-updater-node"
    >
    
      {/* <Handle type="target" position={Position.Top} isConnectable={isConnectable} /> */}
      <div className='simpletextaitemp'
      onClickCapture={()=>{console.log(data)}}
      >
      <div className='flowstart'>
      Enter keywords for flow start
      </div>
        <div className='simpletext'>
        {data.message.value}
        {/* { ainodess && ainodess.map(node=>{
                return node.data.id===data.id && <div className='simpletext'></div>
            
        })} */}
        {/* {savedtext} */}
        </div>
        <div htmlFor="text" className='simpletextlable'>Incoming message</div>
        <textarea id="text" name="text" onChange={onChange} className="textinput" value={text} 
        />

      <button className='savetextbtn' 
      onClickCapture={()=>{handlesavetext()}}
      >Save</button>
      </div>
      <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
    </div>
  );
}

export default Flowstart;