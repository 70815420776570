import React from "react";
import "./Homeinfo.css";

import Footer from "../Footer/Footer";
import { useNavigate } from "react-router";

import { useMediaQuery } from "../../Custom_hooks/Custom";

const Homeinfosection = () => {
  const isMobile = useMediaQuery("(max-width:412px)");
  const navigate = useNavigate();

  return (
    <>
      <div className="homeinfosection">
        <div className="ourservices">Our Services</div>

        <div className="chatbotservice seoanalysis">
          {isMobile && (
            <>
              <div className="chatbotimage">
                <img
                  src="https://i.postimg.cc/Wpw6RN0H/Zopins-com.png"
                  alt=""
                  className="crmimage"
                />
              </div>
            </>
          )}
          <div className="chatbotinfo">
            <div className="chatbotservicehead">Complete Whatsapp-Meta CRM</div>
            A single platform for managing all your leads on meta and onboard
            them onto this crm. Broadcast promotional and interactive messages
            with images and buttons to your clients. Increase your sales by 20 -
            60% .
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Create custom button messages to send.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Assign your clients to your employees to let them interact to
              them.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Create chatbot to interact with you customers with custom AI flow.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Enable multi-agent AI chat system.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Full whatsapp-meta integration.
            </div>
            <div className="moreinfobtn">
              More Information &nbsp;&nbsp;&nbsp;
              <img
                width="50"
                height="50"
                src="https://img.icons8.com/ios-filled/50/05242f/long-arrow-right.png"
                alt="long-arrow-right"
                className="moreinfoicon"
              />
            </div>
          </div>
          {!isMobile && (
            <>
              <div className="chatbotimage seoimage">
                <img
                  src="https://i.postimg.cc/Wpw6RN0H/Zopins-com.png"
                  alt=""
                  className="crmimage"
                />
              </div>
            </>
          )}
        </div>
        <div className="divider"></div>

        <div className="chatbotservice">
          <div className="chatbotimage">
            <img
              src="https://i.postimg.cc/sxJT8nx1/Untitled-design-17.png"
              alt=""
              className="chatbotserviceimage"
            />
          </div>
          <div className="chatbotinfo mainchatbotinfo">
            <div className="chatbotservicehead">
              AI Powered Chatbots For Your Websites
            </div>
            Embedd an AI powered chatbot in your website to let your customers
            feel your presence even when you are offline. Let the chatbot
            understand and answer to the queries of your customers by its own.
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Embedd custom AI flow of your choice.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Create custom interactive messages.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Send button messages.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              3-Layer query resolution architecture.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Build Chatbots & Solve for 24 x 7 Support & Engagement.
            </div>
            <div className="moreinfobtn morebtnchatbot">
              More Information &nbsp;&nbsp;&nbsp;
              <img
                width="50"
                height="50"
                src="https://img.icons8.com/ios-filled/50/16aee8/long-arrow-right.png"
                alt="long-arrow-right"
                className="moreinfoicon"
              />
            </div>
          </div>
        </div>

        <div className=" chatbotdivider"></div>

        <div className="chatbotservice seoanalysis">
          {isMobile && (
            <>
              <div className="chatbotimage seoimage">
                <img
                  src="https://i.postimg.cc/G2sJNLtt/Zopins.png"
                  alt=""
                  className="chatbotserviceimage"
                />
              </div>
            </>
          )}
          <div className="chatbotinfo">
            <div className="chatbotservicehead">
              SEO Analysis For Your Website
            </div>
            Use our seo analysier to analyse and get get your website insights.
            Use the power of AI to derive best results of you website.
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              AI powered domain overview of your website.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Count the number of words by using word counter.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Use image counter to make your images and website load faster.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Analyse the metadata of your website for best SEO.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Remove all warnings and issues in you website by analysing them.
            </div>
            <div className="zopinssubhead3">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png"
                alt="forward"
                className="forwardicon"
              />
              Generate a schema of your website to derive best results of SEO.
            </div>
            <div className="moreinfobtn">
              More Information &nbsp;&nbsp;&nbsp;
              <img
                width="50"
                height="50"
                src="https://img.icons8.com/ios-filled/50/05242f/long-arrow-right.png"
                alt="long-arrow-right"
                className="moreinfoicon"
              />
            </div>
          </div>
          {!isMobile && (
            <>
              <div className="chatbotimage seoimage">
                <img
                  src="https://i.postimg.cc/G2sJNLtt/Zopins.png"
                  alt=""
                  className="chatbotserviceimage"
                />
              </div>
            </>
          )}
        </div>
        <div className="divider"></div>
        <div className="getstartedbtn">
          Get Started With Your Zopins Journey
          <img
            width="48"
            height="48"
            src="https://img.icons8.com/parakeet-line/48/16aee8/long-arrow-right.png"
            alt="long-arrow-right"
            className="getstartedicon"
          />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Homeinfosection;
