import React, { useState } from 'react'

import { auth } from '../Firebase/firebase'

import './employeelogin.css'
import './facebooklogin.css'
import {useNavigate, useParams}from 'react-router-dom'

const Employeeverify = () => {

  const params=useParams();
  const paramemail=params.email;
  const [email,setemail]=useState('');
    const [password,setpassword]=useState('');
    const [error,seterror]=useState('');


    const navigate=useNavigate();
     
    const handleEmailpassSignup=()=>{
      fetch(`https://do.zopins.com/verifyemployee`,{
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin':'*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
           email:paramemail,
           password:password
        }),
      })
      .then(response => {
          console.log(response);
          navigate('/login');
      })
      .catch(error=>{
          console.log(error);
      })
    }


  return <>
  <br /><br />
     <div className='mainlogincontainer'>
  <div className='logincontainer'>
      <div className='loginhead eloginhead'>Verify Email</div>
      {
        error?<>
        <div className='errorbox'>{((error.message).split('/')[1]).split(')')[0]}</div>
        </>:<>
        </>
     }
<div className='loginform'>
   <input type="email" placeholder='Email' className='employeeauthinput' 
   value={paramemail} 
   />
</div>
<div>
   <input type="password" placeholder='Password' className='employeeauthinput' 
   value={password}
   onChange={(ev)=>setpassword(ev.target.value)}
   onInputCapture={(ev)=>{setpassword(ev.target.value)}}
   />
</div>
     <button onClick={handleEmailpassSignup} className='loginbtn loginadmin'>
     <span className=''>
    Verify Email
        </span>
        </button>      
  </div>
  <div className='loginstyle'>
    <img src="https://i.postimg.cc/zGG2PTZh/newloginpic-removebg.png" alt="login img" className='loginimage' />
  </div>

     
  </div>
  
  </>
    
}

export default Employeeverify;
