import React, { useEffect,useRef,useState } from "react";
import "./Home.css";
import HALO from 'vanta/dist/vanta.halo.min'
import * as THREE from 'three';
import {useMediaQuery,useModelState} from '../../Custom_hooks/Custom'
import Homeinfosection from "./Homeinfosection";
import Features from "./Features";

function Home() {
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  const isMobile=useMediaQuery('(max-width:412px)');
  useEffect(() => {

    localStorage.setItem('verifiedbygoogle','false')
    // if(isMobile){
    //   if (!vantaEffect) {
    //     setVantaEffect(HALO({
    //       THREE,
    //       el: myRef.current,
    //       mouseControls: true,
    //       touchControls: true,
    //       gyroControls: false,
    //       minHeight: 200.00,
    //       minWidth: 200.00,
    //       amplitudeFactor: 2,
    //       xOffset: 0,
    //       yOffset: -0.00005,
    //       size: 0.3
    //     }))
    //   }
    // }
    // else{
    //   if (!vantaEffect) {
    //     setVantaEffect(HALO({
    //       THREE,
    //       el: myRef.current,
    //       mouseControls: true,
    //       touchControls: true,
    //       gyroControls: false,
    //       minHeight: 200.00,
    //       minWidth: 200.00,
    //       amplitudeFactor: 2,
    //       xOffset: 0.25,
    //       yOffset: .4,
    //       size: 0.4
    //     }))
    //   }
    // }
    // return () => {
    //   if (vantaEffect) vantaEffect.destroy()
    // }
  }, [])

  return <>

  <div ref={myRef} className="homebackground">
  </div>
  <div className="zopinshead">
  <div className="zopinshead2"><span className="zopinsname">Zopins</span>,<span className="extracontent">Your Complete WhatsApp Engagement Platform</span></div>
  <div className="zopinshead3">Businesses drive 25-60% Revenues using Zopins</div>
  <div className="zopinssubhead3">
  <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png" alt="forward" className="forwardicon"/>
    Broadcast Promotional Offers to Unlimited Users</div>
  <div className="zopinssubhead3">
  <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png" alt="forward" className="forwardicon"/>
    Sell your Products on WhatsApp using Catalogs</div>
  <div className="zopinssubhead3">
  <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png" alt="forward" className="forwardicon"/>
    Automate Messages via Integrations</div>
  <div className="zopinssubhead3">
  <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png" alt="forward" className="forwardicon"/>
    Enable Multi-Agent Live Chat for Customer Support</div>
  <div className="zopinssubhead3">
  <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/16aee8/forward.png" alt="forward" className="forwardicon"/>
    Build Chatbots & Solve for 24 x 7 Support & Engagement</div>
    <div className="btnsboxes">
  <div className="joinfreetrialbtn livedemobtn">Join Live Demo</div>
  <div className="joinfreetrialbtn">Start 14-day Free Trial</div>
    </div>

  </div>
  <Homeinfosection/>
  {/* <Features/> */}
  </>
  
}
export default Home;