import React, { useState,useRef } from 'react'

import './Createnewchatbot.css';
const Createnewchatbot = () => {
  const divRef = useRef(null);
  const [domainname,setdomainname]=useState('');
  const [ischabotcreated,setischatbotcreated]=useState(false);
  const firebaseauthid=localStorage.getItem('adminauthid');
  const [loader,setloader]=useState(false);
  const [scriptdata,setscriptdata]=useState(null);
  const [connection,setconnection]=useState(false);

  const handlechatbotcreation=async()=>{
    if(domainname===''){
      return alert('please enter you domain name to create a chatbot.')
    }
    setloader(true)
    const response=await fetch(`https://do.zopins.com/createnewchatbot/${domainname}/${firebaseauthid}`,{
      method:'GET',
      headers:{
        'Access-Control-Allow-origin':'*'
      }
  })
  if(response.ok){
    const data=await response.json();
    setscriptdata(data);
         setdomainname('');
          setInterval(() => {
            setloader(false)
          }, 2000);
          setInterval(() => { 
            setischatbotcreated(true);
          }, 2000);

  }
    
  }

  const handleDivClick = () => {
    if (divRef.current) {
        const text = divRef.current.innerText;
        navigator.clipboard.writeText(text)
            .then(() => {
               alert('code coppied')
            })
            .catch(err => {
                console.error('Failed to copy content: ', err);
            });
    }
};

const handleconnectionstatus=async()=>{

  const response=await fetch(`https://do.zopins.com/getconnectionstatus/${scriptdata.app_token}`,{
    method:'GET',
    headers:{
      'Access-Control-Allow-origin':'*'
    }
})
const data=await response.json();
setconnection(data.connected);
console.log(data);
}
  return <>

   <div className='newchatbotcontainer'>
      <div className='newchatbotinfo'>
        To create a new chatbot to embedd into your website/webapp , please fill out the complete details given below and click on create chatbot button.
        After creating , a script tag will be shown . Embedd this tag in you website/webapp to start chatbot.
        <div className='newchatbotdetails'>
          <div className='newchatbotinputlables'>Website Domain Name</div>
        <input type="text" className='newchatbotinputs' placeholder='Your domain name (eg. mywebsite.com for https://mywebsite.com/ )'
        value={domainname}
         onChange={(ev)=>{setdomainname(ev.target.value)}}
         onInputCapture={(ev)=>{setdomainname(ev.target.value)}}
        />
        <button className='createchatbotbtn'
        onClick={()=>{handlechatbotcreation()}}
        >
          {loader?<>
            {/* <div className='loader-container newloader-container '>
                      <div className='loader newloader'></div>
                      </div> */}
                      {/* Creating..... */}
           <img width="30" height="30" src="https://img.icons8.com/ios/50/FFFFFF/loading.png"  alt="loading-sign"
            className='newloader'
           />
          </>:<>
          Create Chatbot
          </>}
          </button>
        </div>

        {ischabotcreated && <>
        <div className='generatedscripttaglabel'>Embedd this script in your website</div>
        <div className='generatedseripttag' title='Click to Copy'
          ref={divRef} 
         onClick={handleDivClick} 
        >
          {`<script src="https://meeow.zopins.com/chatbot.js" app-name="meow" version="0.01" app-token="${scriptdata.app_token}"></script>`}
        </div>

        <button className='testconnectionbtn' onClick={handleconnectionstatus}>Test Connection</button > 

         <button className='connectionstatus'>
          {connection===false?<>
            <span style={{color:'red'}}>Status - Not Connected</span>
          </>:<>
           <span style={{color:'green'}}>Status - Connected</span>
          </>}
         </button>

        </>}

      </div>
   </div>
  
  </>
}

export default Createnewchatbot
