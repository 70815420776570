import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPhoneNumber,setName,setUserChat,setselectedcustomer,setallchats} from '../../Reduxstore/store';
import {useModelState,useMediaQuery} from '../../Custom_hooks/Custom'
import Drawercomp from '../../Userpanel/Crmpage/Drawer';
import '../../Userpanel/Sidebar/Sidebar.css'
import {useNavigate} from 'react-router-dom'
import { auth } from '../../Firebase/firebase';

const Adminsidebar = ({setlatestchats}) => {

  const isMobile=useMediaQuery('(max-width:412px)');
  const navigate=useNavigate();
  const {isOpen,open,close}=useModelState();
  const [searching,setsearching]=useState(false)
    const [sname,setsname]=useState('');
    const [filtered,setfiltered]=useState([]);

  const employeeauth=useSelector(state=>state.user.employeeauth);
  const phoneNumber = useSelector(state => state.user.phoneNumber);
  const allchats = useSelector(state => state.user.allchats);
  const dispatch = useDispatch();
  const [contacts,setcontacts]=useState([]);
  const firebaseauthid=localStorage.getItem('adminauthid')

  const [newclientname,setnewclientname]=useState('');
  const [newclientphone,setnewclientphone]=useState('');
  const [newclientphoneid,setnewclientphoneid]=useState('');
  const [message,setmessage]=useState('');
  const [newchat,setnewchat]=useState();
  const [error,seterror]=useState('');

const handlesearched=()=>{
  const client=allchats.filter((c)=>((c.sender_display_name).toUpperCase()).includes((sname).toUpperCase() ) || (c.sender_phone_number).includes(sname));
   setfiltered(client);
  console.log(filtered);

}
useEffect(()=>{

   if(localStorage.getItem('isadminAuthenticated')!=='true'){
  //  return navigate('/login');
   }
   else{
    fetchadmin();
   }
   async function fetchadmin(){
    const response=await fetch(`https://do.zopins.com/getadmin/${firebaseauthid}`,{
      method:'GET',
      headers:{
        'Access-Control-Allow-origin':'*'
      }
  })
  const data=await response.json();
  dispatch(setallchats(data.chats));
  console.log('all chats',allchats);
  }


},[phoneNumber])

const handleparticularchats=(phonenumber)=>{
  if(!isMobile){
    setlatestchats([]);
  }
  const particularchats=allchats.filter(chat=>chat.sender_phone_number===phonenumber);
  dispatch(setUserChat(particularchats[0].chat));
  if(isMobile)
  navigate('/admin/users');
}
async function fetchnewchats(){
 const response=await fetch(`https://do.zopins.com/getnewchatsforadmin/${localStorage.getItem('adminauthid')}`,{
     method:'GET',
     headers:{
       'Access-Control-Allow-origin':'*'
     }
   })
   const data=await response.json();
   console.log(data);
   dispatch(setallchats(data));

   
}

const handleneaddnewcustomer=()=>{
  if( newclientphone){
   setmessage('creating');
   fetch('https://do.zopins.com/createadminclient',{
     method: 'POST',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/json',
     },
     body: JSON.stringify({
     client_name:newclientname,
     client_phone_number:newclientphone,
     client_phone_number_id:newclientphoneid,
     auth_id:firebaseauthid
     })
   })
   .then(response => {
       console.log(response);
         setmessage('created');
         fetchnewchats();

   })
   .catch(error=>{
     console.log(error);
   })
   setInterval(() => {
     setmessage('');
     setnewchat(!newchat);
   }, 2000);
  }
  else{
   seterror('One or more fields are empty');
  }
}
  return <>
             {isMobile && 
                   <img width="48" height="48" style={{zIndex:'100',marginTop:'4%'}} src="https://img.icons8.com/fluency-systems-regular/48/FFFFFF/long-arrow-left.png" alt="long-arrow-left"
                    className='backarrow'
                    onClick={()=>{navigate('/admindashboard')}}
                   />
                }
    {newchat && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="close-button" onClick={()=>{setnewchat(!newchat)}}>Close</button>
             <div className='newcustomerconainer'>
                <input type="text" placeholder='Client Name' className='newclientinputs' required 
                value={newclientname}
                 onChange={(ev)=>{setnewclientname(ev.target.value)}}
                 onInputCapture={(ev)=>{setnewclientname(ev.target.value)}}
                />
                <input type="text" placeholder='Client Phone Number' className='newclientinputs' required
                 value={newclientphone}
                 onChange={(ev)=>{setnewclientphone(ev.target.value)}}
                 onInputCapture={(ev)=>{setnewclientphone(ev.target.value)}}
                />
                <input type="text" placeholder='Client Phone Number Id' className='newclientinputs' required
                 value={newclientphoneid}
                 onChange={(ev)=>{setnewclientphoneid(ev.target.value)}}
                 onInputCapture={(ev)=>{setnewclientphoneid(ev.target.value)}}
                />
                 <div className='addnewchatbtn'
                  onClick={handleneaddnewcustomer}
                 >
                  {message===''?<>
                  Add New Chat
                  </>:
                  message==='creating'?
                  <>
                  <div className='loader-container'>
                      <div className='loader'></div>
                      </div>
                  </>:
                  message==='created'?
                  <>
                  client created successfully
                  </>:
                  <>Add New Chat</>}
                 </div>

                
             </div>
            
          </div>
        </div>
      )}
     <Drawercomp isOpen={isOpen} close={close}/>
    <div className='main-search-container'>
    <div  
    onClick={()=>{setnewchat(!newchat)}}
    >
      <img width="48" height="48" src="https://img.icons8.com/fluency/48/add-to-chat.png" alt="add-to-chat" className='newchatbtn'/>
      </div>
      <div className='search-container'>
      <input type="text" className='search' placeholder='Search...'
         value={sname}
         onChange={(ev)=>{
          setsname(ev.target.value);
          handlesearched();
      }}
         onInputCapture={(ev)=>{
           setsname(ev.target.value);
           handlesearched();
   
         }}
        />
         <svg xmlns="http://www.w3.org/2000/svg" style={{fill:'#031218'}} className='searchicon'  width="100" height="100" viewBox="0 0 24 24">
<path d="M 10 2 C 5.5965257 2 2 5.5965291 2 10 C 2 14.403471 5.5965257 18 10 18 C 11.752132 18 13.370523 17.422074 14.691406 16.458984 L 19.845703 21.613281 A 1.250125 1.250125 0 1 0 21.613281 19.845703 L 16.458984 14.691406 C 17.422074 13.370523 18 11.75213 18 10 C 18 5.5965291 14.403474 2 10 2 z M 10 4.5 C 13.052375 4.5 15.5 6.947627 15.5 10 C 15.5 13.052373 13.052375 15.5 10 15.5 C 6.9476251 15.5 4.5 13.052373 4.5 10 C 4.5 6.947627 6.9476251 4.5 10 4.5 z"></path>
</svg>
      </div>

    </div> 
    <div className='listcontacts' >

    </div>
  <div className=' sidebar'
  style={!isMobile?{marginLeft:'-95%',marginTop:'16vh',height:'84vh'}:{}}
  >
  {filtered.map((contact,index)=>{
                return <>
                   <div key={index}  className='particularemployeesearched' >

                   {contact.sender_display_name!=='unknown'?<>
                     
                    <div className='maincontactname'
                    key={index}
                    onClick={()=>{
                      dispatch(setselectedcustomer(contact))
                      handleparticularchats(contact.sender_phone_number);
                      dispatch(setPhoneNumber(contact.sender_phone_number));
                      dispatch(setName(contact.sender_display_name));
                    }}
                    
                    style={phoneNumber===contact.sender_phone_number?{backgroundColor:'#06384e',borderRadius:'.4vw'}:{}}
                    >
                    <div className='contacticon'>{((contact.sender_display_name).substring(0,1)).toUpperCase()}</div>
                    <div className='contact'> 
                     {/* {(contact.sender_display_name).toUpperCase()} */}
                     {
                     (contact.sender_display_name).length>=10?<>
                      {
                        ((contact.sender_display_name).toUpperCase()).substring(0,10)
                      }...
                      </>:<>
                        {((contact.sender_display_name).toUpperCase())}
                      </>
                    
                    }
                     </div>
                    
                    </div>
                 
                    </>:
                    <>
                    <div className='maincontactname' 
                   key={index}
                    onClick={()=>{
                      dispatch(setselectedcustomer(contact))
                      handleparticularchats(contact.sender_phone_number);
                      dispatch(setPhoneNumber(contact.sender_phone_number));
                      dispatch(setName(contact.sender_display_name))
                 
                      console.log(phoneNumber);
                    }}
                    style={phoneNumber===contact.sender_phone_number?{borderBottom:'2px solid white',backgroundColor:'#06384e',borderRadius:'.4vw'}:{}}
                    >
                    <div className='contacticon'>U</div>
                    
                    <div className='contact'>{contact.sender_phone_number}</div>
                    </div>
                  
                    </>}
                   </div>
                </>
            })}
    
            {allchats.map((contact,index)=>{
                return <>
                   <div key={index} >
                   {contact.sender_display_name!=='unknown'?<>
                    <div className='maincontactname'
                    onClick={()=>{
                      dispatch(setselectedcustomer(contact))
                      handleparticularchats(contact.sender_phone_number);
                      dispatch(setPhoneNumber(contact.sender_phone_number));
                      dispatch(setName(contact.sender_display_name));
               
                     
                    }}
                    
                    style={phoneNumber===contact.sender_phone_number?{backgroundColor:'#06384e',borderRadius:'.4vw'}:{}}
                    >
                    <div className='contacticon'>{((contact.sender_display_name).substring(0,1)).toUpperCase()}</div>
                    <div className='contact'> 
                     {/* {(contact.sender_display_name).toUpperCase()} */}
                     {
                     (contact.sender_display_name).length>=10?<>
                      {
                        ((contact.sender_display_name).toUpperCase()).substring(0,10)
                      }....
                      </>:<>
                        {((contact.sender_display_name).toUpperCase())}
                      </>
                    
                    }
                     </div>
                    
                    </div>
                 
                    </>:
                    <>
                    <div className='maincontactname' 
                   
                    onClick={()=>{
                      dispatch(setselectedcustomer(contact))
                      handleparticularchats(contact.sender_phone_number);
                      dispatch(setPhoneNumber(contact.sender_phone_number));
                      dispatch(setName(contact.sender_display_name))
               
                      console.log(phoneNumber);
                    }}
                    style={phoneNumber===contact.sender_phone_number?{borderBottom:'2px solid white',backgroundColor:'#06384e',borderRadius:'.4vw'}:{}}
                    >
                    <div className='contacticon'>U</div>
                    
                    <div className='contact'>{contact.sender_phone_number}</div>
                    </div>
                  
                    </>}
                   </div>
                   
                </>
            })}
  </div>
  </>
}

export default Adminsidebar
