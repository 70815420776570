import React from 'react'
import Actionbar from '../actionbar/Actionbar'
import './Settings.css'

const Settings = () => {
  return <>
  {/* <Actionbar/> */}
    <div className='settingshead'>settings</div>
  </>
}

export default Settings
