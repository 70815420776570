import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setPhoneNumber,setName,setUserChat,setallchats } from '../../Reduxstore/store';
import {useModelState} from '../../Custom_hooks/Custom'
import Drawercomp from '../Crmpage/Drawer';
import './Sidebar.css'
import Actionbar from '../actionbar/Actionbar';

import {useNavigate} from 'react-router-dom'
import Crmpage from '../Crmpage/Crmpage';
import { Sidenav } from 'rsuite';
import {setemployeeauth} from '../../Reduxstore/store'


const NewSidebar = ({setlatestchats}) => {
  const navigate=useNavigate();
  const {isOpen,open,close}=useModelState();
  const [searching,setsearching]=useState(false)
    const [sname,setsname]=useState('');
    const [filtered,setfiltered]=useState([]);
  

    const employeeauth=useSelector(state=>state.user.employeeauth);
  const phoneNumber = useSelector(state => state.user.phoneNumber);
  const allchats = useSelector(state => state.user.allchats);
  const dispatch = useDispatch();
  const [contacts,setcontacts]=useState([])

  const [newclientname,setnewclientname]=useState('');
  const [newclientphone,setnewclientphone]=useState('');
  const [newclientphoneid,setnewclientphoneid]=useState('');
  const [message,setmessage]=useState('');
  const [newchat,setnewchat]=useState();
  const [error,seterror]=useState('');

const handlesearched=()=>{
   if(employeeauth.employeedetails){
    const client=allchats.filter((c)=>((c.sender_display_name).toUpperCase()).includes((sname).toUpperCase() ) || (c.sender_phone_number).includes(sname));
      setfiltered(client);
     console.log(filtered);
   }

}
const updatechats=()=>{
  fetch('https://do.zopins.com/updateemployeechats',{
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin':'*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(employeeauth)
  })
  .then(response => {
      console.log('updated response',response);
      //fetchnewchats();
    })
    .catch(error=>{
      console.log(error);
    })
}
//  const fetchnewchats=async()=>{
//   const response=await fetch(`https://do.zopins.com/getupdatedchats/${employeeauth.companydetails.auth_id}/${employeeauth.employeedetails.id}`,{
//     method:'GET',
//     headers:{
//       'Access-Control-Allow-origin':'*'
//     }
//   })
//   const data=await response.json();
//   console.log('employee updated chats',data)
//  }
useEffect(()=>{
  async function fetchnupdatedchats(){
   // latestchats=[];
    const response=await fetch(`https://do.zopins.com/employeelogin/${employeeauth.employeedetails.email}/${employeeauth.employeedetails.password}`,{
      method:'GET'
    })
     const data=await response.json();
     console.log(data.validated)
     if(data.validated){
      console.log('data from updates',data.employeedetails.assigned_clients);
       dispatch(setallchats(data.employeedetails.assigned_clients))
       dispatch(setemployeeauth(data));
     }
     else{
        seterror(
          {message:'Database:Error(auth/No user found.)'}
          )
     }
    }

  fetchnupdatedchats();

},[phoneNumber])


const handleparticularchats=(phonenumber)=>{
  setlatestchats([]);
  //console.log('latest chats',latestchats);
       const particularchats=employeeauth.employeedetails.assigned_clients.filter(chat=>chat.sender_phone_number===phonenumber);
       dispatch(setUserChat(particularchats[0].chat));
}

async function fetchnewchats(){
   const companydetails=employeeauth.companydetails;
  const response=await fetch(`https://do.zopins.com/getnewchats/${employeeauth.companydetails.auth_id}/${employeeauth.employeedetails.id}`,{
      method:'GET',
      headers:{
        'Access-Control-Allow-origin':'*'
      }
    })
    const data=await response.json();
    console.log('new updated chats data',data);
    dispatch(setemployeeauth({
      validated:true,
      companydetails:companydetails,
      employeedetails:data
    }))
}

const handleneaddnewcustomer=()=>{
   if( newclientphone){
    setmessage('creating');
    fetch('https://do.zopins.com/createnewclientbyclient',{
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
      client_name:newclientname,
      client_phone_number:newclientphone,
      client_phone_number_id:newclientphoneid,
      phone_number:employeeauth.employeedetails.phone_number,
      admin_phone_number_id:employeeauth.companydetails.admin_phone_number_id
      })
    })
    .then(response => {
        console.log(response);
          setmessage('created');
          fetchnewchats();

    })
    .catch(error=>{
      console.log(error);
    })
    setInterval(() => {
      setmessage('');
      setnewchat(!newchat);
    }, 2000);
   }
   else{
    seterror('One or more fields are empty');
   }

}
  return <>
      {newchat && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="close-button" onClick={()=>{setnewchat(!newchat)}}>Close</button>
             <div className='newcustomerconainer'>
                <input type="text" placeholder='Client Name' className='newclientinputs' required 
                value={newclientname}
                 onChange={(ev)=>{setnewclientname(ev.target.value)}}
                 onInputCapture={(ev)=>{setnewclientname(ev.target.value)}}
                />
                <input type="text" placeholder='Client Phone Number' className='newclientinputs' required
                 value={newclientphone}
                 onChange={(ev)=>{setnewclientphone(ev.target.value)}}
                 onInputCapture={(ev)=>{setnewclientphone(ev.target.value)}}
                />
                <input type="text" placeholder='Client Phone Number Id' className='newclientinputs' required
                 value={newclientphoneid}
                 onChange={(ev)=>{setnewclientphoneid(ev.target.value)}}
                 onInputCapture={(ev)=>{setnewclientphoneid(ev.target.value)}}
                />
                 <div className='addnewchatbtn'
                  onClick={handleneaddnewcustomer}
                 >
                  {message===''?<>
                  Add New Chat
                  </>:
                  message==='creating'?
                  <>
                  <div className='loader-container'>
                      <div className='loader'></div>
                      </div>
                  </>:
                  message==='created'?
                  <>
                  client created successfully
                  </>:
                  <>Add New Chat</>}
                 </div>

                
             </div>
          </div>
        </div>
      )}
     <Drawercomp isOpen={isOpen} close={close}/>
    <div className='main-search-container'>
      <span className='employeeheadname'>{(employeeauth.employeedetails.name).toUpperCase()}</span>
     <div  onClick={()=>{setnewchat(!newchat)}}>
      <img width="48" height="48" src="https://img.icons8.com/fluency/48/add-to-chat.png" alt="add-to-chat" className='newchatbtn'/>
      </div>
      <div className='search-container'>
        <input type="text" className='search' placeholder='Search...'
         value={sname}
         onInputCapture={(ev)=>{
           setsname(ev.target.value);
           handlesearched();
   
         }}
         onChange={(ev)=>{
           setsname(ev.target.value);
           handlesearched();
       }}
        />
        <svg xmlns="http://www.w3.org/2000/svg" style={{fill:"#031218;"}} className='searchicon'  width="100" height="100" viewBox="0 0 24 24">
<path d="M 10 2 C 5.5965257 2 2 5.5965291 2 10 C 2 14.403471 5.5965257 18 10 18 C 11.752132 18 13.370523 17.422074 14.691406 16.458984 L 19.845703 21.613281 A 1.250125 1.250125 0 1 0 21.613281 19.845703 L 16.458984 14.691406 C 17.422074 13.370523 18 11.75213 18 10 C 18 5.5965291 14.403474 2 10 2 z M 10 4.5 C 13.052375 4.5 15.5 6.947627 15.5 10 C 15.5 13.052373 13.052375 15.5 10 15.5 C 6.9476251 15.5 4.5 13.052373 4.5 10 C 4.5 6.947627 6.9476251 4.5 10 4.5 z"></path>
</svg>

      </div>
    </div>
  <div className='sidebar'>
  <div className='hiddendiv'></div>
  {filtered.map((contact,index)=>{
                return <>
                   <div key={index}  className='particularemployeesearched'>

                   {contact.sender_display_name!=='unknown'?<>
                     
                    <div className='maincontactname'
                    key={index}
                    onClick={()=>{
                      handleparticularchats(contact.sender_phone_number)
                      dispatch(setPhoneNumber(contact.sender_phone_number));
                      dispatch(setName(contact.sender_display_name));
                    }}
                    
                    style={phoneNumber===contact.sender_phone_number?{backgroundColor:'#06384e',borderRadius:'.4vw'}:{}}
                    >
                    <div className='contacticon'>{((contact.sender_display_name).substring(0,1)).toUpperCase()}</div>
                    <div className='contact'>  {(contact.sender_display_name).toUpperCase()}</div>
                    
                    </div>
                 
                    </>:
                    <>
                    <div className='maincontactname' 
                   key={index}
                    onClick={()=>{
                      handleparticularchats(contact.sender_phone_number)
                      dispatch(setPhoneNumber(contact.sender_phone_number));
                      dispatch(setName(contact.sender_display_name))
                 
                      console.log(phoneNumber);
                    }}
                   
                    style={phoneNumber===contact.sender_phone_number?{borderBottom:'2px solid white',backgroundColor:'#06384e',borderRadius:'.4vw'}:{}}
                    >
                    <div className='contacticon'>U</div>
                    
                    <div className='contact'>{contact.sender_phone_number}</div>
                    <div className='removechaticonbox'>
                    <svg xmlns="http://www.w3.org/2000/svg" className='removechaticon' x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
<linearGradient id="nyvBozV7VK1PdF3LtMmOna_pre7LivdxKxJ_gr1" x1="18.405" x2="33.814" y1="10.91" y2="43.484" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#32bdef"></stop><stop offset="1" stop-color="#1ea2e4"></stop></linearGradient><path fill="url(#nyvBozV7VK1PdF3LtMmOna_pre7LivdxKxJ_gr1)" d="M39,10l-2.835,31.181C36.072,42.211,35.208,43,34.174,43H13.826	c-1.034,0-1.898-0.789-1.992-1.819L9,10H39z"></path><path fill="#0176d0" d="M32,7c0-1.105-0.895-2-2-2H18c-1.105,0-2,0.895-2,2c0,0,0,0.634,0,1h16C32,7.634,32,7,32,7z"></path><path fill="#007ad9" d="M7,9.886L7,9.886C7,9.363,7.358,8.912,7.868,8.8C10.173,8.293,16.763,7,24,7s13.827,1.293,16.132,1.8	C40.642,8.912,41,9.363,41,9.886v0C41,10.501,40.501,11,39.886,11H8.114C7.499,11,7,10.501,7,9.886z"></path>
</svg>
                    </div>
                    </div>
                  
                    </>}
                   </div>
                </>
            })}
            {employeeauth.employeedetails && employeeauth.employeedetails.assigned_clients.map((contact,index)=>{
                return <>
                   <div key={index}  >
                   {contact.sender_display_name!=='unknown'?<>
                    <div className='maincontactname'
                    onClick={()=>{
                      handleparticularchats(contact.sender_phone_number)
                      dispatch(setPhoneNumber(contact.sender_phone_number));
                      dispatch(setName(contact.sender_display_name));      
                    }}
                  
                    style={phoneNumber===contact.sender_phone_number?{backgroundColor:'#06384e',borderRadius:'.4vw'}:{}}
                    >
                    <div className='contacticon'>{((contact.sender_display_name).substring(0,1)).toUpperCase()}</div>
                    <div className='contact'>  {
                      (contact.sender_display_name).length>=10?<>
                      {
                        ((contact.sender_display_name).toUpperCase()).substring(0,10)
                      }...
                      </>:<>
                        {((contact.sender_display_name).toUpperCase())}
                      </>
                    
                    }
                    </div>
                    {/* <div className='removechaticonbox' title={`Remove ${contact.sender_display_name} from your list. `}>
                    <svg xmlns="http://www.w3.org/2000/svg" className='removechaticon' x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
<linearGradient id="nyvBozV7VK1PdF3LtMmOna_pre7LivdxKxJ_gr1" x1="18.405" x2="33.814" y1="10.91" y2="43.484" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#32bdef"></stop><stop offset="1" stop-color="#1ea2e4"></stop></linearGradient><path fill="url(#nyvBozV7VK1PdF3LtMmOna_pre7LivdxKxJ_gr1)" d="M39,10l-2.835,31.181C36.072,42.211,35.208,43,34.174,43H13.826	c-1.034,0-1.898-0.789-1.992-1.819L9,10H39z"></path><path fill="#0176d0" d="M32,7c0-1.105-0.895-2-2-2H18c-1.105,0-2,0.895-2,2c0,0,0,0.634,0,1h16C32,7.634,32,7,32,7z"></path><path fill="#007ad9" d="M7,9.886L7,9.886C7,9.363,7.358,8.912,7.868,8.8C10.173,8.293,16.763,7,24,7s13.827,1.293,16.132,1.8	C40.642,8.912,41,9.363,41,9.886v0C41,10.501,40.501,11,39.886,11H8.114C7.499,11,7,10.501,7,9.886z"></path>
</svg>
                    </div> */}
                    
                    </div>
                    </>:
                    <>
                    <div className='maincontactname' 
                   
                    onClick={()=>{
                      handleparticularchats(contact.sender_phone_number)
                      dispatch(setPhoneNumber(contact.sender_phone_number));
                      dispatch(setName(contact.sender_display_name))
               
                      console.log(phoneNumber);
                    }}
                 
                    style={phoneNumber===contact.sender_phone_number?{borderBottom:'2px solid white',backgroundColor:'#06384e',borderRadius:'.4vw'}:{}}
                    >
                    <div className='contacticon'>U</div>
                    
                    <div className='contact'>{contact.sender_phone_number}</div>
                    <div className='removechaticonbox'>
                    <svg xmlns="http://www.w3.org/2000/svg" className='removechaticon' x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
<linearGradient id="nyvBozV7VK1PdF3LtMmOna_pre7LivdxKxJ_gr1" x1="18.405" x2="33.814" y1="10.91" y2="43.484" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#32bdef"></stop><stop offset="1" stop-color="#1ea2e4"></stop></linearGradient><path fill="url(#nyvBozV7VK1PdF3LtMmOna_pre7LivdxKxJ_gr1)" d="M39,10l-2.835,31.181C36.072,42.211,35.208,43,34.174,43H13.826	c-1.034,0-1.898-0.789-1.992-1.819L9,10H39z"></path><path fill="#0176d0" d="M32,7c0-1.105-0.895-2-2-2H18c-1.105,0-2,0.895-2,2c0,0,0,0.634,0,1h16C32,7.634,32,7,32,7z"></path><path fill="#007ad9" d="M7,9.886L7,9.886C7,9.363,7.358,8.912,7.868,8.8C10.173,8.293,16.763,7,24,7s13.827,1.293,16.132,1.8	C40.642,8.912,41,9.363,41,9.886v0C41,10.501,40.501,11,39.886,11H8.114C7.499,11,7,10.501,7,9.886z"></path>
</svg>
                    </div>
                    </div>
                  
                    </>}
                   </div>
                </>
            })}
  </div>
  </>
}

export default NewSidebar
