import React from 'react'
import {useModelState} from '../../Custom_hooks/Custom'

import { Link } from 'react-router-dom';

import { Divider, Drawer, ButtonToolbar, Button, IconButton, Placeholder } from "rsuite";

import './Drawer.css'


const Drawercomp = ({isOpen,close}) => {
  return <>
  {/* <Drawer open={isOpen} onClose={close} placement="left" style={{height:'100%',width:'28%'}}>
        <div className='drawerhead'>
            <span className='myprofilename'>{"Sahil"}</span>
            </div>
       <Drawer.Body style={{height:'100%'}} className='drawerbody'>
        
  
       </Drawer.Body>
     </Drawer> */}
      <Drawer placement={'left'} open={isOpen} onClose={close}>
        <Drawer.Header>
          <Drawer.Title className='drawerhead'>My Profile</Drawer.Title>
          <Drawer.Actions>
            {/* <Button onClick={close}>Cancel</Button> */}
            <Button  appearance="primary"
            className='myprofilename'
            >
              {'Sahil'}
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <Placeholder.Paragraph rows={8} />
        </Drawer.Body>
      </Drawer>
  </>
}

export default Drawercomp
