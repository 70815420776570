import React, { useEffect, useState } from 'react'
import Crmusers from './Crmusers'
import Supersettings from './Supersettings'
import Superdashboard from './Superdashboard'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

const Superadminhome = ({active}) => {
  const navigate=useNavigate();
  const issupervalidated=useSelector(state=>state.user.issupervalidated)
  const [supervalidated,setsupervalidated]=useState(false);

  useEffect(()=>{
    // setsupervalidated(localStorage.getItem('issupervalidated'));
     console.log(issupervalidated);
     if(!issupervalidated){
      navigate('/superadminlogin')
     }

  },[])
  return <>
  {active==='dashboard' && <Superdashboard/>}
  {active==='users' && <Crmusers/>}
  {active==='settings' && <Supersettings/>}
  </>
}

export default Superadminhome
