import React from 'react'

const NewTemplates = () => {
  return <>
      <div className='newtemplates'>

        <div className='templategenerator'></div>

        <div className='templateviewer'></div>

      </div>
  </>
}

export default NewTemplates
