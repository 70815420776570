import React from 'react'
import { useState,useEffect } from 'react';

import { useSelector } from 'react-redux';

import './Mytemplates.css';

const Mytemplates = () => {

    const [templates, settemplates]=useState([]);
    const [isviewing,setisviewing]=useState(false);
    const [template,settemplate]=useState([]);
    const waba_id=useSelector(state=>state.user.wabaid)
     
    const henaletemplateview=async (templateid)=>{
        console.log(templateid)
        try {
            const response=await fetch(`https://do.zopins.com/gettemplate/${templateid}`,{
                method:'GET',
                headers:{
                    'Access-Control-Allow-origin':'*'
                }
            })
            const data=await response.json();
            settemplate(data);
            console.log(template);
            setisviewing(true);
          console.log(data.data);
            } catch (error) {
                 console.log(error);
            }
    }
    
    useEffect(()=>{
        async function fetchmessages(){
            const response=await fetch(`https://do.zopins.com/myalltemplates/${waba_id}`,{
              method:'GET',
              headers:{
                'Access-Control-Allow-origin':'*'
              }
          })
          const data=await response.json();
          settemplates(data.data);
          console.log(data.data);
          }
          fetchmessages();
    },[])

    
  return <>
  {isviewing && <div>
    <div className='templateviewbox'>
        <div className='tempmetainfo'>
          
       <div>TEMPLATE PREVIEW</div>

       <div className='previewbox'>
         {template && template.components.map((component,index)=>{
            return <>
               <div key={index}>
                {component.type==='HEADER'?<>
                <div className='compheader'>{component.text}</div>
                </>:''}
                {component.type==='BODY'?<>
                <div className='compmessage'>{component.text}</div>
                </>:''}

               </div>   
            </>
         })}
         </div>
         {template && template.components.map((component,index)=>{
            return <>
               <div key={index}>
                {component.type==='BUTTONS'?<>
                <div className='compmessage'>{component.buttons.map((button,index)=>{
                    return <>
                      <div className='tempbutton'>{button.text}</div>
            
                    </>
                })}</div>
                </>:''}

               </div>   
            </>
         })}
        </div>
    </div>
    </div>
  }
     <div className='templatebox'>
     <div className='mytemplateshead'>My Templates</div>
      {!templates?<>
        <div className='notemplates'>No templates approved....</div>
</>:<>
<table>
  <thead >
    <tr>
      <th>No.</th>
      <th>Template Id</th>
      <th>Template Name</th>
      <th>Status</th>
      <th>View/Delete/Edit</th>
    </tr>
  </thead>
  <tbody>
    {templates && templates.map((template,index)=>{
        return <>
        <tr key={template.id} className='temprow'>
      <td>{index+1}</td>
      <td>{template.id}</td>
      <td>{template.name}</td>
      <td style={template.status==='APPROVED'?{color:'green'}:template.status==='REJECTED'?{color:'red'}:{color:'blue'}}>{template.status}</td>
      <td><div className='viewtemplatebtn' onClick={()=>{henaletemplateview(template.id)}}>
      <img width="24" height="24" src="https://img.icons8.com/material-rounded/24/16aee8/visible.png" alt="visible" className='templateshowicon' onClick={()=>henaletemplateview(template.id)}/>
      <img width="64" height="64" src="https://img.icons8.com/external-flatart-icons-solid-flatarticons/64/FA5252/external-dustbin-ux-and-ui-flatart-icons-solid-flatarticons.png" alt="external-dustbin-ux-and-ui-flatart-icons-solid-flatarticons" className='deletetempicon'/>
      <img width="64" height="64" src="https://img.icons8.com/external-febrian-hidayat-glyph-febrian-hidayat/64/16aee8/external-edit-user-interface-febrian-hidayat-glyph-febrian-hidayat.png" alt="external-edit-user-interface-febrian-hidayat-glyph-febrian-hidayat" className='edittempicon'/>
        </div></td>
    </tr>
        </>
    })}
  </tbody>
</table>
      </>} 
     </div>
  
  
  </>
}

export default Mytemplates
