import React, { useEffect, useState } from 'react'

import '../Admindashboard/Admindash.css'

import {useNavigate} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux';
import {setphoneid,setwabaid,setadminname,setadminphone,setallchats} from '../../Reduxstore/store'
import Chatbotchats from '../Chatbotchats/Chatbotchats';
import Createnewchatbot from './Createnewchatbot/Createnewchatbot';
import Mychatbots from './Mychatbots/Mychatbots';
import Addchatfow from './Addchatflow/Addchatfow';
import Createqa from './Createquestionanswer/Createqa';
import Webscrapping from './Webscrapping/Webscrapping';

const Adminchatbotdashboard = () => {
  const dispatch=useDispatch();  
 const adminphoneid=useSelector(state=>state.user.adminphoneid);
  const navigate=useNavigate();
  const [active,setactive]=useState('create')
  const firebaseauthid=localStorage.getItem('adminauthid')

  useEffect(()=>{

    setactive('createchatbot')
     if(localStorage.getItem('isadminAuthenticated')!=='true'){
       navigate('/login')
     } 
    //  else{
    //   fetchadmin();
    //  }
      // async function fetchadmin(){
      //   const response=await fetch(`https://do.zopins.com/getadmin/${firebaseauthid}`,{
      //     method:'GET',
      //     headers:{
      //       'Access-Control-Allow-origin':'*'
      //     }
      // })
      // const data=await response.json();
      // dispatch( setphoneid(data.phone_number_id));
      // dispatch(setadminname(data.name))
      // dispatch(setwabaid(data.whatsapp_business_id))
      // dispatch(setadminphone(data.phone_number));
      // dispatch(setallchats(data.chats));
      // console.log('all chats',allchats);
      // }

  },[]);
  return <>
    <div className='maindashcontainer'
      
    >
   
    <div className='dashnav'>
     
      <button className='dele' onClick={()=>setactive('createchatbot')} style={active==='createchatbot'?{color:'#16AEE8'}:{}}>Create Chatbot</button>
    <div className='dele' onClick={()=>setactive('chatbotanalytics')} style={active==='chatbotanalytics'?{color:'#16AEE8'}:{}}> Chat Analysis</div>
     <div className='dele' onClick={()=>setactive('mychatbots')} style={active==='mychatbots'?{color:'#16AEE8'}:{}}>My Chatbots</div>

{/* 
     <div className='dele' onClick={()=>{
      setactive('addchatflow');
    }}
       
     style={active==='addchatflow'?{backgroundColor:'#06435b',border:'1px solid white'}:{}}>Add Chatbot Flow</div>
      */}
      <div className='dele' onClick={()=>setactive('createqa')} style={active==='createqa'?{color:'#16AEE8'}:{}}>Create Q/A</div>
     <div className='dele' onClick={()=>setactive('mutate')} style={active==='mutate'?{color:'#16AEE8'}:{}}>Web Scraping</div>
     <div className='dele' onClick={()=>setactive('settings')} style={active==='settings'?{color:'#16AEE8'}:{}}>Chatbot Settings</div>
     {/* <div className='dele' onClick={()=>setactive('ai')} style={active==='ai'?{backgroundColor:'#06435b',border:'1px solid white'}:{}}>Setup AI Flow</div>
     <div className='dele' onClick={()=>setactive('aito')} style={active==='aito'?{backgroundColor:'#06435b',border:'1px solid white'}:{}}>others</div> */}

    </div>
    </div>
    {active==='createchatbot' && <Createnewchatbot/>}
    {active==='mychatbots' && <Mychatbots/>}
    {/* {active==='addchatflow' && <Addchatfow/>} */}
    {active==='createqa' && <Createqa/>}
    {active==='mutate' && <Webscrapping/>}
  </>
}

export default Adminchatbotdashboard;
