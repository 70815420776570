import React from "react";
import "./Template.css";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "../../Custom_hooks/Custom";

const NewTemplates = ({ usedin ,domain}) => {
  const [templatename, settemplatename] = useState("");
  const [templatecategory, settemplatecategory] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [header, setheader] = useState("");
  const [headervariable, setheadervariable] = useState(null);
  const [messagevariables, setmessagevariables] = useState([null]);
  const [istemplatesend, setistemplatesend] = useState(false);
  const [textmessage, settextmessage] = useState("");
  const [isvariableused, setisvariableused] = useState(false);
  const [isvariableinmessage, setisvariableinmessage] = useState(false);
  const variables = ["{{1}}", "{{2}}", "{{3}}", "{{4}}", "{{5}}"];
  const [buttons, setbuttons] = useState([]);
  const [popup, setpopup] = useState(false);
  const [buttontype, setbuttontype] = useState("");
  const [buttonvalue, setbuttonvalue] = useState("");
  const [buttontext, setbuttontext] = useState("");
  const wabaid = useSelector((state) => state.user.wabaid);
  const isMobile = useMediaQuery("(max-width:412px)");

  const handlevariableinheader = () => {
    if (header.includes("{{1}}")) {
      setisvariableused(true);
    }
  };

  const handlevariableinmessage = () => {
    if (textmessage.includes("{{1}}")) {
      setisvariableinmessage(true);
    }
  };

  const handleCheckboxChange = () => {
    setisvariableused(false);
    setheader("");
    setIsChecked(!isChecked);
  };
  const handleCheckboxChange2 = () => {
    setisvariableused(false);
    setheader("");
    setIsChecked2(!isChecked2);
  };

  const togglePopup = (type) => {
    setbuttontype(type);
    setpopup(!popup);
  };

  const addnewbutton = () => {
    let buttondata;
    if (buttontype === "Phone Number") {
      buttondata = {
        type: "PHONE_NUMBER",
        text: buttontext,
        phone_number: buttonvalue,
      };
    } else if (buttontype === "Url") {
      buttondata = {
        type: "URL",
        text: buttontext,
        url: buttonvalue,
      };
    } else if (buttontype === "Quick Reply") {
      buttondata = {
        type: "QUICK_REPLY",
        text: buttontext,
      };
    } else {
      buttondata = {
        type: "COPY_CODE",
        example: buttontext,
      };
    }
    console.log(buttondata);
    setbuttons([...buttons, buttondata]);
    console.log(buttons);
    togglePopup(!popup);
    setbuttonvalue("");
    setbuttontext("");
  };

  const removebutton = (idx) => {
    setbuttons(buttons.filter((button, index) => index !== idx));
    console.log(buttons);
  };
  const handletemplatesave = async () => {

    const headerdata = {
      text: header,
      vars: headervariable,
    };
    const messagedata = {
      text: textmessage,
      vars: messagevariables,
    };

    setistemplatesend(true);
    fetch(`https://do.zopins.com/createtemplate`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        accountId: wabaid,
        templateName: templatename,
        templateCategory: templatecategory,
        headerdata,
        messagedata,
        buttons,
      }),
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    setTimeout(() => {
      setistemplatesend(false);
    }, 3000);
  };

  const handletemplatesaveforchatbot=()=>{
      const data={
        name:templatename,
        category:'prompt',
        type:'template',
        details:{
          question:header,
          answer:{
            message:textmessage,
            buttons:buttons
          }
        }
      }
       console.log(data);
    setistemplatesend(true);
      fetch(`https://do.zopins.com/savechatbotnodes`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          authid:localStorage.getItem('adminauthid'),
          apptoken:localStorage.getItem('domainapptoken'),
          admin:domain,
          node:data
        }),
      })
        .then((response) => {
            setistemplatesend(false);

          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(data);
  }


  return (
    <>
      {istemplatesend && (
        <div className="popup-overlay">
          <div className="templatesentpopup">
            <div>question answer template saved.</div>
          </div>
        </div>
      )}

      {popup && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="close-button" onClick={togglePopup}>
              Close
            </button>
            <div className="popuphead">{buttontype} Button</div>
            <div>
              {buttontype === "Phone Number" ? (
                <>
                  <div>
                    <div className="popuplables">Button Name</div>
                    <input
                      type="text"
                      className="buttoninput"
                      value={buttontext}
                      onChange={(ev) => setbuttontext(ev.target.value)}
                      onInputCapture={(ev) => setbuttontext(ev.target.value)}
                    />
                    <div className="popuplables">Phone Number</div>
                    <input
                      type="text"
                      className="buttoninput"
                      value={buttonvalue}
                      onChange={(ev) => setbuttonvalue(ev.target.value)}
                      onInputCapture={(ev) => setbuttonvalue(ev.target.value)}
                    />
                    <div>
                      <button className="addbutton" onClick={addnewbutton}>
                        Add Button
                      </button>
                    </div>
                  </div>
                </>
              ) : buttontype === "Url" ? (
                <>
                  <div>
                    <div>Name</div>
                    <input
                      type="text"
                      className="buttoninput"
                      value={buttontext}
                      onChange={(ev) => setbuttontext(ev.target.value)}
                      onInputCapture={(ev) => setbuttontext(ev.target.value)}
                    />
                    <div>Url</div>
                    <input
                      type="text"
                      className="buttoninput"
                      value={buttonvalue}
                      onChange={(ev) => setbuttonvalue(ev.target.value)}
                      onInputCapture={(ev) => setbuttonvalue(ev.target.value)}
                    />
                    <div>
                      <button className="addbutton" onClick={addnewbutton}>
                        Add Button
                      </button>
                    </div>
                  </div>
                </>
              ) : buttontype === "Quick Reply" ? (
                <>
                  <div>
                    <div>Name</div>
                    <input
                      type="text"
                      className="buttoninput"
                      value={buttontext}
                      onChange={(ev) => setbuttontext(ev.target.value)}
                      onInputCapture={(ev) => setbuttontext(ev.target.value)}
                    />
                    <div>Text</div>
                    <input
                      type="text"
                      className="buttoninput"
                      value={buttonvalue}
                      onChange={(ev) => setbuttonvalue(ev.target.value)}
                      onInputCapture={(ev) => setbuttonvalue(ev.target.value)}
                    />
                    <div>
                      <button className="addbutton" onClick={addnewbutton}>
                        Add Button
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div>Name</div>
                    <input
                      type="text"
                      className="buttoninput"
                      value={buttontext}
                      onChange={(ev) => setbuttontext(ev.target.value)}
                      onInputCapture={(ev) => setbuttontext(ev.target.value)}
                    />
                    <div>Code</div>
                    <input
                      type="text"
                      className="buttoninput"
                      value={buttonvalue}
                      onChange={(ev) => setbuttonvalue(ev.target.value)}
                      onInputCapture={(ev) => setbuttonvalue(ev.target.value)}
                    />
                    <div>
                      <button className="addbutton" onClick={addnewbutton}>
                        Add Button
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="newtemplates">
        <div className="templategenerator">
          {isMobile && <div className="previewtemplate">Preview Template</div>}
          {
            usedin==='bots' ?<>
        { textmessage !== "" && header!=='' ? (
            <button
              className="templatesave"
              title="save template for approval"
              onClick={handletemplatesaveforchatbot}
            >
              Save
            </button>
          ) : (
            <button
              className="templatesave"
              disabled
              style={{ backgroundColor: "gray", color: "black" }}
              title="Can not save template"
            >
              Save
            </button>
          )}
            
            </>:<>
            {templatecategory !== "" &&
            templatename !== "" &&
            textmessage !== "" ? <>
              <button
                className="templatesave"
                title="save template for approval"
                onClick={handletemplatesave}
              >
                Save
              </button>
            </>: (
              <button
                className="templatesave"
                disabled
                style={{ backgroundColor: "gray", color: "black" }}
                title="Can not save template"
              >
                Save
              </button>
            )}
            
            
            </>
          }
          <hr />

          <div className="templatemaincontent">
            <div className="templatemaininputs">
              <div className="templatelabels">Template Name</div>
              <input
                type="text"
                className="templateinputs"
                placeholder="Enter template name"
                value={templatename}
                onInputCapture={(ev) => settemplatename(ev.target.value)}
                onChange={(ev) => settemplatename(ev.target.value)}
              />
            </div>
            <div className="templatemaininputs">
              <div className="templatelabels">Category</div>
              <select
                id="selectInput"
                name="selectInput"
                className=" templateinputs selectinput"
                value={templatecategory}
                onInputCapture={(ev) => settemplatecategory(ev.target.value)}
                onChange={(ev) => settemplatecategory(ev.target.value)}
              >
                {usedin==='bots'?<>
                <option value="prompt">prompt</option>
                </>:<>
                
                <option value="Authentication">Authentication</option>
                <option value="Marketing">Marketing</option>
                <option value="Utility">Utility</option>   
                </>}
              </select>
            </div>
          </div>
 


          {usedin==='bots'? <>
          <div className="templateheaderbox">
            <div className="headerchooser">
              <div className="templatelabels">Question</div>

              {/* <div>
                <label class="switch headerswitch">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span class="slider round"></span>
                </label>
              </div> */}

            </div>

              <div className="headermakerbox">
                <input
                  type="text"
                  className="templateinputs"
                  placeholder="Enter question "
                  value={header}
                  onChange={(ev) => {
                    setheader(ev.target.value);
                    handlevariableinheader();
                  }}
                  onInputCapture={(ev) => {
                    setheader(ev.target.value);
                    handlevariableinheader();
                  }}
                />
                  {/* <div className="headervariable">
                    <div>
                      As you are using variable in your header,please specify an
                      example of the variable for us to review the template. You
                      can use only one variable in your header.{" "}
                    </div>
                    <input
                      type="text"
                      className="templateinputs variable"
                      placeholder="Enter varaible example for  {{1}}"
                      value={headervariable}
                      onInputCapture={(ev) => {
                        setheadervariable(ev.target.value);
                      }}
                      onChange={(ev) => {
                        setheadervariable(ev.target.value);
                      }}
                    />
                  </div> */}
                  
              </div>
          </div>
          
          </>:<>
          <div className="templateheaderbox">
            <div className="headerchooser">
              <div className="templatelabels">Header</div>
              <div>
                <label class="switch headerswitch">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            {isChecked && (
              <div className="headermakerbox">
                <input
                  type="text"
                  className="templateinputs"
                  placeholder="Enter header "
                  value={header}
                  onChange={(ev) => {
                    setheader(ev.target.value);
                    handlevariableinheader();
                  }}
                  onInputCapture={(ev) => {
                    setheader(ev.target.value);
                    handlevariableinheader();
                  }}
                />
                {isvariableused && (
                  <div className="headervariable">
                    <div>
                      As you are using variable in your header,please specify an
                      example of the variable for us to review the template. You
                      can use only one variable in your header.{" "}
                    </div>
                    <input
                      type="text"
                      className="templateinputs variable"
                      placeholder="Enter varaible example for  {{1}}"
                      value={headervariable}
                      onInputCapture={(ev) => {
                        setheadervariable(ev.target.value);
                      }}
                      onChange={(ev) => {
                        setheadervariable(ev.target.value);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          </>}

          
          <div className="textbox">
            <div className="templatelabels">
              {usedin==='bots' ?<>
              Answer
              </>:<>
              Message
              </>}
              </div>
            <textarea
              className="templateinputs messageinput"
              placeholder="Your message here"
              value={textmessage}
              onChange={(ev) => {
                settextmessage(ev.target.value);
                handlevariableinmessage();
              }}
              onInputCapture={(ev) => {
                settextmessage(ev.target.value);
                handlevariableinmessage();
              }}
            ></textarea>
            {isvariableinmessage && (
              <>
                <div className="messagevariablebox">
                  <div>
                    As you are using variable in your message,please specify an
                    example of the variable for us to review the template. You
                    can use a maximum of 5 variable in your message.
                  </div>
                  {variables.map((varib, index) => {
                    return (
                      <div key={index} className="variablebox">
                        <div className="messagevar">{varib}</div>
                        <div>
                          <input
                            className="variable messagevariable"
                            placeholder="Enter example for this variable if used"
                            value={messagevariables[index] || ""}
                            onChange={(ev) => {
                              const updatedVariables = [...messagevariables];
                              updatedVariables[index] = ev.target.value;
                              setmessagevariables(updatedVariables);
                              console.log(messagevariables);
                            }}
                            onInputCapture={(ev) => {
                              const updatedVariables = [...messagevariables];
                              updatedVariables[index] = ev.target.value;
                              setmessagevariables(updatedVariables);
                            }}
                          ></input>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <div className="templatebuttonsbox">
            <div className="headerchooser">
              <div className="templatelabels">Buttons</div>
              <div>
                <label class="switch headerswitch">
                  <input
                    type="checkbox"
                    checked={isChecked2}
                    onChange={handleCheckboxChange2}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              {isChecked2 && (
                <>
                  <div className="buttonsbox">
                    <div
                      className="templatebuttons"
                      onClick={() => {
                        togglePopup("Phone Number");
                      }}
                    >
                      Phone Number
                    </div>

                    <div
                      className="templatebuttons"
                      onClick={() => {
                        togglePopup("Url");
                      }}
                    >
                      Url
                    </div>

                    <div
                      className="templatebuttons"
                      onClick={() => {
                        togglePopup("Quick Reply");
                      }}
                    >
                      Quick Reply
                    </div>

                    <div
                      className="templatebuttons"
                      onClick={() => {
                        togglePopup("Copy Code");
                      }}
                    >
                      Copy Code
                    </div>
                  </div>
                  <div className="addedbuttons">
                    {buttons &&
                      buttons.map((button, index) => {
                        return (
                          <>
                            <div className="adjustbox">
                              <button className="adjustablebutton">
                                {button.text}
                              </button>
                              <button
                                className="removebutton"
                                onClick={() => {
                                  removebutton(index);
                                }}
                              >
                                X
                              </button>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          </div>
          <hr />
        </div>
        <div className="templateviewer">
          <div className="template">
          {usedin==='bots'?<>
          <div className="templatequestionviewer" >
            Question
          <div className="templatequestion" >{header}</div>
            </div>
            <div className="templatequestionviewer templateanswer" >
            Answer
          <div className="templatequestion" >{textmessage}</div>
            </div>
           
        </>:<>
        
            <div className="templatetext">
              <div className="templatehead">{header}</div>
              {textmessage}
            </div>
        
        </>}
            {isChecked2 && (
              <>
                {buttons &&
                  buttons.map((button, index) => {
                    return (
                      <>
                        <div className="buttons">{button.text}</div>
                      </>
                    );
                  })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTemplates;
