// store.js
import { configureStore, createSlice,combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

const initialState = {
  phoneNumber: '',
  name:'',
  userchat:[],
  allchats:[],
  botchats:[],
  employeephone: '',
    employeename:'',
    adminPhone:'',
    adminName:'',
    adminphoneid:'',
    wabaid:'',
    employeeauth:{
      validated:false
    },
    selectedcustomer:null,
    issupervalidated:false,
    aiactivetemplate:null,
    ainodes:[],
    newnodes:[],
    path:''
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setName: (state, action) => {
        state.name = action.payload;
      },
      setUserChat:(state, action) => {
        state.userchat = action.payload;
      },
      setallchats:(state, action) => {
        state.allchats = action.payload;
      },
      setbotchats:(state, action) => {
        state.botchats = action.payload;
      },
      
      setadminphone: (state, action) => {
        state.adminPhone = action.payload;
      },
      setadminname: (state, action) => {
        state.adminName = action.payload;
      },
      setphoneid: (state, action) => {
        state.adminphoneid = action.payload;
      },
      setwabaid: (state, action) => {
        state.wabaid = action.payload;
      },
      setemployeePhone: (state, action) => {
        state.employeephone = action.payload;
      },
      setemployeeName: (state, action) => {
          state.employeename = action.payload;
        },
        setemployeeauth: (state, action) => {
          state.employeeauth = action.payload;
        },
        setselectedcustomer: (state, action) => {
          state.selectedcustomer = action.payload;
        },
        setissupervalidated: (state, action) => {
          state.issupervalidated = action.payload;
        },
        setaiactivetemplate: (state, action) => {
          state.aiactivetemplate = action.payload;
        },
        setainodes: (state, action) => {
          state.ainodes = action.payload;
           console.log('store nodes',state.ainodes);
        },
        setnewnodes: (state, action) => {
          state.newnodes = action.payload;
        },
        setpath: (state, action) => {
          state.path = action.payload;
        },
        updateMessage: (state, action) => {
          const { id, newMessage } = action.payload;
          state.ainodes = state.ainodes.map(item =>{
             if(item.data.id === id){
              console.log(state.ainodes[0])
              return item.data.message=newMessage;
            }
            else{
              return item;
            }
            
          }
          );
        },

  },
});




export const {
  updateMessage,setPhoneNumber,
  setName,setemployeePhone,setemployeeName,
  setadminphone,setadminname,setphoneid,setwabaid,
  setemployeeauth,setUserChat,setallchats,setselectedcustomer,
  setissupervalidated,setaiactivetemplate,setainodes,
  setnewnodes,setbotchats,setpath
} = userSlice.actions;


// export default configureStore({
//   reducer: {
//     user: userSlice.reducer
//   }
// });

const rootReducer = combineReducers({
  user: userSlice.reducer
});

const persistConfig = {
  key: 'persist-root',
  storage,
};

const persistedReducer = persistReducer(persistConfig,rootReducer);

 const store = configureStore({
  reducer: persistedReducer
});

 const persistor = persistStore(store);
export  {store,persistor};

