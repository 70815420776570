import React, { useState, useEffect, useRef } from "react";
import "rsuite/dist/rsuite.min.css";
import { useSelector, useDispatch } from "react-redux";
import { setPhoneNumber,setName,setUserChat,setselectedcustomer,setbotchats} from '../../Reduxstore/store';
import Adminsidebar from "../Adminsidebar/Adminsidebar";
import { Usecustomfileinput, useMediaQuery } from "../../Custom_hooks/Custom";
import "../../Userpanel/Crmpage/Crm.css";
import "../../Userpanel/MainChat/Mainchat.css";
import { useNavigate } from "react-router-dom";

import Chatbotsidebar from "./Chatbotsidebar";

import "../Adminpage/Admincrm.css";

import styled, { keyframes } from "styled-components";
import "../../Userpanel/Crmpage/Sendingtemplate.css";
// import io from "socket.io-client";
// const socket = io.connect("https://do.zopins.com", {
//   forceNew: true,
//   transports: ["polling"],
// });

import socket from "../Adminpage/Socket";

const fadeInAnim = keyframes`
  from {
    opacity: 0;
    transform: translateX(-5vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const AnimatedContainer = styled.div`
  animation: ${fadeInAnim} 0.5s ease-in-out;
`;

const Chatbotchats = () => {
  const dispatch=useDispatch();
  const videoRef = useRef(null);
  const imageRef = useRef(null);
  const messagescontainerref = useRef(null);
  const navigate = useNavigate();
  const [latestchats, setlatestchats] = useState([]);
  const [activephoneNumber, setactivephoneNumber] = useState("");
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedvideo, setselectedvideo] = useState(null);
  const allchats = useSelector(state => state.user.botchats);
  const [customerassignedto, setcustomerassignedto] = useState(null);
  const phoneNumber = useSelector((state) => state.user.phoneNumber);
  const [employees, setemployees] = useState([]);
  const [selectedemployee, setselectedemployee] = useState("");

  const adminphoneid = useSelector((state) => state.user.adminphoneid);
  const waba_id = useSelector((state) => state.user.wabaid);
  const fetched = useSelector((state) => state.user.userchat);
  const name = useSelector((state) => state.user.name);
  const selectedcustomer = useSelector((state) => state.user.selectedcustomer);
  const [templatemessage, settemplatemessage] = useState("");
  const [istemplatemessageactive, setistemplatemessageactive] = useState(false);
  const [isLink, setisLink] = useState(false);
  const isMobile = useMediaQuery("(max-width:412px)");
  const [mobilechats,setmobilechats]=useState(false);

  const emojis = [
    "😀",
    "😃",
    "😄",
    "😁",
    "😆",
    "😅",
    "😂",
    "🤣",
    "😊",
    "😇",
    "🙂",
    "🙃",
    "😉",
    "😌",
    "😍",
    "🥰",
    "😘",
    "😗",
    "😙",
    "😚",
    "😋",
    "😛",
    "😝",
    "😜",
    "🤪",
    "🤨",
    "🧐",
    "🤓",
    "😎",
    "🤩",
    "🥳",
    "😏",
    "😒",
    "😞",
    "😔",
    "😟",
    "😕",
    "🙁",
    "☹️",
    "😣",
    "😖",
    "😫",
    "😩",
    "😢",
    "😭",
    "😤",
    "😠",
    "😡",
    "🤬",
    "🤯",
    "😳",
    "🥵",
    "🥶",
    "😱",
    "😨",
    "😰",
    "😥",
    "😓",
    "🤗",
    "🤔",
    "🤭",
    "🤫",
    "🤥",
    "😶",
    "😐",
    "😑",
    "😬",
    "🙄",
    "😯",
    "😦",
    "😧",
    "😮",
    "😲",
    "😴",
    "🤤",
    "😪",
    "😵",
    "🤐",
    "🥴",
    "🤢",
    "🤮",
    "🤧",
    "😷",
    "🤒",
    "🤕",
    "🤑",
    "🤠",
    "😈",
    "👿",
    "👹",
    "👺",
    "🤡",
    "💩",
    "👻",
    "💀",
    "☠️",
    "👽",
    "👾",
    "🤖",
    "🎃",
    "😺",
    "😸",
    "😹",
    "😻",
    "😼",
    "😽",
    "🙀",
    "😿",
    "😾",
    "👋",
    "🤚",
    "🖐",
    "✋",
    "🖖",
    "👌",
    "🤏",
    "✌️",
    "🤞",
    "🤟",
    "🤘",
    "🤙",
    "👈",
    "👉",
    "👆",
    "🖕",
    "👇",
    "☝️",
    "👍",
    "👎",
    "✊",
    "👊",
    "🤛",
    "🤜",
    "👏",
    "🙌",
    "👐",
    "🤲",
    "🤝",
    "🙏",
    "✍️",
    "💅",
    "🤳",
    "💪",
    "🦾",
    "🦵",
    "🦿",
    "🦶",
    "👂",
    "🦻",
    "👃",
    "🧠",
    "🫀",
    "🫁",
    "🦷",
    "🦴",
    "👀",
    "👁",
    "👅",
    "👄",
    "💋",
    "🩸",
    "👶",
    "🧒",
    "👦",
    "👧",
    "🧑",
    "👱",
    "👨",
    "🧔",
    "👱‍♂️",
    "👨‍🦰",
    "👨‍🦱",
    "👨‍🦳",
    "👨‍🦲",
    "👩",
    "👱‍♀️",
    "👩‍🦰",
    "👩‍🦱",
    "👩‍🦳",
    "👩‍🦲",
    "🧓",
    "👴",
    "👵",
    "🙍",
    "🙍‍♂️",
    "🙍‍♀️",
    "🙎",
    "🙎‍♂️",
    "🙎‍♀️",
    "🙅",
    "🙅‍♂️",
    "🙅‍♀️",
    "🙆",
    "🙆‍♂️",
    "🙆‍♀️",
    "💁",
    "💁‍♂️",
    "💁‍♀️",
    "🙋",
    "🙋‍♂️",
    "🙋‍♀️",
    "🧏",
    "🧏‍♂️",
    "🧏‍♀️",
    "🙇",
    "🙇‍♂️",
    "🙇‍♀️",
    "🤦",
    "🤦‍♂️",
    "🤦‍♀️",
    "🤷",
    "🤷‍♂️",
    "🤷‍♀️",
    "🧑‍⚕️",
    "👨‍⚕️",
    "👩‍⚕️",
    "🧑‍🎓",
    "👨‍🎓",
    "👩‍🎓",
    "🧑‍🏫",
    "👨‍🏫",
    "👩‍🏫",
    "🧑‍⚖️",
    "👨‍⚖️",
    "👩‍⚖️",
    "🧑‍🌾",
    "👨‍🌾",
    "👩‍🌾",
    "🧑‍🍳",
    "👨‍🍳",
    "👩‍🍳",
    "🧑‍🔧",
    "👨‍🔧",
    "👩‍🔧",
    "🧑‍🏭",
    "👨‍🏭",
    "👩‍🏭",
    "🧑‍💼",
    "👨‍💼",
    "👩‍💼",
    "🧑‍🔬",
    "👨‍🔬",
    "👩‍🔬",
    "🧑‍💻",
    "👨‍💻",
    "👩‍💻",
    "🧑‍🎤",
    "👨‍🎤",
    "👩‍🎤",
    "🧑‍🎨",
    "👨‍🎨",
    "👩‍🎨",
    "🧑‍✈️",
    "👨‍✈️",
    "👩‍✈️",
    "🧑‍🚀",
    "👨‍🚀",
    "👩‍🚀",
    "🧑‍🚒",
    "👨‍🚒",
    "👩‍🚒",
    "👮",
    "👮‍♂️",
    "👮‍♀️",
    "🕵️",
    "🕵️‍♂️",
    "🕵️‍♀️",
    "💂",
    "💂‍♂️",
    "💂‍♀️",
    "👷",
    "👷‍♂️",
    "👷‍♀️",
    "🤴",
    "👸",
    "👳",
    "👳‍♂️",
    "👳‍♀️",
    "👲",
    "🧕",
    "🤵",
    "👰",
    "🤰",
    "🤱",
    "👼",
    "🎅",
    "🤶",
    "🦸",
    "🦸‍♂️",
    "🦸‍♀️",
    "🦹",
    "🦹‍♂️",
    "🦹‍♀️",
    "🧙",
    "🧙‍♂️",
    "🧙‍♀️",
    "🧚",
    "🧚‍♂️",
    "🧚‍♀️",
    "🧛",
    "🧛‍♂️",
    "🧛‍♀️",
    "🧜",
    "🧜‍♂️",
    "🧜‍♀️",
    "🧝",
    "🧝‍♂️",
    "🧝‍♀️",
    "🧞",
    "🧞‍♂️",
    "🧞‍♀️",
    "🧟",
    "🧟‍♂️",
    "🧟‍♀️",
    "💆",
    "💆‍♂️",
    "💆‍♀️",
    "💇",
    "💇‍♂️",
    "💇‍♀️",
    "🚶",
    "🚶‍♂️",
    "🚶‍♀️",
    "🧍",
    "🧍‍♂️",
    "🧍‍♀️",
    "🧎",
    "🧎‍♂️",
    "🧎‍♀️",
    "👨‍🦯",
    "👩‍🦯",
    "👨‍🦼",
    "👩‍🦼",
    "👨‍🦽",
    "👩",
    "🏃",
    "🏃‍♂️",
    "🏃‍♀️",
    "💃",
    "🕺",
    "🕴️",
    "👯",
    "👯‍♂️",
    "👯‍♀️",
    "🧖",
    "🧖‍♂️",
    "🧖‍♀️",
    "🧘",
    "🧑‍🤝‍🧑",
    "👭",
    "👫",
    "👬",
    "💏",
    "👩‍❤️‍💋‍👨",
    "👨‍❤️‍💋‍👨",
    "👩‍❤️‍💋‍👩",
    "💑",
    "👩‍❤️‍👨",
    "👨‍❤️‍👨",
    "👩‍❤️‍👩",
    "👪",
    "👨‍👩‍👦",
    "👨‍👩‍👧",
    "👨‍👩‍👧‍👦",
    "👨‍👩‍👦‍👦",
    "👨‍👩‍👧‍👧",
    "👨‍👨‍👦",
    "👨‍👨‍👧",
    "👨‍👨‍👧‍👦",
    "👨‍👨‍👦‍👦",
    "👨‍👨‍👧‍👧",
    "👩‍👩‍👦",
    "👩‍👩‍👧",
    "👩‍👩‍👧‍👦",
    "👩‍👩‍👦‍👦",
    "👩‍👩‍👧‍👧",
    "👨‍👦",
    "👨‍👦‍👦",
    "👨‍👧",
    "👨‍👧‍👦",
    "👨‍👧‍👧",
    "👩‍👦",
    "👩‍👦‍👦",
    "👩‍👧",
    "👩‍👧‍👦",
    "👩‍👧‍👧",
    "🗣️",
    "👤",
    "👥",
    "👣",
    "🧳",
    "🌂",
    "☂️",
    "🎃",
    "🧵",
    "🧶",
    "👓",
    "🕶️",
    "🥽",
    "🥼",
    "🦺",
    "👔",
    "👕",
    "👖",
    "🧣",
    "🧤",
    "🧥",
    "🧦",
    "👗",
    "👘",
    "🥻",
    "🩱",
    "🩲",
    "🩳",
    "👙",
    "👚",
    "👛",
    "👜",
    "👝",
    "🛍️",
    "🎒",
    "👞",
    "👟",
    "🥾",
    "🥿",
    "👠",
    "👡",
    "🩰",
    "👢",
    "👑",
    "👒",
    "🎩",
    "🎓",
    "🧢",
    "⛑️",
    "💄",
    "💍",
    "💼",
    "🩸",
    "🩹",
    "🩺",
    "💉",
    "🩻",
    "🚪",
    "🛏️",
    "🛋️",
    "🪑",
    "🚽",
    "🚿",
    "🛁",
    "🪒",
    "🧴",
    "🧷",
    "🧹",
    "🧺",
    "🧻",
    "🧼",
    "🪠",
    "🪣",
    "🪤",
    "🪥",
    "🧽",
    "🧯",
    "🛒",
  ];
  const [emojiactive, setemojiactive] = useState(false);
  const [message, setmessage] = useState("");

  const [istemplatesending, setistemplatesending] = useState(false);
  const {
    selectedFile,
    openFileDialog,
    setSelectedFile,
    selectedImage,
    setSelectedImage,
  } = Usecustomfileinput();
  const [more, setmore] = useState(false);
  const [adminmsg, setadminmsg] = useState(false);
  const [activetereply, setactivatereply] = useState(false);
  let newchats = [];

  const handlefilesent = async () => {
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("sender_phone_number_id", adminphoneid);
    formdata.append("reciever_phone_number", phoneNumber);
    formdata.append("type", "doc");
    console.log(selectedFile);
    for (let pair of formdata.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    fetch("https://do.zopins.com/sendmedia", {
      method: "POST",
      body: formdata,
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    setSelectedFile(null);
  };

  const handlemessagesent = () => {
    handlescrolltonewmessage();
  };

  const handlescrolltonewmessage = () => {
    console.log("ref", messagescontainerref);
    if (messagescontainerref.current) {
      setTimeout(() => {
        messagescontainerref.current.scrollTop =
          messagescontainerref.current?.scrollHeight;
      }, 530);
    }
  };

  const sendmessagetoserver = (data) => {
    fetch(`https://do.zopins.com/sendmessagetouser`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sender: phoneNumber.split("-")[0],
        message: data,
        reciever: phoneNumber,
      }),
    })
      .then((response) => {
        console.log(response);
        handlescrolltonewmessage();
        getlatestchats()
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handlekeydown = async (e) => {
    if (e.key === "Enter" && message) {
      const messagedata = {
        from: "admin",
        to: "user",
        type: "text",
        visitorid:phoneNumber,
        admin:phoneNumber.split("-")[0],
        body: {
          message: message,
          timeStamp: new Date(),
        },
      };
      latestchats.push(messagedata);
      sendmessagetoserver(messagedata);
      setmessage("");
      console.log("latest chats", latestchats);
    }
  };
  

  const getlatestchats=async()=>{
    const response=await fetch(`https://do.zopins.com/getdomainchats/${allchats.app_token}`,{
        method:'GET',
        headers:{
          'Access-Control-Allow-origin':'*'
        }
    })
    const data=await response.json();
    dispatch(setbotchats(data));
    console.log(data);
}

  const downloadFile = async (doc_name, doc_id, doc_type) => {
    const new_doc_type = doc_type.split("/")[1];
    console.log(new_doc_type);
    console.log(doc_name);
    console.log(doc_id);

    const url = `https://do.zopins.com/documents/${doc_id}.${new_doc_type}`;
    window.open(url, "_blank");
  };
  // const downloadsentFile = async (doc_name, doc_id, doc_type) => {
  //   const new_doc_type = doc_type.split("/")[1];
  //   console.log(new_doc_type);
  //   console.log(doc_name);
  //   console.log(doc_id);

  //   const url = `https://do.zopins.com/uploadeddocs/${doc_id}.${new_doc_type}`;
  //   window.open(url, "_blank");
  // };
  // const savesentmessagetouser = (data) => {
  //   newchats.push(data);
  //   console.log(newchats);
  //   setlatestchats([...newchats]);
  //   console.log('latestchats',latestchats)
  //   handlescrolltonewmessage();
  // };

  useEffect(() => {
    if (localStorage.getItem("isadminAuthenticated") !== "true") {
      return navigate("/login");
    }
    setactivephoneNumber(phoneNumber);
    if (messagescontainerref.current) {
      messagescontainerref.current.scrollTop =
        messagescontainerref.current?.scrollHeight;
    }

    socket.on("sendmessagetoadmin", (data) => {
      setlatestchats([...latestchats, data.message]);
     // getlatestchats();
      handlescrolltonewmessage();
    });



  }, [phoneNumber,latestchats,navigate,socket]);

  const findcustomerassignment = () => {
    let found = false;
    employees.forEach((emp, index) => {
      emp.assigned_clients.forEach((client, idx) => {
        if (phoneNumber === client.sender_phone_number) {
          console.log("assigned employee", emp);
          setcustomerassignedto(emp);
          found = true;
        }
      });
    });
    if (found === false) {
      setcustomerassignedto(null);
    }
  };

  const handleCloseModal = () => {
    setSelectedImg(null);
  };

  const getdate = (timestamp) => {
    const date = new Date(timestamp);

    return date.toLocaleDateString();
  };
  const gettime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  const handlecustomerassignment = async (customer, employee) => {};
  const inputRef = useRef(null);

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    if (
      clipboardData &&
      clipboardData.files &&
      clipboardData.files.length > 0
    ) {
      const file = clipboardData.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formataigenerateddata=(text)=>{

    let formattedText = text.replace(/\*\*(.*?)\*\*/g, `<strong style="color:green">$1</strong>`);
    formattedText = formattedText.replace(/\*(.*?)\*/g, '<div>$1</div>');
    // Replace newlines with <br> tags
    formattedText = formattedText.replace(/\n/g, '</br>');
    const parsedText = <div dangerouslySetInnerHTML={{ __html: formattedText }} />;
    return parsedText;
  }
  return (
    <>
      {selectedImg && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-img" onClick={handleCloseModal}>
              X
            </span>
            <img src={selectedImg} alt="Selected Imag" className="full-image" />
          </div>
        </div>
      )}

      {selectedFile && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-btn" onClick={() => setSelectedFile(null)}>
              Close
            </button>
            <div>SELECTED DOCUMENT</div>
            <div>
              {selectedFile.type.split("/")[0] === "application" ? (
                <>
                  <embed
                    src={selectedImage}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                    className="imagetobesent"
                  />
                </>
              ) : selectedFile.type.startsWith("video/") ? (
                <>
                  <video controls className="imagetobesent">
                    <source src={selectedImage} type={selectedFile.type} />
                    Your browser does not support the video tag.
                  </video>
                </>
              ) : (
                <>
                  <img
                    src={selectedImage}
                    alt="img not found"
                    className="imagetobesent"
                  />
                </>
              )}
            </div>
            <div className="docsend" onClick={handlefilesent}>
              Send
            </div>
          </div>
        </div>
      )}
        <AnimatedContainer>
          <div className="box">
            {!isMobile && <Chatbotsidebar setlatestchats={setlatestchats} />}

            {phoneNumber === "" ? (
              <>
                <div className="chatsentrycontainer">
                  <div className="chatscontainerhead">Chats</div>
                  <div className="chatscontainerinfo">
                    Please select a contact to start chatting.
                  </div>
                </div>
              </>
            ) : (
              <>
                <div></div>
                <div className="chatstopbar">
                  {
                  isMobile && (
                    <img
                      width="48"
                      height="48"
                      src="https://img.icons8.com/fluency-systems-regular/48/FFFFFF/long-arrow-left.png"
                      alt="long-arrow-left"
                      className="backarrow"
                      onClick={() => {
                        navigate("/chatbot/chats");
                      }}
                    />
                  )}
                  <div className="profileicon">
                    {name.split("-")[1].substr(0, 1).toUpperCase()}
                  </div>
                  <div className="profilename chatbotprofilename">

                    {/* {name.split("-")[1].toUpperCase()}&nbsp; */}
                    {name.toUpperCase()} &nbsp;

                    {customerassignedto && (
                      <span className="customerassignmentinfo">
                        Assigned to {customerassignedto.name}
                      </span>
                    )}
                  </div>
                  <div className="profilephone chatbotprofilephone">
                    {name.split("-")[1].toUpperCase()}
                  </div>
                  <div className="phonecontainer">
                    <span title="Messages from admin for this chat.">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="100"
                        height="100"
                        viewBox="0 0 50 50"
                        style={{ fill: "#074158" }}
                        className="adminmsg"
                        onClick={() => {
                          setadminmsg(true);
                          setmore(false);
                          setactivatereply(false);
                        }}
                      >
                        <path d="M 25 4 C 12.316406 4 2 12.972656 2 24 C 2 30.1875 5.335938 36.066406 10.949219 39.839844 C 10.816406 40.890625 10.285156 43.441406 8.183594 46.425781 L 7.078125 47.992188 L 9.054688 48 C 14.484375 48 18.15625 44.671875 19.363281 43.394531 C 21.195313 43.796875 23.089844 44 25 44 C 37.683594 44 48 35.027344 48 24 C 48 12.972656 37.683594 4 25 4 Z"></path>
                      </svg>
                    </span>
                    <img
                      width="50"
                      height="50"
                      src="https://img.icons8.com/ios-filled/50/06384e/ringer-volume.png"
                      alt="ringer-volume"
                      className="phoneicon"
                      title="Call"
                    />

                    <img
                      width="50"
                      height="50"
                      src="https://img.icons8.com/ios-filled/50/06384e/menu-2.png"
                      alt="menu-2"
                      className="threedots"
                      title="Add chat notes"
                      onClick={() => {
                        setmore(true);
                        setadminmsg(false);
                      }}
                    />
                  </div>
                </div>

                <div className="chatsarea" 
                style={mobilechats && isMobile?{height:'77.5vh'}:{}}
                ref={messagescontainerref}>
                  {fetched &&
                    fetched.chat.map((chat, index) => {
                      return (
                        <>
                          {chat.from === "user" ? (
                            <>
                              <div className="recieved">
                                {chat.type === "text" || chat.type === "quickreply" ? (
                                  <>
                                    <div className="recievedtext">
                                      {chat.body.message}
                                      <div className="timestamp timestampimg">
                                        {getdate(chat.body.timeStamp)}
                                        <div>
                                          &nbsp;|&nbsp;
                                          {gettime(chat.body.timeStamp)}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              {chat.type === "text"    ? (
                                <>
                                  <div className="sent">
                                    <div className="senttext">
                                      {chat.body.message}
                                      <div className="timestamp timestampimg">
                                        {getdate(chat.body.timeStamp)}
                                        <div>
                                          &nbsp;|&nbsp;
                                          {gettime(chat.body.timeStamp)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : chat.type==='template'?<>
                                 <div className="sent">
                                  <div className="templatebody">
                                    {chat.data.details.answer.message}
                                    {chat.data.details.answer.buttons && chat.data.details.answer.buttons.map((button,index)=>{
                                      return <>
                                       <div key={index} className="buttonsoftemplate">
                                        {button.text}
                                       </div>
                                      </>
                                    }) }
                                <div className="timestamp timestampimg">
                                        {getdate(chat.timestamp)}
                                        <div>
                                          &nbsp;|&nbsp;
                                          {gettime(chat.timestamp)}
                                        </div>
                                      </div>

                                  </div>

                                 </div>
                              </>:chat.type==='aicontent'?(
                                <>
                                  <div className="sent">
                                    <div className="senttext">
                                      {formataigenerateddata(chat.body.message)}
                                      <div className="timestamp timestampimg">
                                        {getdate(chat.body.timeStamp)}
                                        <div>
                                          &nbsp;|&nbsp;
                                          {gettime(chat.body.timeStamp)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ):chat.type==="keywordbased"?<>
                                                              <div className="sent">
                                  <div className="templatebody">
                                  {chat.data[2].content}  
                                <div className="timestamp timestampimg"
                                style={isMobile?{fontWeight:'500'}:{}}
                                >
                                        {getdate(chat.timestamp)}
                                        <div>
                                          &nbsp;|&nbsp;
                                          {gettime(chat.timestamp)}
                                        </div>
                                      </div>

                                  </div>

                                 </div>
                              </>:<></>}
                            </>
                          )}
                        </>
                      );
                    })}
                  {latestchats &&
                    latestchats.map((chat, index) => {
                      return (
                        <>
                          {chat.visitorid === phoneNumber && <>
                            {chat.from === "user" ? (
                            <>
                              <div className="recieved">
                                {chat.type === "text" || chat.type === "quickreply" ? (
                                  <>
                                    <div className="recievedtext">
                                      {chat.body.message}
                                      <div className="timestamp timestampimg"
                                       style={isMobile?{fontWeight:'500'}:{}}
                                      >
                                        {getdate(chat.body.timeStamp)}
                                        <div>
                                          &nbsp;|&nbsp;
                                          {gettime(chat.body.timeStamp)}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              {chat.type === "text"    ? (
                                <>
                                  <div className="sent">
                                    <div className="senttext">
                                      {chat.body.message}
                                      <div className="timestamp timestampimg"
                                       style={isMobile?{fontWeight:'500'}:{}}
                                      >
                                        {getdate(chat.body.timeStamp)}
                                        <div>
                                          &nbsp;|&nbsp;
                                          {gettime(chat.body.timeStamp)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : chat.type==='template'?<>
                                 <div className="sent">
                                  <div className="templatebody">
                                    {chat.data.details.answer.message}
                                    {chat.data.details.answer.buttons && chat.data.details.answer.buttons.map((button,index)=>{
                                      return <>
                                       <div key={index} className="buttonsoftemplate">
                                        {button.text}
                                       </div>
                                      </>
                                    }) }
                                <div className="timestamp timestampimg"
                                 style={isMobile?{fontWeight:'500'}:{}}
                                >
                                        {getdate(chat.timestamp)}
                                        <div>
                                          &nbsp;|&nbsp;
                                          {gettime(chat.timestamp)}
                                        </div>
                                      </div>

                                  </div>

                                 </div>
                              </>:chat.type==='aicontent'?(
                                <>
                                  <div className="sent">
                                    <div className="senttext">
                                      {formataigenerateddata(chat.body.message)}
                                      <div className="timestamp timestampimg"
                                       style={isMobile?{fontWeight:'500'}:{}}
                                      >
                                        {getdate(chat.body.timeStamp)}
                                        <div>
                                          &nbsp;|&nbsp;
                                          {gettime(chat.body.timeStamp)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ):chat.type==='keywordbased'?<>
                                <div className="sent">
                                  <div className="templatebody">
                                  {chat.data[2].content}  
                                <div className="timestamp timestampimg"
                                 style={isMobile?{fontWeight:'500'}:{}}
                                >
                                        {getdate(chat.timestamp)}
                                        <div>
                                          &nbsp;|&nbsp;
                                          {gettime(chat.timestamp)}
                                        </div>
                                      </div>

                                  </div>

                                 </div>
                              </>:<>
                              
                              </>}
                            </>
                          )}
                           </>}
                        </>
                      );
                    })}

                  {istemplatemessageactive && (
                    <>
                      <div className=" dummymessage ">
                        {templatemessage}
                        <div className="timestamp">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            style={{ fill: "#FFFFFF" }}
                            className="singletick"
                          >
                            <path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"></path>
                          </svg>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {more && (
                  <>
                    <div className="morebox">
                      <img
                        width="30"
                        height="30"
                        src="https://img.icons8.com/ios-glyphs/30/074158/multiply.png"
                        alt="multiply"
                        className="crossicon"
                        onClick={() => setmore(false)}
                      />
                      <div className="morehead">Assign Client</div>
                      <div className="employeecontainer">
                        {employees &&
                          employees.map((employee, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="pemployee"
                                  //  onClickCapture={()=>{setselectedemployee(employee.name)}}
                                  onClick={() => {
                                    setselectedemployee(employee.name);
                                  }}
                                  style={
                                    selectedemployee === employee.name
                                      ? {
                                          backgroundColor: "#052b3a",
                                          color: "white",
                                        }
                                      : {}
                                  }
                                >
                                  {employee.name.toUpperCase()}&nbsp; (+
                                  {employee.phone_number})
                                </div>
                                {selectedemployee === employee.name && (
                                  <>
                                    <div
                                      className="selectemployeebtn"
                                      onClick={() => {
                                        handlecustomerassignment(
                                          selectedcustomer,
                                          employee
                                        );
                                        setselectedemployee(null);
                                        setmore(!more);
                                      }}
                                    >
                                      Assign client to {selectedemployee}
                                    </div>
                                  </>
                                )}
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}
                {emojiactive && (
                  <>
                    <div className="emojiscontainer">
                      {emojis.map((emoji, index) => {
                        return (
                          <>
                            <span
                              key={index}
                              className="emoji"
                              //  onClick={()=>setmessage(message+emoji)}
                              onClickCapture={() => {
                                if (message === "") {
                                  setmessage(emoji);
                                } else {
                                  setmessage(message + emoji);
                                }
                              }}
                            >
                              {emoji}
                            </span>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}

                <div className="messagearea"
                style={mobilechats && isMobile ?{backgroundColor:'transparent'}:{}}
                >
                  <div className="file-element">
                    <img
                      width="48"
                      height="48"
                      src="https://img.icons8.com/pulsar-line/48/053548/prototype.png"
                      alt="prototype"
                      className="emojiicon"
                      onClick={() => {
                        setistemplatesending(!istemplatesending);
                      }}
                    />
                    <img
                      width="60"
                      height="60"
                      src="https://img.icons8.com/external-vitaliy-gorbachev-fill-vitaly-gorbachev/60/06384e/external-file-home-office-vitaliy-gorbachev-fill-vitaly-gorbachev.png"
                      alt="external-file-home-office-vitaliy-gorbachev-fill-vitaly-gorbachev"
                      className="fileicon"
                      onClick={openFileDialog}
                    />

                    <img
                      width="50"
                      height="50"
                      src="https://img.icons8.com/ios-filled/50/06384e/happy--v1.png"
                      alt="happy--v1"
                      className="emojiicon2"
                      onClick={() => {
                        setemojiactive(!emojiactive);
                      }}
                    />
                  </div>
                  <input
                    type="text"
                    className="message-input"
                    placeholder="Your message here..."
                    onClick={()=>{
                      setmobilechats(true);
                    }}
                    value={message}
                    style={isLink ? { color: "blue" } : {}}
                    ref={inputRef}
                    onPaste={handlePaste}
                    onChange={(ev) => {
                      setmessage(ev.target.value);
                      const urlRegex = /(https?:\/\/[^\s]+)/g;
                      const containsLink = urlRegex.test(message);
                      setisLink(containsLink);
                    }}
                    onInputCapture={(ev) => {
                      setmessage(ev.target.value);
                      const urlRegex = /(https?:\/\/[^\s]+)/g;
                      const containsLink = urlRegex.test(message);
                      setisLink(containsLink);
                    }}
                    onKeyDown={handlekeydown}
                  />
                  <button className="send-button" onClick={handlemessagesent}>
                    <img
                      width="64"
                      height="64"
                      src="https://img.icons8.com/ios-filled/50/FFFFFF/sent.png"
                      alt="sent"
                      className="sendicon"
                    />
                  </button>
                </div>
              </>
            )}
          </div>
        </AnimatedContainer>
      
    </>
  );
};

export default Chatbotchats;
