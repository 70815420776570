import React, { useEffect, useState, useRef } from "react";

import "./employeelogin.css";
import "./facebooklogin.css";
import { auth } from "../Firebase/firebase";
import { useNavigate } from "react-router-dom";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {useMediaQuery,useModelState} from '../Custom_hooks/Custom'
import HALO from "vanta/dist/vanta.halo.min";
import * as THREE from "three";

const Mainsignup = () => {
  const navigate = useNavigate();
  const [email, setemail] = useState('');
  const [password, setpassword] = useState("");
  const [error, seterror] = useState();
  const [issignup, setissignup] = useState(false);
  const [name,setname]=useState('');
  const isMobile=useMediaQuery('(max-width:412px)');
  const handlecompanycreation = () => {
    setissignup(true);

    if (!email || !password) {
      seterror("One or more fields are empty");
      return;
    }
    // const actionCodeSettings = {
    //   url: `https://zopins.com/employeeverify/${email}`,
    //   handleCodeInApp: true,
    // };

    // createUserWithEmailAndPassword(auth, email, password)
    //   .then((user) => {
    //     console.log(user);
        saveusertoDB();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     seterror(error);
    //     setissignup(false);
    //   });
  };

  const handleadminlogin2 = async () => {
    const response = await fetch(
      `https://do.zopins.com/checkuser/${localStorage.getItem('useremail')}`
    );
    const data = await response.json();
    console.log(data);
    if (data.validated === true) {
      console.log('exists')
      // localStorage.setItem("isadminAuthenticated", "true");
      // setissignup(false);
      // navigate("/admindashboard");
    } else {
      seterror({ message: "Database:Error(auth/No user found.)" });
    }
};
  const saveusertoDB = async () => {
    const authid = auth.currentUser.uid;
  //  fetchuser();
    console.log(authid);
    fetch(`https://do.zopins.com/signup`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: authid,
        email: email,
        password: password,
      }),
    })
      .then((response) => {
        console.log(response);
        fetch(`https://do.zopins.com/createadmin`, {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            authid: authid,
            admin_phone_number_id: "",
            wabaid: "",
          }),
        })
          .then((response) => {
            console.log(response);
            fetch(`https://do.zopins.com/createchattingarea`, {
              method: "POST",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                authid: authid,
                name: "",
                phoneNumber: "",
                phoneNumberId: "",
                wabaid: "",
              }),
            })
              .then((response) => {
                console.log(response);
              //  navigate("/signup");
                handlefindlogintype();
              })
              .catch((error) => {
                console.log({ message: "Database:Error(auth/user exists.)" });
              });
          })
          .catch((error) => {
            console.log({ message: "Database:Error(auth/User exists.)" });
          });
      })

      .catch((error) => {
        console.log({ message: "Database:Error(auth/user exists.)" });
      });
  };
  const handlefindlogintype = async () => {
    const response = await fetch(
      `https://do.zopins.com/findlogintype/${email}/${password}`,
      { method: "GET" }
    );
    const data = await response.json();
    console.log(data);
    if (data.validated === "admin") {
      return handleadminlogin();
    }

    return seterror({ message: "Database:Error(auth/No user found.)" });
  };
  const handleadminlogin = async () => {
        localStorage.setItem("adminauthid", auth.currentUser.uid);
        console.log(auth.currentUser.uid);
        const response = await fetch(
          `https://do.zopins.com/getcompany/${auth.currentUser.uid}`
        );
        const data = await response.json();
        console.log(data);
        if (data.validated === true) {
          localStorage.setItem("isadminAuthenticated", "true");
          setissignup(false);
          navigate("/admindashboard");
        } else {
          seterror({ message: "Database:Error(auth/No user found.)" });
        }
  };

  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);

  useEffect(() => {
    if(!auth.currentUser){
        navigate('/')
    }
    setemail(localStorage.getItem('useremail'));
    setname(localStorage.getItem('username'));

    if(isMobile){
      if (!vantaEffect) {
        setVantaEffect(HALO({
          THREE,
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          amplitudeFactor: 2,
          xOffset: 0,
          yOffset: -0.00005,
          size: 0.3
        }))
      }
    }
    else{
      if (!vantaEffect) {
        setVantaEffect(HALO({
          THREE,
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          amplitudeFactor: 2,
          xOffset: 0.25,
          yOffset: -0.05,
          size: 1.8
        }))
      }
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect]);
  return (
    <>
      <div className="mainlogincontainer">
        <div className="logincontainer" style={{ zIndex: "2000" }}>
          <div className="loginhead eloginhead">SignUp</div>
          {error ? (
            <>
              <div className=" ebox2">
                {error.message.split("/")[1].split(")")[0]}
              </div>
            </>
          ) : (
            <></>
          )}

         <div className="authname">
         <input
            disabled
              type="email"
              placeholder="Email"
              className="employeeauthinput"
              value={name}
            />
         </div>
          <div className="loginform">
            <input
            disabled
              type="email"
              placeholder="Email"
              className="employeeauthinput"
              value={email}
            //   onChange={(ev) => setemail(ev.target.value)}
            //   onInputCapture={(ev) => {
            //     setemail(ev.target.value);
            //   }}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              className="employeeauthinput"
              value={password}
              onChange={(ev) => setpassword(ev.target.value)}
              onInputCapture={(ev) => {
                setpassword(ev.target.value);
              }}
            />
          </div>
          <button
            //   onClick={handlefindlogintype}
            onClick={() => {
              handlecompanycreation();
            }}
            className="loginbtn"
          >
            <span className="">
              {issignup ? (
                <>
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                </>
              ) : (
                <>Sign up</>
              )}
            </span>
          </button>
          {/* <div className='or'>or</div>
     <div className='googlelogin'>
            <svg xmlns="http://www.w3.org/2000/svg" className='googleicon' x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
<path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
</svg>
Login With Google 
            </div> */}
        </div>
        <div
          ref={myRef}
          className="homebackground"
          style={{ zIndex: "200" }}
        ></div>
        <div className="loginstyle"></div>
      </div>
    </>
  );
};

export default Mainsignup;
