import React from 'react'
import {useNavigate} from 'react-router-dom';
import { useEffect } from 'react';
import { auth } from '../../Firebase/firebase';
import { useSelector } from 'react-redux';
import {useMediaQuery} from '../../Custom_hooks/Custom'
const Adminsettings = () => {
  const isMobile=useMediaQuery('(max-width:412px)');
  const navigate=useNavigate();
  const phonenumberid=useSelector(state=>state.user.phonenumberid);

  useEffect(()=>{
    if(localStorage.getItem('isadminAuthenticated')!=='true'){
      navigate('/login')
    } 
  })
  return <>
  {
    phonenumberid!==''?<>
  <div className="chatsentrycontainer"
      style={!isMobile?{marginLeft:'30%'}:{}}
      >
                <div className="chatscontainerhead">Settings</div>
                <div className="chatscontainerinfo">
                 Feature comming soon...
                </div>
              </div>
    </>:<>
    <div className="chatsentrycontainer"
      style={!isMobile?{marginLeft:'30%'}:{}}
      >
                <div className="chatscontainerhead">Settings</div>
                <div className="chatscontainerinfo">
                 Please complete whatsapp embedded signup in your dashboard to access this feature.
                </div>
              </div>
    </>
  }
  
  
  </>
}

export default Adminsettings
