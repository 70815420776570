import React, { useEffect, useState } from 'react'

import './Admindash.css'
import NewTemplates from './NewTemplates'
import Mytemplates from './Mytemplates'
import Analytics from './Analytics'

import {useNavigate} from 'react-router-dom'
import Newuser from './Newuser'
import Viewusers from './Viewusers'
import Createapp from './CreateApp'
import { auth } from '../../Firebase/firebase';
import {useDispatch,useSelector} from 'react-redux';
import {setphoneid,setwabaid,setadminname,setadminphone,setallchats} from '../../Reduxstore/store'
import Aiflow from '../Aiflow/Aiflow'

const Admindashboard = () => {
  const dispatch=useDispatch();  
 const adminphoneid=useSelector(state=>state.user.adminphoneid);
 const allchats=useSelector(state=>state.user.allchats);
  const navigate=useNavigate();
  const [active,setactive]=useState('create')
  const firebaseauthid=localStorage.getItem('adminauthid')

  useEffect(()=>{
     if(localStorage.getItem('isadminAuthenticated')!=='true'){
       navigate('/login')
     } 
     else{
      fetchadmin();
     }
      async function fetchadmin(){
        const response=await fetch(`https://do.zopins.com/getadmin/${firebaseauthid}`,{
          method:'GET',
          headers:{
            'Access-Control-Allow-origin':'*'
          }
      })
      const data=await response.json();
      dispatch( setphoneid(data.phone_number_id));
      dispatch(setadminname(data.name))
      dispatch(setwabaid(data.whatsapp_business_id))
      dispatch(setadminphone(data.phone_number));
      dispatch(setallchats(data.chats));
      console.log('all chats',allchats);
      }

  },[]);
  return <>
    <div className='maindashcontainer'>
   
    <div className='dashnav'>
     
      <button className='dele' onClick={()=>setactive('create')} style={active==='create'?{color:'#16AEE8',fontWeight:'bolder'}:{}}>Create App</button>
{adminphoneid!==''?<>
    <div className='dele' onClick={()=>setactive('analytics')} style={active==='analytics'?{color:'#16AEE8',fontWeight:'bolder'}:{}}> Data Analysis</div>
     <div className='dele' onClick={()=>setactive('newtemp')} style={active==='newtemp'?{color:'#16AEE8',fontWeight:'bolder'}:{}}>New Templates</div>
     <div className='dele' onClick={()=>setactive('mytemp')} style={active==='mytemp'?{color:'#16AEE8',fontWeight:'bolder'}:{}}>My Templates</div>
     <div className='dele' onClick={()=>setactive('newuser')} style={active==='newuser'?{color:'#16AEE8',fontWeight:'bolder'}:{}}>Add New User</div>
     <div className='dele' onClick={()=>setactive('view')} style={active==='view'?{color:'#16AEE8',fontWeight:'bolder'}:{}}>View Users</div>
     <div className='dele' onClick={()=>setactive('ai')} style={active==='ai'?{color:'#16AEE8',fontWeight:'bolder'}:{}}>Setup AI Flow</div>
     <div className='dele' onClick={()=>setactive('aito')} style={active==='aito'?{color:'#16AEE8',fontWeight:'bolder'}:{}}>others</div>
</>:<></>}
    </div>
    </div>
    {active==='analytics' && <Analytics/>}
    {active==='newtemp' && <NewTemplates/>}
    {active==='mytemp' && <Mytemplates/>}
    {active==='newuser' && <Newuser/>}
    {active==='view' && <Viewusers/>}
    {active==='create' && <Createapp/>}
    {active==='ai' && <Aiflow/>}
  </>
}

export default Admindashboard
