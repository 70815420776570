import React from 'react'

import './Createapp.css'
import { useState,useEffect } from 'react';

import {useDispatch} from 'react-redux';
import {setphoneid,setwabaid} from '../../Reduxstore/store';
import FacebookLogin from '../../Authentication/FacebookLogin';
import { useSelector } from 'react-redux';
import {createUserWithEmailAndPassword,sendSignInLinkToEmail } from "firebase/auth";
import { auth } from '../../Firebase/firebase';


const Createapp = () => {

  const [loginData, setLoginData] = useState(null);
  const dispatch=useDispatch();
  const adminphoneid=useSelector(state=>state.user.adminphoneid);


useEffect(() => {  
  window.fbAsyncInit = function () {
    // Facebook SDK initialization
    window.FB.init({
      appId: '1737444063400013', // Facebook App ID
      cookie: true, // enable cookies
      xfbml: true, // parse social plugins on this page
      version: 'v19.0' // Graph API version
    });
  };
  // Load the JavaScript SDK asynchronously
  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
}, []);

// Facebook Login with JavaScript SDK
const launchWhatsAppSignup = () => {
  // Conversion tracking code
  if (window.fbq) {
    window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: '1737444063400013', feature: 'whatsapp_embedded_signup' });
  }

  // Launch Facebook login
  window.FB.login(function (response) {
    if (response.authResponse) {
      const code = response.authResponse.code;
      setLoginData({ code });
      console.log(code);
      console.log(loginData);
      dispatch(setphoneid(code.data.phone_number_id));
      dispatch(setwabaid(code.data.waba_id));

      // The returned code must be transmitted to your backend, 
      // which will perform a server-to-server call from there to our servers for an access token
    } else {
      console.log('User cancelled login or did not fully authorize.');
    }
  }, {
    config_id: '275190725637175', 
    response_type: 'code',    // must be set to 'code' for System User access token
    override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
    extras: {
      setup: {
      }
    }
  });
};

const verifyemail=async()=>{
  const response=fetch(`https://do.zopins.com/getcompanyemail/${localStorage.getItem('adminauthid')}`);
  const data=await response.json()
  const email=data.email
  const actionCodeSettings = {
    url: `https://zopins.com/verifyadmin/${email}/:apikey}`,
    handleCodeInApp: true,
  };


  sendSignInLinkToEmail(auth,email,actionCodeSettings)
  .then((userCredential)=>{
     console.log(userCredential)
  })
  .catch(error=>{
    console.log(error);
  })

}
  return <>

  <div className='createappbox'>
    <div className='zopins'>Zopins</div>
    {
      adminphoneid?<>
       <div className='noappcreationmsg'>APP STATUS - &nbsp;<button className='appactivebtn' style={{backgroundColor:"#16AEE8",color:"green"}}>ACTIVE</button>
       
      <div style={{color:"#16AEE8"}}
      className='creatingmessage'
      >
         Your meta app is connected to zopins. You can now use zopins to interact with your customers.
        </div>
       </div>
      </>:<>
    <div className='createinfo'>
      Please select the meta app from the button given below to procede further. Note if you already have a whatsapp business number registered with meta , it will automatically show them otherwise create a new meta whatsapp business account by clicking on create new account.
    </div>
    <hr />
<div className='loginhead'></div>
  <button onClick={launchWhatsAppSignup} className='loginbtn'>
  <img width="48" height="48" src="https://img.icons8.com/fluency/48/facebook-new.png" alt="facebook-new" className='facebookicon'/>
     <span className='logintextinfo'>
     Select / Create meta app
        </span>
        </button>
<hr />

      </>
      
    }

  </div>
   
  </>
}

export default Createapp;
