import React, { useEffect, useState,useRef } from 'react'

import './employeelogin.css'
import './facebooklogin.css'
import { auth } from '../Firebase/firebase'
import {useNavigate} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux';
import {setemployeeauth,setallchats} from '../Reduxstore/store'

import {signInWithEmailAndPassword } from "firebase/auth";
import {setphoneid} from '.././Reduxstore/store';
import HALO from 'vanta/dist/vanta.halo.min'
import * as THREE from 'three';
import {useMediaQuery,useModelState} from '../Custom_hooks/Custom'


const Forgetpassword = () => {
  const dispatch=useDispatch();
  const isMobile=useMediaQuery('(max-width:412px)');
    const [loader,setloader]=useState(false);
    const [successmsg,setsuccessmsg]=useState(false);
    const navigate=useNavigate();
    const [email,setemail]=useState('');
    const [error,seterror]=useState();
    

   const sendforgetpasswordmail=()=>{
    setloader(true);
    fetch(`https://do.zopins.com/sendforgetpasswordmail`,{
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin':'*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
           email:email
        }),
      })
      .then(response => {
         if(response.status===201){
                setloader(false); 
           
            seterror( {message:'Database:Error(auth/Email not registered.)'})
            // setsuccessmsg( "No mail found ");
            setInterval(() => {
                setsuccessmsg(false);
                setemail('');
            }, 4000);
           console.log(response);
        }
        else if(response.status===200){
            setInterval(() => {
                setloader(false); 
            }, 2000);
            setsuccessmsg( "mail sent....");
            setInterval(() => {
                setsuccessmsg(false);
                setemail('');
                navigate('/login');
            }, 4000);
           console.log(response);
        }
      })
      .catch(error=>{
        setInterval(() => {
            setloader(false); 
        }, 2000);
        setInterval(() => {
            setsuccessmsg(false);
            setemail('');
        }, 4000);
        seterror( {message:'Database:Error(auth/Email not registered.)'})
      })
   }



    const [vantaEffect, setVantaEffect] = useState(null)
    const myRef = useRef(null)
    useEffect(() => {
        // if(isMobile){
        //     if (!vantaEffect) {
        //       setVantaEffect(HALO({
        //         THREE,
        //         el: myRef.current,
        //         mouseControls: true,
        //         touchControls: true,
        //         gyroControls: false,
        //         minHeight: 200.00,
        //         minWidth: 200.00,
        //         amplitudeFactor: 2,
        //         xOffset: 0,
        //         yOffset: -0.00005,
        //         size: 0.3
        //       }))
        //     }
        //   }
        //   else{
        //     if (!vantaEffect) {
        //       setVantaEffect(HALO({
        //         THREE,
        //         el: myRef.current,
        //         mouseControls: true,
        //         touchControls: true,
        //         gyroControls: false,
        //         minHeight: 200.00,
        //         minWidth: 200.00,
        //         amplitudeFactor: 2,
        //         xOffset: 0.25,
        //         yOffset: -0.05,
        //         size: 1.8
        //       }))
        //     }
        //   }
        //   return () => {
        //     if (vantaEffect) vantaEffect.destroy()
        //   }
    }, [])
  return <>
     <div className='mainlogincontainer'
     style={!isMobile?{height:'100vh'}:{}}
     >
  <div className='logincontainer' style={{zIndex:'2000'}}>
  <div className='loginhead eloginhead'>Forget Password</div>
        {
        error?<>
        <div className='forgeterror ebox2 '>
            Email not registered
            </div>
        </>
        :<>
        
        </>
     }

<div className='loginform lgin'>
   <input type="email" placeholder='Email' className='employeeauthinput'
     value={email}
     onChange={(ev)=>setemail(ev.target.value)}
     onInputCapture={(ev)=>{setemail(ev.target.value)}}
   />
</div>
  <button onClick={sendforgetpasswordmail} className='loginbtn'>
    {
        loader?<>
        <div className='loader-container'>
                      <div className='loader'></div>
                      </div>
        </>:successmsg?<>
        <span className=''>
            {successmsg}
        </span>
        </>:
     <span className=''>
    Send mail
        </span>
    }
        </button>
  </div>
  <div ref={myRef} className="homebackground" style={{zIndex:'200'}}></div>
  <div className='loginstyle'>
    <img src="https://i.postimg.cc/zGG2PTZh/newloginpic-removebg.png" alt="login img" className='loginimage' />
  </div>
  </div>
  
  </>
    
}

export default Forgetpassword
