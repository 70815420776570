import React, { useEffect, useState } from 'react'
import {Outlet} from 'react-router-dom'
import './Adminpage.css'
import Adminsidebar from '../Adminsidebar/Adminsidebar'
import Assignedclients from '../Assignedclients/Assignedclients'
import Assignnewclient from '../Assignnew/Assignnewclient'
import Sendmessage from '../Sendmessage/Sendmessage'
import {useSelector} from 'react-redux'
import styled, { keyframes } from 'styled-components';
import NewSidebar from '../../Userpanel/Sidebar/NewSidebar'
import Crmpage from '../../Userpanel/Crmpage/Crmpage'
import Admincrm from './Admincrm'
import { auth } from '../../Firebase/firebase'
import {useNavigate} from 'react-router-dom';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-5vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Styled component with animation
const AnimatedContainer = styled.div`
  animation: ${fadeInAnimation} 0.5s ease-in-out;
`;
const Adminpage = () => {
  const navigate=useNavigate();
    const phone = useSelector(state => state.user.employeephone);
    const name = useSelector(state => state.user.employeename);

    const [active,setactive]=useState('assigned');

    useEffect(()=>{
      if(!auth.currentUser){
        navigate('/login')
      }
    })

  return <>
  <Admincrm/>
  </>
}

export default Adminpage
