import React, { useRef } from "react";

import "./landingpage.css";

import './privacy.css'

const Privacypolicy = () => {
  const paraOne = useRef(null);
  const paraTwo = useRef(null);
  const paraThree = useRef(null);
  const paraFour = useRef(null);
  const paraFive = useRef(null);
  const paraSix = useRef(null);
  const paraSeven = useRef(null);
  const paraEight = useRef(null);
  const paraNine = useRef(null);
  const paraTen = useRef(null);
  const paraEleven = useRef(null);
  const paraTwelve = useRef(null);
  const paraThirteen = useRef(null);
  const paraFourteen = useRef(null);
  const paraFifteen = useRef(null);
  const paraSixteen = useRef(null);

  const scrollToOne = () => {
    if (paraOne.current) {
      window.scrollTo({
        top: paraOne.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToTwo = () => {
    if (paraTwo.current) {
      window.scrollTo({
        top: paraTwo.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToThree = () => {
    if (paraThree.current) {
      window.scrollTo({
        top: paraThree.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToFour = () => {
    if (paraFour.current) {
      window.scrollTo({
        top: paraFour.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToFive = () => {
    if (paraFive.current) {
      window.scrollTo({
        top: paraFive.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToSix = () => {
    if (paraSix.current) {
      window.scrollTo({
        top: paraSix.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToSeven = () => {
    if (paraSeven.current) {
      window.scrollTo({
        top: paraSeven.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToEight = () => {
    if (paraEight.current) {
      window.scrollTo({
        top: paraEight.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToNine = () => {
    if (paraNine.current) {
      window.scrollTo({
        top: paraNine.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToTen = () => {
    if (paraTen.current) {
      window.scrollTo({
        top: paraTen.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToEleven = () => {
    if (paraEleven.current) {
      window.scrollTo({
        top: paraEleven.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToTwelve = () => {
    if (paraTwelve.current) {
      window.scrollTo({
        top: paraTwelve.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToThirteen = () => {
    if (paraThirteen.current) {
      window.scrollTo({
        top: paraThirteen.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToFourteen = () => {
    if (paraFourteen.current) {
      window.scrollTo({
        top: paraFourteen.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToFifteen = () => {
    if (paraFifteen.current) {
      window.scrollTo({
        top: paraFifteen.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const scrollToSixteen = () => {
    if (paraSixteen.current) {
      window.scrollTo({
        top: paraSixteen.current.offsetTop,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  return (
    <>
    <div className="privacycontainer">
      <div className="Pmainhead">Privacy Policy</div>
      <hr />
      <div id="fullcontainer">
      <h4>INTRODUCTION</h4>
        <p>
          This privacy notice for Zopins
          ("Company", "we", "us", or "our"), describes how and why we might
          collect, store, use, and/or share ("process") your information when
          you use our services ("Services"), such as when you:
        </p>
        <ul>
          <li>
            Visit our website at 
            <a href="https://zopins.com/">  https://zopins.com/</a>, or any
            website of ours that links to this privacy notice.
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events.
          </li>
        </ul>
        <p>
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. 
        </p>

        

        <h4>TABLE OF CONTENTS</h4>

        <p className="table" onClick={scrollToOne}>
          1. WHAT INFORMATION DO WE COLLECT?
        </p>

        <p className="table" onClick={scrollToTwo}>
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </p>

        <p className="table" onClick={scrollToThree}>
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
          INFORMATION?
        </p>

        <p className="table" onClick={scrollToFour}>
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </p>

        <p className="table" onClick={scrollToFive}>
          5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </p>

        <p className="table" onClick={scrollToSix}>
          6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </p>

        <p className="table" onClick={scrollToSeven}>
          7. HOW LONG DO WE KEEP YOUR INFORMATION?
        </p>

        <p className="table" onClick={scrollToEight}>
          8. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </p>

        <p className="table" onClick={scrollToNine}>
          9. DO WE COLLECT INFORMATION FROM MINORS?
        </p>

        <p className="table" onClick={scrollToTen}>
          10. WHAT ARE YOUR PRIVACY RIGHTS?
        </p>

        <p className="table" onClick={scrollToEleven}>
          11. CONTROLS FOR DO-NOT-TRACK FEATURES
        </p>

        <p className="table" onClick={scrollToTwelve}>
          12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </p>

        <p className="table" onClick={scrollToThirteen}>
          13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </p>

        <p className="table" onClick={scrollToFourteen}>
          14. DO WE MAKE UPDATES TO THIS NOTICE?
        </p>

        <p className="table" onClick={scrollToFifteen}>
          15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </p>

        <p className="table" onClick={scrollToSixteen}>
          16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </p>

        <p>
          To save or protect an individual's vital interest. We may process your
          information when necessary to save or protect an individual’s vital
          interest, such as to prevent harm.
        </p>
        <h4 ref={paraOne}>1. WHAT INFORMATION DO WE COLLECT?</h4>
        <h5>Personal information you disclose to us</h5>
        <p>
          <strong>In Short: </strong>We collect personal information that you
          provide to us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>
          <strong>Personal Information Provided by You.</strong>The personal
          information that we collect depends on the context of your
          interactions with us and the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include the following:
        </p>
        <ul>
          <li>Usename</li>
          <li>Phone Numbers</li>
          <li>Email Addresses</li>
          <li>Passwords</li>
          <li>Mailing Addresses</li>
          <li>Billing Addresses</li>
          <li>Debit/Credit Card Numbers</li>
        </ul>
        <h4 ref={paraTwo}> 2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
        <p>
          <strong>In Short:</strong>We process your information to provide,
          improve, and administer our Services, communicate with you, for
          security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent.
        </p>
        <p>
          <strong>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </strong>
        </p>

        <p>
          <strong>
            To facilitate account creation and authentication and otherwise
            manage user accounts.
          </strong>
          We may process your information so you can create and log in to your
          account, as well as keep your account in working order.
        </p>
        <p>
          <strong>To save or protect an individual's vital interest.</strong>We
          may process your information when necessary to save or protect an
          individual’s vital interest, such as to prevent harm.
        </p>

        <h4 ref={paraThree}>
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
        </h4>

        <p>
          <strong>In Short:</strong> We only process your personal information
          when we believe it is necessary and we have a valid legal reason
          (i.e., legal basis) to do so under applicable law, like with your
          consent, to comply with laws, to provide you with services to enter
          into or fulfill our contractual obligations, to protect your rights,
          or to fulfill our legitimate business interests.
        </p>

        <p>If you are located in the EU or UK, this section applies to you.</p>

        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>

        <ul>
          <li>
            <strong>Consent:</strong> We may process your information if you
            have given us permission (i.e., consent) to use your personal
            information for a specific purpose. 
          </li>
          <li>
            <strong>Legal Obligations:</strong> We may process your information
            where we believe it is necessary for compliance with our legal
            obligations, such as to cooperate with a law enforcement body or
            regulatory agency, exercise or defend our legal rights, or disclose
            your information as evidence in litigation in which we are involved.
          </li>
          <li>
            <strong>Vital Interests:</strong> We may process your information
            where we believe it is necessary to protect your vital interests or
            the vital interests of a third party, such as situations involving
            potential threats to the safety of any person.
          </li>
        </ul>

        <p>If you are located in Canada, this section applies to you.</p>

        <p>
          We may process your information if you have given us specific
          permission (i.e., express consent) to use your personal information
          for a specific purpose, or in situations where your permission can be
          inferred (i.e., implied consent). 
        </p>

        <p>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>

        <ul>
          <li>
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way.
          </li>
          <li>For investigations and fraud detection and prevention.</li>
        </ul>

        <h4 ref={paraFour}>
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h4>

        <p>
          <strong>In Short:</strong> We may share information in specific
          situations described in this section and/or with the following third
          parties.
        </p>

        <p>
          We may need to share your personal information in the following
          situations:
        </p>

        <ul>
          <li>
            <strong>Business Transfers:</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
        </ul>

        <h4 ref={paraFive}>
          5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h4>

        <p>
          <strong>In Short:</strong> We may use cookies and other tracking
          technologies to collect and store your information.
        </p>

        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>

        <h4 ref={paraSix}>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h4>

        <p>
          <strong>In Short:</strong> If you choose to register or log in to our
          Services using a social media account, we may have access to certain
          information about you.
        </p>

        <p>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
        </p>

        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>

        <h4 ref={paraSeven}>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>

        <p>
          <strong>In Short:</strong> We keep your information for as long as
          necessary to fulfill the purposes outlined in this privacy notice
          unless otherwise required by law.
        </p>

        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than
          thirty six (36) months past the start of the idle period of the user's
          account.
        </p>

        <h3>Account Information</h3>

        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>Log in to your account settings and update your user account.</li>
          <li>Contact us using the contact information provided.</li>
        </ul>

        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>

        <h3>Cookies and Similar Technologies</h3>

        <p>
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. 
          .
        </p>

        <p>
          If you have questions or comments about your privacy rights, you may
          email us. 
        </p>

        <h4 ref={paraEleven}>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>

        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>

        <h4 ref={paraTwelve}>
          12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h4>

        <p>
          In Short: Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.
        </p>

        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>

        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g., backups, etc.).
        </p>

        <h3>CCPA Privacy Notice</h3>

        <p>The California Code of Regulations defines a "resident" as:</p>
        <ol>
          <li>
            every individual who is in the State of California for other than a
            temporary or transitory purpose and
          </li>
          <li>
            every individual who is domiciled in the State of California who is
            outside the State of California for a temporary or transitory
            purpose
          </li>
        </ol>

        <p>All other individuals are defined as "non-residents."</p>

        <p>
          If this definition of "resident" applies to you, we must adhere to
          certain rights and obligations regarding your personal information.
        </p>

        <h4>What categories of personal information do we collect?</h4>
        <p>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </p>
        <ul>
          <li>Category A - 36 months</li>
          <li>Category B - 36 months</li>
          <li>Category C - 36 months</li>
          <li>Category I - 36 months</li>
          <li>Category K - 36 months</li>
        </ul>
        <p>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </p>
        <ul>
          <li>Receiving help through our customer support channels;</li>
          <li>Participation in customer surveys or contests; and</li>
          <li>
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </li>
        </ul>

        <h4>How do we use and share your personal information?</h4>

        <p>
          More information about our data collection and sharing practices can
          be found in this privacy notice.
        </p>

        

        <p>
          If you are using an authorized agent to exercise your right to opt out
          we may deny a request if the authorized agent does not submit proof
          that they have been validly authorized to act on your behalf.
        </p>

        <h4>Will your information be shared with anyone else?</h4>

        <p>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that processes the
          information on our behalf, following the same strict privacy
          protection obligations mandated by the CCPA.
        </p>

        <p>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal information.
        </p>
        <p>
          Zopins has not disclosed, sold, or
          shared any personal information to third parties for a business or
          commercial purpose in the preceding twelve (12) months. zopins
           will not sell or share personal
          information in the future belonging to website visitors, users, and
          other consumers.
        </p>

        <h4>Your rights with respect to your personal data</h4>

        <p>
          <strong>
            Right to request deletion of the data — Request to delete
          </strong>
        </p>

        <p>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation, or any processing that
          may be required to protect against illegal activities.
        </p>

        <p>
          <strong>Right to be informed — Request to know</strong>
        </p>

        <p>Depending on the circumstances, you have a right to know:</p>
        <ul>
          <li>whether we collect and use your personal information;</li>
          <li>the categories of personal information that we collect;</li>
          <li>
            the purposes for which the collected personal information is used;
          </li>
          <li>
            whether we sell or share personal information to third parties;
          </li>
          <li>
            the categories of personal information that we sold, shared, or
            disclosed for a business purpose;
          </li>
          <li>
            the categories of third parties to whom the personal information was
            sold, shared, or disclosed for a business purpose;
          </li>
          <li>
            the business or commercial purpose for collecting, selling, or
            sharing personal information; and
          </li>
          <li>
            the specific pieces of personal information we collected about you.
          </li>
        </ul>

        <p>
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </p>

        <p>
          <strong>
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
            Rights
          </strong>
        </p>

        <p>
          We will not discriminate against you if you exercise your privacy
          rights.
        </p>

        <p>
          <strong>
            Right to Limit Use and Disclosure of Sensitive Personal Information
          </strong>
        </p>

        <p>We do not process consumer's sensitive personal information.</p>

        <p>
          <strong>Verification process</strong>
        </p>

        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication method
          (e.g., phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </p>

        <p>
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. However, if we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </p>

        <h2>Other Privacy Rights</h2>

        <p>You may object to the processing of your personal information.</p>
        <p>
          You may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the
          information.
        </p>
        <p>
          You can designate an authorized agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on
          your behalf in accordance with the CCPA.
        </p>
        <p>
          You may request to opt out from future selling or sharing of your
          personal information to third parties. Upon receiving an opt-out
          request, we will act upon the request as soon as feasibly possible,
          but no later than fifteen (15) days from the date of the request
          submission.
        </p>
        <p>
          To exercise these rights, you can contact us by email.
        </p>

        <h4 ref={paraThirteen}>
          13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h4>

        <p>
          In Short: Yes, if you are a resident of Virginia, you may be granted
          specific rights regarding access to and use of your personal
          information.
        </p>

        <p>Virginia CDPA Privacy Notice</p>

        <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>

        <p>
          "Consumer" means a natural person who is a resident of the
          Commonwealth acting only in an individual or household context. It
          does not include a natural person acting in a commercial or employment
          context.
        </p>

        <p>
          "Personal data" means any information that is linked or reasonably
          linkable to an identified or identifiable natural person. "Personal
          data" does not include de-identified data or publicly available
          information.
        </p>

        <p>
          "Sale of personal data" means the exchange of personal data for
          monetary consideration.
        </p>

        <p>
          If this definition "consumer" applies to you, we must adhere to
          certain rights and obligations regarding your personal data.
        </p>

        <p>
          The information we collect, use, and disclose about you will vary
          depending on how you interact with Zopins 
           and our Services. To find out more, please visit the following
          links:
        </p>

        <ul>
          <li>Personal data we collect</li>
          <li>How we use your personal data</li>
          <li>When and with whom we share your personal data</li>
          <li>Your rights with respect to your personal data</li>
          <li>
            Right to be informed whether or not we are processing your personal
            data
          </li>
          <li>Right to access your personal data</li>
          <li>Right to correct inaccuracies in your personal data</li>
          <li>Right to request deletion of your personal data</li>
          <li>
            Right to obtain a copy of the personal data you previously shared
            with us
          </li>
          <li>
            Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ("profiling")
          </li>
        </ul>

        <p>
          Zopins has not sold any personal data
          to third parties for business or commercial purposes. Zopins
           will not sell personal data in the
          future belonging to website visitors, users, and other consumers.
        </p>

        <p>Exercise your rights provided under the Virginia CDPA</p>

        <p>
          More information about our data collection and sharing practices can
          be found in this privacy notice.
        </p>

        

        <p>
          If you are using an authorized agent to exercise your rights, we may
          deny a request if the authorized agent does not submit proof that they
          have been validly authorized to act on your behalf.
        </p>

        <p>Verification process</p>

        <p>
          We may request that you provide additional information reasonably
          necessary to verify you and your consumer's request. If you submit the
          request through an authorized agent, we may need to collect additional
          information to verify your identity before processing your request.
        </p>

        <p>
          Upon receiving your request, we will respond without undue delay, but
          in all cases, within forty-five (45) days of receipt. The response
          period may be extended once by forty-five (45) additional days when
          reasonably necessary. We will inform you of any such extension within
          the initial 45-day response period, together with the reason for the
          extension.
        </p>

        <p>Right to appeal</p>

        <p>
          If we decline to take action regarding your request, we will inform
          you of our decision and reasoning behind it. If you wish to appeal our
          decision, please email us .
          Within sixty (60) days of receipt of an appeal, we will inform you in
          writing of any action taken or not taken in response to the appeal,
          including a written explanation of the reasons for the decisions. If
          your appeal if denied, you may contact the Attorney General to submit
          a complaint.
        </p>

        <h4 ref={paraFourteen}>14. DO WE MAKE UPDATES TO THIS NOTICE?</h4>

        <p>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </p>

        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>

        <h4 ref={paraFifteen}>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>

        <p>
          If you have questions or comments about this notice, you may email us.
        </p>

        <p>
          Office Address 
          <br />
          263 BMK Market,Behind Hive Hotel,
          <br /> Panipat,Haryana(132103)
          <br />
          India
        </p>

        <h4 ref={paraSixteen}>
          16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h4>

        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, 
        </p>
      </div>
      </div>
    </>
  );
};

export default Privacypolicy;



