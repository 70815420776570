import React, { useState,useEffect} from 'react'
import {Outlet,useNavigate} from 'react-router-dom'
import './actionbar.css'
import {signOut } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import {setallchats, setbotchats, setPhoneNumber} from '../../Reduxstore/store'
import styled, { keyframes } from 'styled-components';
import { auth } from '../../Firebase/firebase';

import {useMediaQuery} from '../../Custom_hooks/Custom'

const fadeInAnim = keyframes`
  from {
    opacity: 0;
    transform: translateX(-5vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const fadeInAnimY= keyframes`
  from {
    opacity: 0;
    z-index:100;
    transform: translateY(-5vw);
  }
  to {
    opacity: 1;
    z-index:100;
    transform: translateY(0);
  }
`;

// Styled component with animation
const Adminactionbar = () => {
  const navigate=useNavigate();
  const [active,setactive]=useState('');
  const isMobile=useMediaQuery('(max-width:412px)');
  const dispatch=useDispatch();
  const path=useSelector(state=>state.user.path)
  const AnimatedContainer = styled.div`
    animation: ${isMobile?fadeInAnimY:fadeInAnim} 0.55s ease-in-out;
  `;

  const handleSignout=()=>{
    console.log('signing out');
    signOut(auth)
    .then(result=>{
      localStorage.removeItem('isadminAuthenticated');
      localStorage.removeItem('adminauthid');
     dispatch(setPhoneNumber(''));
      console.log(result);
      navigate('/login');
    })
    .catch(error=>{
      console.log(error);
    })
  }
  useEffect(() => {
    console.log(path);
    if(path==='meta'){
      setactive('adminchats')
    }
    if(path==='chatbot'){
      setactive('chatbotdashboard')
    }
     
  }, [path]);

  return <>
  <AnimatedContainer>
  <div className='adminactionbarcontainer' style={{zIndex:'10'}}>
    {
      isMobile && active==="adminchats" && 
      <div className='chatsheadinfo'>
      Chats
    </div>
    }
     {
      isMobile && active==="admindashboard" &&
      <div className='chatsheadinfo'>
      Dashboard
    </div>
    }
    {
      isMobile && active==="adminsettings" &&
      <div className='chatsheadinfo'>
      Settings
    </div>
    }
        {
      isMobile && active==="chatbotdashboard" &&
      <div className='chatsheadinfo'>
      Chatbot 
    </div>
    }

<img width="24" height="24"
onClick={()=>{
  navigate('/selectservice')
}}
 className='leftarrowselectorpath' src="https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/24/16aee8/external-left-arrow-arrows-tanah-basah-basic-outline-tanah-basah.png" alt="external-left-arrow-arrows-tanah-basah-basic-outline-tanah-basah"/>
    
{ path==='meta' &&<>
  <div title='dashboard' 
  style={{zIndex:'2000'}}
  onClick={()=>{navigate('/admindashboard');setactive('admindashboard')}}
   className='adminactionelements'
  >
    <img width="48" height="48" src="https://img.icons8.com/pulsar-line/48/16aee8/dashboard-layout.png" alt="dashboard-layout" className='icons'
     style={active==='admindashboard'?{fill:"#16aee8",backgroundColor:'#094962',borderRadius:'2vw'}:{fill:"#16aee8"}}
    />
                </div>
                </>
}
               {!isMobile && path==='meta' && <>
                <div title='Meta chats' 
                onClick={()=>{
                  setactive('adminchats')
                  dispatch(setPhoneNumber(''));
                  dispatch(setallchats([]))
                  navigate('/admin/users')
                  }}
                 className='adminactionelements'
                >
                <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/16aee8/new-contact.png" alt="new-contact" 
                style={active==='adminchats'?{fill:"#16aee8",backgroundColor:'#094962',borderRadius:'2vw'}:{fill:"#16aee8"}}
                
               className='icons'
                />
                </div>
               </>}

               {isMobile && path==='meta' && <>
                <div title='Meta chats' 
                onClick={()=>{
                  setactive('adminchats')

                  navigate('/admin/chats')

                  }}
                 className='adminactionelements'
                >
                <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/16aee8/new-contact.png" alt="new-contact" 
                style={active==='adminchats'?{fill:"#16aee8",backgroundColor:'#094962',borderRadius:'2vw'}:{fill:"#16aee8"}}
                
               className='icons'
                />
                </div>
               </>}
                {path==='chatbot' && <>
                  <div title='Chatbot' 
                onClick={()=>{
                  setactive('chatbotdashboard')
                  dispatch(setPhoneNumber(''));
                  dispatch(setbotchats([]))
                  if(isMobile){
                    navigate('/chatbotdashboard');
                  }
                  else{
                    navigate('/chatbotdashboard')}}}
                 className='adminactionelements'
                >

                  <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/16aee8/message-bot.png" alt="chatbot--v1"
                  style={active==='chatbotdashboard'?{fill:"#16aee8",backgroundColor:'#094962',borderRadius:'2vw'}:{fill:"#16aee8"}}
                
                  className='icons'
                  />
                </div>
                
                </>}
            

                <div title='settings'  onClick={()=>{navigate('/adminsettings');setactive('adminsettings')}}
                 className='adminactionelements'
                >

                <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/16aee8/gear.png" alt="gear" className='icons'
                style={active==='adminsettings'?{fill:"#16aee8",backgroundColor:'#094962',borderRadius:'2vw'}:{fill:"#16aee8"}}
                />
                </div>

                {!isMobile && <>
                <div title='logout' 
                onClick={handleSignout}
            
                className='adminactionelements'
                >
                <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/16aee8/logout-rounded-left.png" alt="logout-rounded-left" className='icons'/>
                </div> 
                </>}

  </div>
  </AnimatedContainer>
  <Outlet/>
  
  </>
}

export default Adminactionbar;
