import React from 'react'
import './Footer.css'

const Footer = () => {
  return <>
     <div className='footer'>
        <div>
            <div className='footer-logo'>
            <img alt="MyPin" src="https://i.postimg.cc/XvK4p6Yy/z-logo-wire-Recovered-Recovered-1.png" className="f-logo"/>
            <div className='profile'>
                Zopins - Your AI Partner
            </div>
            </div>
            <div className='footer-solutions'>
               <div className='sol-head' style={{color:'#16AEE8'}}>Meta CRM</div>
               <div className='sols'>Connect Your Meta Apps With This CRM</div>
               <div className='sols'>Send Promotional Messages</div>
               <div className='sols'>Create AI Flows</div>
               <div className='sols'>Generate Custom Messages</div>
               <div className='sols'>Manage Your Clients</div>
            </div>
            <div className='footer-resources'>
               <div className='sol-head' style={{color:'#16AEE8'}}> AI ChatBot</div>
               <div className='sols'>Create Custom AI Chatbots</div>
               <div className='sols'>Embedd Multiple Chatbots</div>
               <div className='sols'>Generate AI Flow For Your Chatbot</div>
               <div className='sols'>Single Click To Create Chatbots</div>
               <div className='sols'>Train Your Chatbot</div>
            </div>
  
            <div className='footer-seo'>
               <div className='sol-head' style={{color:'#16AEE8'}}>SEO Analysis</div>
               <div className='sols'>Domain Overview</div>
               <div className='sols'>Word Counter</div>
               <div className='sols'>Image Counter</div>
               <div className='sols'>Warnings And Issues Analysis</div>
               <div className='sols'>Schema Generator</div>
            </div>


        </div>
     </div>
  
  </>
}

export default Footer
