import React from 'react'
import Actionbar from '../actionbar/Actionbar'
import './Notes.css'

const Notes = () => {
  return <>
  {/* <Actionbar/> */}
    <div className='noteshead'>Notes</div>
  </>
}

export default Notes
