import React from 'react'

import './Addchatflow.css'
import Aiflow from '../../Aiflow/Aiflow'

const Addchatfow = ({domainfornode}) => {
    console.log(domainfornode);
  return <>

   <Aiflow  usedin='bots' domainfornodeflow={domainfornode} />
  
  
  </>
}

export default Addchatfow
