import React from 'react'
import { useState } from 'react';
import './Keywordbasedtraining.css'

export default function Keywordbasedtraining() {
  const [quesAns,setQuesAns] = useState([]);
  const [ques,setques]=useState('');
  const [ans,setans]=useState('');
  const [issaved,setissaved]=useState(false);
  
  const addQuesAns = () =>{
        if(ques === '' || ans === ''){
          return
        }
       const data={
        "messages":[
          {
            "role":"system",
            "content":"you are a helpful assistant who will assist user who will come on our website"
          },
          {
            "role":"user",
            "content":ques
          },
          {
            "role":"assistant",
            "content":ans
          }
        ]
       }
       setQuesAns([...quesAns,data]);
       setques('')
       setans('')
  }

  const downloadJsonlFile =() => {
    const jsonlString = quesAns.map(item => JSON.stringify(item)).join('\n');
    const blob = new Blob([jsonlString], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    const filename=new Date();
    link.download = `quesans${filename}.jsonl`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    handlesavedatatodb();
  };

  const handlesavedatatodb=async()=>{

    fetch(`https://do.zopins.com/savequesans`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            domain:localStorage.getItem('domainfortraining'),
            authid:localStorage.getItem('adminauthid'),
            apptoken:localStorage.getItem('apptokenfortraining'),
            data:quesAns
        }),
      })
        .then((response) => {
          console.log(response);
          setQuesAns([]);
          setissaved(true);
        })
        .catch((error) => {
          console.log(error);
        });
        setTimeout(() => {
            setissaved(false); 
        }, 3000);
  }

  const handlegetsavedquesans=async()=>{
       const response=await fetch(`https://do.zopins.com/getsavedquesans/${localStorage.getItem('apptokenfortraining')}`);
       const data=await response.json();
       console.log(data);
        setQuesAns(data.usedquesans);
  }
  return (
    <>
     <div className="key-container">
     <div className="edit-box"> 
        <h3  className='keytraininghead'>
            {quesAns.length<1 ?<>
                <div className='downloadjsonlfilebtn'
             title='Question answers are less than 10. Minimum 10 required.'
            >
               Submit and Download file
            </div>
            </>:<>
            <div className='downloadjsonlfilebtn'
              onClick={()=>{downloadJsonlFile()}}
            >
               Submit and Download file
            </div>
            </>}
            <div  className='myquesansbtn'
            onClick={()=>{
                handlegetsavedquesans();
            }}
            >My Question and answers</div>
            <div  className='newquesans'
            onClick={()=>{
                setQuesAns([]);
            }}
            >New</div>

            Enter Question & Answer in the below fields
            </h3>
         <span>

        Question
         </span>
        <textarea name="" rows='3'
       
        className='keyinput'
        value={ques}
        onChange={(ev)=>{setques(ev.target.value)}}
        onInputCapture={(ev)=>{setques(ev.target.value)}}
        ></textarea>
        Answer
        <textarea name="" 
         className='keyinput'
  
        value={ans}
        onChange={(ev)=>{setans(ev.target.value)}}
        onInputCapture={(ev)=>{setans(ev.target.value)}}
        rows='3'></textarea>
        <button onClick={addQuesAns}
        
        >Save</button>
      </div>
      <div className="view-box">
        {issaved?<>
        <div className='successmessage'>Ques/Ans saved successfully</div>
        </>:<>
        {quesAns && quesAns.map((data,index)=>{
          return <>
         <div className="qa-box">
            <span>{index+1}</span>
            <div className='ques'>{data.messages[1].content}</div>
            <div className='ans'>{data.messages[2].content}</div>
         </div>
          </>
        })}  
        </>}
      </div>
     </div>
    </>
  )
}