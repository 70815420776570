import React from 'react'
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';


import './Analysis.css'
import { withTheme } from '@emotion/react';

const Analytics = () => {
  const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G',
];
  return <>
   <div className='analysiscontainer'>
    <div className='analysisbox1'>
      <div className='analysisgraphs'>
        <div className='newuserhead'> Increase in new crm users Per Day</div>
            <LineChart
      xAxis={[{ data: [0, 10, 20, 30, 40, 50] }]}
      series={[
        {
          data: [0, 5, 15, 10, 48, 50],
        },
      ]}
      width={340}
      height={180}
    />
      </div>
      <div className='analysisgraphs'>
      <div className='newuserhead' >Employee Data Analysis for Crm Usage</div>
            <div  className='piechart'>
            <PieChart
  series={[
    {
      data: [
        { id: 0, value: 10, label: 'Idol' },
        { id: 1, value: 15, label: 'working' },
        { id: 2, value: 20, label: 'others' },
      ],
    },
  ]}
  width={340}
  height={140}
/>
</div>

      </div>
    </div>
    <div className='analysisbox2'>
      <div className='analysisgraphs'>
      <div className='newuserhead' >Employee hours Analysis for Crm Usage</div>
            <div  className='piechart'>
            <BarChart
  xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
  series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
  width={304}
  height={150}
/>
</div>

      </div>
      <div className='analysisgraphs'>
      <div className='newuserhead' >Employee Data Analysis for Crm Usage</div>
            <div className='areachart'>
            <LineChart
      width={340}
      height={170}
      series={[{ data: uData, label: 'uv', area: true, showMark: false }]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      sx={{
        '.MuiLineElement-root': {
          display: 'none',
        },
      }}
    />
</div>

      </div>
    </div>

   </div>
  
  </>
}

export default Analytics
