import React, { useEffect, useState } from 'react'
import './Newuser.css'
import { auth } from '../../Firebase/firebase';
import { useSelector } from 'react-redux';
import {createUserWithEmailAndPassword,sendSignInLinkToEmail } from "firebase/auth";

const Newuser = () => {

    const [username,setusername]=useState('');
    const [userphone,setuserphone]=useState('');
    const [email,setemail]=useState('');
    const [password,setpassword]=useState('');
    const [isusersaved,setisusersaved]=useState(false);
    const [successmessage,setsuccessmessage]=useState(false);
    const [error,seterror]=useState(null);
    const admin_phone_number_id=useSelector(state=>state.user.adminphoneid)

    const handleUserCreation=async ()=>{
        const auth_id=auth.currentUser.uid;
        const id=Math.floor(Math.random() * Math.pow(10, 20))
        setisusersaved(true)

        const actionCodeSettings = {
          url: `http://zopins.com/verifyadmin?email=${email}`,
          handleCodeInApp: true,
        };

            fetch(`https://do.zopins.com/createnewuser`,{
                method: 'POST',
                headers: {
                  'Access-Control-Allow-Origin':'*',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    authid:auth_id,
                    employeeid:id,
                    admin_phone_number_id:admin_phone_number_id,
                    username:username,
                    userphone:userphone,
                    useremail:email,
                    userpassword:password
                })
              })
              .then(response => {
                  console.log(response);
                    setisusersaved(false);
                    setsuccessmessage(true);
                    sendSignInLinkToEmail(auth,email,actionCodeSettings)
        .then((userCredential)=>{
           console.log(userCredential)
        })
        .catch(error=>{
          console.log(error);
          seterror('User with this email already exists')
        })
              })
              .catch(error=>{
                console.log(error);
              })
           setTimeout(() => {
            setsuccessmessage(false);
           }, 6000);
          }
          
  return <>

   <div className='newusercintainer'>
    <div className='newuserhaed'> Add New User</div>
    <div className='userdetails'>
        <div>
       <input type="text" className="uinputs" placeholder='User Name'
       value={username}
         onChange={(ev)=>{setusername(ev.target.value)}}
         onInputCapture={(ev)=>{setusername(ev.target.value)}}
       />
        </div>
        <div>
       <input type="text"  className="uinputs" placeholder='Phone number'
       value={userphone}
       onChange={(ev)=>{setuserphone(ev.target.value)}}
       onInputCapture={(ev)=>{setuserphone(ev.target.value)}}
       />
        </div>
        <div>
       <input type="text" className="uinputs" placeholder='Email Address'
       value={email}
       onChange={(ev)=>{setemail(ev.target.value)}}
       onInputCapture={(ev)=>{setemail(ev.target.value)}}
       />
        </div>
        <div>
       <input type="text"  className="uinputs" placeholder='Password' 
       value={password}
       onChange={(ev)=>{setpassword(ev.target.value)}}
       onInputCapture={(ev)=>{setpassword(ev.target.value)}}
       />
        </div>
    <div className='createnewuserbtn' onClick={handleUserCreation}>
        {isusersaved?<>
            <div className='loader-container'>
                      <div className='loader'></div>
                      </div>
        </>:
       successmessage?<>
       <div className='successmessage'>User Created Successfully.Mail sent {email} for verification.</div>
  </>
  :<>
  Create
  </>
        }
        </div>
        
    </div>
   </div>
  </>
}

export default Newuser
