import React, { useState, useEffect, useRef } from "react";
import "rsuite/dist/rsuite.min.css";
import { useSelector, useDispatch } from "react-redux";
import Adminsidebar from "../Adminsidebar/Adminsidebar";
import { Usecustomfileinput,useMediaQuery } from "../../Custom_hooks/Custom";
import "../../Userpanel/Crmpage/Crm.css";
import "../../Userpanel/MainChat/Mainchat.css";
import { useNavigate } from "react-router-dom";


import "./Admincrm.css";


import styled, { keyframes } from "styled-components";
import "../../Userpanel/Crmpage/Sendingtemplate.css";
// import io from "socket.io-client";
// const socket = io.connect("https://do.zopins.com", {
//   forceNew: true,
//   transports: ["polling"],
// });

import socket from "./Socket";

const fadeInAnim = keyframes`
  from {
    opacity: 0;
    transform: translateX(-5vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const AnimatedContainer = styled.div`
  animation: ${fadeInAnim} 0.5s ease-in-out;
`;

const Crmpage = () => {
  const videoRef = useRef(null);
  const imageRef = useRef(null);
  const messagescontainerref = useRef(null);
  const navigate = useNavigate();
  const [latestchats, setlatestchats] = useState([]);
  const [activephoneNumber, setactivephoneNumber] = useState("");
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedvideo, setselectedvideo] = useState(null);

  const [customerassignedto, setcustomerassignedto] = useState(null);
  const [tempcustomerassignment, settempcustomerassignment] = useState(null);

  const phoneNumber = useSelector((state) => state.user.phoneNumber);
  const [employees, setemployees] = useState([]);
  const [selectedemployee, setselectedemployee] = useState("");

  const adminphoneid = useSelector((state) => state.user.adminphoneid);
  const waba_id=useSelector((state) => state.user.wabaid);


  const fetched = useSelector((state) => state.user.userchat);
  // const sender_id = "238289119376434";
  const name = useSelector((state) => state.user.name);
  const selectedcustomer = useSelector((state) => state.user.selectedcustomer);
  const [templatemessage, settemplatemessage] = useState("");
  const [istemplatemessageactive, setistemplatemessageactive] = useState(false);
  const [isLink,setisLink]=useState(false);
  const [thumbnail,setthumbnail]=useState(false);
  const divRef = useRef(null);
  const isMobile=useMediaQuery('(max-width:412px)');

  const [chatsmobile,setchatsmobile]=useState(false);

  const emojis = ["😀","😃","😄", "😁","😆","😅","😂","🤣","😊","😇","🙂", "🙃","😉","😌","😍", "🥰",
    "😘","😗","😙","😚","😋","😛","😝","😜","🤪","🤨","🧐","🤓","😎","🤩","🥳","😏","😒","😞",
    "😔","😟","😕","🙁","☹️","😣","😖","😫","😩","😢","😭","😤","😠","😡","🤬","🤯","😳","🥵",
    "🥶", "😱", "😨", "😰", "😥", "😓", "🤗", "🤔", "🤭", "🤫", "🤥", "😶", "😐","😑",
    "😬", "🙄", "😯", "😦", "😧", "😮", "😲", "😴", "🤤","😪","😵","🤐","🥴","🤢",
    "🤮","🤧","😷","🤒","🤕","🤑","🤠","😈","👿","👹","👺","🤡","💩","👻","💀","☠️","👽",
    "👾", "🤖", "🎃", "😺", "😸", "😹", "😻", "😼", "😽", "🙀", "😿", "😾", "👋","🤚", "🖐",
    "✋", "🖖", "👌", "🤏", "✌️", "🤞", "🤟", "🤘", "🤙", "👈", "👉", "👆", "🖕","👇", "☝️",
    "👍", "👎", "✊", "👊", "🤛", "🤜", "👏", "🙌", "👐", "🤲", "🤝", "🙏", "✍️","💅", "🤳","💪",
    "🦾", "🦵", "🦿", "🦶", "👂", "🦻", "👃", "🧠", "🫀", "🫁", "🦷", "🦴", "👀", "👁",
    "👅", "👄", "💋", "🩸", "👶", "🧒", "👦", "👧", "🧑", "👱", "👨", "🧔", "👱‍♂️","👨‍🦰", "👨‍🦱",
    "👨‍🦳", "👨‍🦲", "👩", "👱‍♀️", "👩‍🦰", "👩‍🦱", "👩‍🦳", "👩‍🦲", "🧓", "👴", "👵", "🙍", "🙍‍♂️","🙍‍♀️",
    "🙎", "🙎‍♂️", "🙎‍♀️", "🙅", "🙅‍♂️", "🙅‍♀️", "🙆", "🙆‍♂️", "🙆‍♀️", "💁", "💁‍♂️", "💁‍♀️", "🙋", "🙋‍♂️", "🙋‍♀️", "🧏",
    "🧏‍♂️", "🧏‍♀️", "🙇", "🙇‍♂️", "🙇‍♀️", "🤦", "🤦‍♂️", "🤦‍♀️", "🤷", "🤷‍♂️", "🤷‍♀️", "🧑‍⚕️", "👨‍⚕️","👩‍⚕️", "🧑‍🎓",
    "👨‍🎓", "👩‍🎓", "🧑‍🏫", "👨‍🏫", "👩‍🏫", "🧑‍⚖️", "👨‍⚖️", "👩‍⚖️", "🧑‍🌾","👨‍🌾", "👩‍🌾", "🧑‍🍳", "👨‍🍳", "👩‍🍳",
    "🧑‍🔧","👨‍🔧","👩‍🔧","🧑‍🏭","👨‍🏭","👩‍🏭","🧑‍💼","👨‍💼","👩‍💼","🧑‍🔬","👨‍🔬","👩‍🔬","🧑‍💻","👨‍💻","👩‍💻","🧑‍🎤",
    "👨‍🎤", "👩‍🎤", "🧑‍🎨", "👨‍🎨", "👩‍🎨", "🧑‍✈️", "👨‍✈️", "👩‍✈️", "🧑‍🚀", "👨‍🚀", "👩‍🚀", "🧑‍🚒", "👨‍🚒","👩‍🚒", "👮", "👮‍♂️",
    "👮‍♀️", "🕵️", "🕵️‍♂️", "🕵️‍♀️", "💂", "💂‍♂️", "💂‍♀️", "👷", "👷‍♂️", "👷‍♀️", "🤴", "👸", "👳","👳‍♂️",
    "👳‍♀️",  "👲",  "🧕",  "🤵",  "👰",  "🤰",  "🤱",  "👼",  "🎅",  "🤶",  "🦸",  "🦸‍♂️",
    "🦸‍♀️","🦹",  "🦹‍♂️", "🦹‍♀️", "🧙", "🧙‍♂️", "🧙‍♀️", "🧚", "🧚‍♂️", "🧚‍♀️", "🧛", "🧛‍♂️", "🧛‍♀️",
    "🧜","🧜‍♂️", "🧜‍♀️", "🧝", "🧝‍♂️", "🧝‍♀️", "🧞", "🧞‍♂️", "🧞‍♀️", "🧟", "🧟‍♂️", "🧟‍♀️", "💆", "💆‍♂️", "💆‍♀️",
    "💇", "💇‍♂️", "💇‍♀️", "🚶","🚶‍♂️", "🚶‍♀️","🧍", "🧍‍♂️", "🧍‍♀️", "🧎", "🧎‍♂️", "🧎‍♀️", "👨‍🦯", "👩‍🦯",
    "👨‍🦼", "👩‍🦼", "👨‍🦽","👩", "🏃", "🏃‍♂️", "🏃‍♀️", "💃", "🕺", "🕴️", "👯", "👯‍♂️", "👯‍♀️","🧖",
    "🧖‍♂️",  "🧖‍♀️",  "🧘", "🧑‍🤝‍🧑",  "👭",  "👫",  "👬",  "💏",  "👩‍❤️‍💋‍👨",
    "👨‍❤️‍💋‍👨", "👩‍❤️‍💋‍👩", "💑", "👩‍❤️‍👨", "👨‍❤️‍👨","👩‍❤️‍👩","👪", "👨‍👩‍👦", "👨‍👩‍👧", "👨‍👩‍👧‍👦", "👨‍👩‍👦‍👦", "👨‍👩‍👧‍👧", "👨‍👨‍👦", "👨‍👨‍👧","👨‍👨‍👧‍👦",
    "👨‍👨‍👦‍👦", "👨‍👨‍👧‍👧", "👩‍👩‍👦", "👩‍👩‍👧", "👩‍👩‍👧‍👦", "👩‍👩‍👦‍👦","👩‍👩‍👧‍👧",  "👨‍👦",  "👨‍👦‍👦","👨‍👧", "👨‍👧‍👦", "👨‍👧‍👧", "👩‍👦", "👩‍👦‍👦",
    "👩‍👧",  "👩‍👧‍👦"  ,"👩‍👧‍👧"  ,"🗣️","👤",  "👥",  "👣",  "🧳",  "🌂",  "☂️",  "🎃",  "🧵","🧶", "👓",
    "🕶️","🥽","🥼","🦺","👔","👕","👖","🧣","🧤","🧥","🧦","👗","👘","🥻","🩱","🩲",
    "🩳", "👙", "👚","👛", "👜", "👝", "🛍️", "🎒", "👞", "👟", "🥾", "🥿", "👠", "👡",
    "🩰", "👢","👑", "👒", "🎩", "🎓", "🧢", "⛑️", "💄", "💍", "💼", "🩸", "🩹", "🩺",  "💉",
    "🩻",  "🚪",  "🛏️",  "🛋️",  "🪑",  "🚽",  "🚿",  "🛁",  "🪒",  "🧴",  "🧷",
    "🧹","🧺", "🧻","🧼", "🪠", "🪣", "🪤", "🪥", "🧽", "🧯", "🛒",
  ];
  const [emojiactive, setemojiactive] = useState(false);
  const dispatch = useDispatch();
  const [ismsgsend, setismsgsend] = useState(false);
  const [message, setmessage] = useState("");
  const [templates, settemplates] = useState([]);
  const [activetemplate, setactivetemplate] = useState("");
  const [activetemplatename, setactivetemplatename] = useState("");
  const [inputheadervars, setinputheadervars] = useState("");
  const [inputbodyvars, setinputbodyvars] = useState("");
  const [headervars, setheadervars] = useState([]);
  const [bodyvars, setbodyvars] = useState([]);
  const [arevariablessaved, setarevariablessaved] = useState(false);
  const [templatebuttons, settemplatebuttons] = useState([]);
  const [istemplatesending, setistemplatesending] = useState(false);
  const { selectedFile, openFileDialog, setSelectedFile, selectedImage,setSelectedImage } =
    Usecustomfileinput();
  const [more, setmore] = useState(false);
  const [adminmsg, setadminmsg] = useState(false);
  const [activetereply, setactivatereply] = useState(false);
  const firebaseauthid=localStorage.getItem('adminauthid')
   
  let newchats = [];

  const handlefilesent = async () => {
    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("sender_phone_number_id", adminphoneid);
    formdata.append("reciever_phone_number", phoneNumber);
    formdata.append("type", "doc");
    console.log(selectedFile);
    for (let pair of formdata.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    fetch("https://do.zopins.com/sendmedia", {
      method: "POST",
      body: formdata,
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    setSelectedFile(null);
  };


  const handlemessagesent = () => {
    setismsgsend(true);
    if (message) {
      setisLink(false);
      fetch("https://do.zopins.com/sendmessages", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sender_phone_number_id: adminphoneid,
          reciever_phone_number: phoneNumber,
          message: message,
          type: "text",
        }),
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      setmessage("");
      handlescrolltonewmessage();
    }
  };

  const handlescrolltonewmessage = () => {
    console.log("ref", messagescontainerref);
    if (messagescontainerref.current) {
      setTimeout(() => {
        messagescontainerref.current.scrollTop =
          messagescontainerref.current?.scrollHeight;
      }, 530);
      // messagescontainerref.lastElementChild.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const savemessagetouser = (data) => {
    newchats.push(data);
    console.log(data);
    setlatestchats([...newchats]);
    handlescrolltonewmessage();
  };

  const savesentmessagetouser = (data) => {
    newchats.push(data);
    console.log(data);
    setlatestchats([...newchats]);
    handlescrolltonewmessage();
  };

  const handlekeydown = async (e) => {
    if (e.key === "Enter" && message) {
      setisLink(false)
      fetch("https://do.zopins.com/sendmessages", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          sender_phone_number_id: adminphoneid,
          reciever_phone_number: phoneNumber,
          message: message,
          type: "text",
        }),
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log("message sent");

          }
        })
        .catch((error) => {
          console.log(error);
        });
      setmessage("");
      setSelectedFile(null);
    }
  };

  const downloadFile = async (doc_name, doc_id, doc_type) => {
    const new_doc_type = doc_type.split("/")[1];
    console.log(new_doc_type);
    console.log(doc_name);
    console.log(doc_id);
    
    const url = `https://do.zopins.com/documents/${doc_id}.${new_doc_type}`;
    window.open(url, '_blank');
 
  };
  const downloadsentFile = async (doc_name, doc_id, doc_type) => {
    const new_doc_type = doc_type.split("/")[1];
    console.log(new_doc_type);
    console.log(doc_name);
    console.log(doc_id);

    const url = `https://do.zopins.com/uploadeddocs/${doc_id}.${new_doc_type}`;
    window.open(url, '_blank');
   
  };
  
  useEffect(() => {
    if(localStorage.getItem('isadminAuthenticated')!=='true'){
      return navigate("/login");
    }
    setactivephoneNumber(phoneNumber);
    socket.emit('join', {authid:adminphoneid});
    socket.on("received_message", (data) => {
      savemessagetouser(data.recieved_message);
    });
  
    socket.on("sent_message", (data) => {
      console.log("sent and recieved msg", data.sent_message);

      savesentmessagetouser(data.sent_message);
    });
    console.log(phoneNumber);

    if (messagescontainerref.current) {
      messagescontainerref.current.scrollTop =
        messagescontainerref.current?.scrollHeight;
    }

    async function fetchemployees() {
      const response = await fetch(
        `https://do.zopins.com/getemployees/${firebaseauthid}`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-origin": "*",
          },
        }
      );
      const data = await response.json();
      setemployees(data.employees);
    }
    if (localStorage.getItem('isadminAuthenticated')==="true")
    fetchemployees();

    if (phoneNumber !== "") findcustomerassignment();
  }, [phoneNumber, socket]);

  const findcustomerassignment = () => {
    let found = false;
    employees.forEach((emp, index) => {
      emp.assigned_clients.forEach((client, idx) => {
        if (phoneNumber === client.sender_phone_number) {
          console.log("assigned employee", emp);
          setcustomerassignedto(emp);
          found = true;
        }
      });
    });
    if (found === false) {
      setcustomerassignedto(null);
    }
  };

  const handleImageClick = (image) => {
    console.log(image);
    setSelectedImg(image);
    console.log(selectedImg);
  };

  const handleCloseModal = () => {
    setSelectedImg(null);
  };

  const fetchtemplates = async () => {
    const response = await fetch(
      `https://do.zopins.com/myalltemplates/${waba_id}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-origin": "*",
        },
      }
    );
    const data = await response.json();
    settemplates(data.data);
    console.log("templates", data.data);
  };

  const fetchtemplatedata = async (tempid) => {
    setinputbodyvars("");
    setinputheadervars("");
    setarevariablessaved(false);
    try {
      const response = await fetch(
        `https://do.zopins.com/gettemplate/${tempid}`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-origin": "*",
          },
        }
      );
      const data = await response.json();
      setactivetemplate(data);
      activetemplate &&
        activetemplate.components.forEach((element) => {
          if (element.type === "BUTTONS") {
            settemplatebuttons(element.buttons);
          }
        });

      console.log("active", activetemplate);
      console.log("response", data);
    } catch (error) {
      console.log(error);
    }
  };

  const sendtemplate = () => {
    fetch("https://do.zopins.com/sendtemplate", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sender_phone_number_id: adminphoneid,
        reciever_phone_number: phoneNumber,
        templatename: activetemplatename,
        headervars,
        bodyvars,
        templatebuttons,
      }),
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    setistemplatesending(!istemplatesending);
  };
  const handleInputChange = () => {
    const result_head_vars = inputheadervars
      .split(",")
      .map((text) => text.trim());
    const result_body_vars = inputbodyvars
      .split(",")
      .map((text) => text.trim());
    setbodyvars(result_body_vars);
    setheadervars(result_head_vars);
    setarevariablessaved(true);

    setTimeout(() => {
      setarevariablessaved(false);
    }, 3000);
    console.log(bodyvars);
    console.log(headervars);
  };
  const formatDate = (timestamp) => {
    const dateTime = new Date(timestamp * 1000);
    return dateTime.toLocaleDateString(); // You can customize the format as needed
  };
  const formattime = (timestamp) => {
    const dateTime = new Date(timestamp * 1000);
    return dateTime.toLocaleTimeString(); // You can customize the format as needed
  };
  const getdate = (timestamp) => {
    const date = new Date(timestamp);

    return date.toLocaleDateString();
  };
  const gettime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  };

  const handlecustomerassignment = async (customer, employee) => {
    // console.log('e',employee);
    setcustomerassignedto(employee);
    //  console.log('c a t',customerassignedto);
    fetch("https://do.zopins.com/customerassign", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        authid: firebaseauthid,
        customer: customer,
        employee: employee,
      }),
    })
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const inputRef = useRef(null);

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    if (clipboardData && clipboardData.files && clipboardData.files.length > 0) {
      const file = clipboardData.files[0];
      setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);
    
    }  
  };
  return (
    <>
      {selectedImg && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-img" onClick={handleCloseModal}>
              X
            </span>
            <img src={selectedImg} alt="Selected Imag" className="full-image" />
          </div>
        </div>
      )}

      {istemplatesending && (
        <div className="popup-overlay"
        style={isMobile?{zIndex:'2000000'}:{}}
        >
          <div className="popup templatepopup">
            <button
              className="close-button templateclose"
              style={isMobile?{height:'3vw',width:'3vw',borderRadius:'2vw',fontWeight:'bolder',fontSize:'2vw',display:'flex',textAlign:'center',alignItems:'center',justifyContent:'center'}:{}}
              onClick={() => {
                setistemplatesending(!istemplatesending);
              }}
            >
             close
            </button>
            <div className="availablehead">AVAILABLE TEMPLATES</div>
            <div className="templateselectionbox">
              <div className="availabletemplatesbox">
                <div className="availabletemplates">
                  {templates &&
                    templates.map((temp, index) => {
                      return (
                        <>
                          <div key={index}>
                            <div
                              className="templatearch"
                              onClickCapture={() => {
                                fetchtemplatedata(temp.id);
                              }}
                              onClick={() => {
                                fetchtemplatedata(temp.id);
                                setactivetemplatename(temp.name);
                              }}
                              style={
                                activetemplatename === temp.name
                                  ? {
                                      color: "white",
                                      backgroundColor: "#16AEE8",
                                    }
                                  : {}
                              }
                            >
                              {temp.name.toUpperCase()}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              {headervars[0] && bodyvars[0] ? (
                <>
                  <button className="sendtemplatebtn" onClick={sendtemplate}>
                    Send
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="sendtemplatebtn"
                    onClick={sendtemplate}
                    disabled
                  >
                    Send
                  </button>
                </>
              )}
              <div className="sendingtemplates">
                <div className="selectedtemplate">
                  {activetemplate && (
                    <>
                      <div className="sendtempinfo">
                        Please enter your keywords in place of variables such as
                        john in place of {"{{1}}"}.
                      </div>
                      <div className="previewbox">
                        {activetemplate &&
                          activetemplate.components.map((component, index) => {
                            return (
                              <>
                                <div key={index}>
                                  {component.type === "HEADER" ? (
                                    <>
                                      <div className="tempheader">
                                        {component.text}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {component.type === "BODY" ? (
                                    <>
                                      <div className="tempmessage">
                                        {component.text}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            );
                          })}
                      </div>

                      {activetemplate &&
                        activetemplate.components.map((component, index) => {
                          return (
                            <>
                              {component.type === "BUTTONS" ? (
                                <>
                                  {component.buttons.map((button, index) => {
                                    return (
                                      <>
                                        <div
                                          className="tempbutton "
                                          key={index}
                                        >
                                          {button.text}
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                    </>
                  )}
                </div>
                {activetemplatename && ( 
                  <>
                    <div className="selectedtemplatevariables">
                      <div className="tempheadervars">
                        <div className="hvarinfo">
                          Please enter the variables in the format as{" "}
                          {"{{1}} , {{2}} , . . . ."}
                        </div>
                        <div>Header</div>
                        <div>
                          <input
                            type="text"
                            placeholder="Variables if used"
                            value={inputheadervars}
                            onChange={(ev) =>
                              setinputheadervars(ev.target.value)
                            }
                            onInputCapture={(ev) =>
                              setinputheadervars(ev.target.value)
                            }
                            className="headervarsinput"
                          />
                        </div>
                      </div>
                      <div className="tempbodyvars">
                        <div>Body</div>
                        <div>
                          <textarea
                            type="text"
                            placeholder="Variables if used"
                            className="bodyvarsinputs"
                            value={inputbodyvars}
                            onChange={(ev) => setinputbodyvars(ev.target.value)}
                            onInputCapture={(ev) =>
                              setinputbodyvars(ev.target.value)
                            }
                          />
                        </div>
                      </div>
                      {inputheadervars && inputbodyvars ? (
                        <>
                          <button
                            className="variablesavebtn"
                            onClick={handleInputChange}
                          >
                            {arevariablessaved ? (
                              <>
                                <div className="loader-container">
                                  <div className="loader"></div>
                                </div>
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </button>
                        </>
                      ) : (
                        <button className="variablesavebtn" disabled>
                          Save
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedFile && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-btn" onClick={() => setSelectedFile(null)}>
              Close
            </button>
            <div>SELECTED DOCUMENT</div>
            <div>
              {selectedFile.type.split("/")[0] === "application" ? (
                <>
                  <embed
                    src={selectedImage}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                    className="imagetobesent"
                  />
                </>
              ) : selectedFile.type.startsWith("video/") ? (
                <>
                  <video controls className="imagetobesent">
                    <source src={selectedImage} type={selectedFile.type} />
                    Your browser does not support the video tag.
                  </video>
                </>
              ) : (
                <>
                  <img
                    src={selectedImage}
                    alt="img not found"
                    className="imagetobesent"
                  />
                </>
              )}
            </div>
            <div className="docsend" onClick={handlefilesent}>
              Send
            </div>
          </div>
        </div>
      )}
      {
        adminphoneid?
      <AnimatedContainer>
        <div className="box"
        style={chatsmobile?{position:'fixed',zIndex:'2000000'}:{}}
        >
          {
            !isMobile && <Adminsidebar setlatestchats={setlatestchats} />
          }
          
          {phoneNumber === "" ? (
            <>
              <div className="chatsentrycontainer">
                <div className="chatscontainerhead">Chats</div>
                <div className="chatscontainerinfo">
                  Please select a contact to start chatting.
                </div>
              </div>
            </>
          ) : (
            <>
              <div></div>
              <div className="chatstopbar">
                {isMobile && 
                   <img width="48" height="48" src="https://img.icons8.com/fluency-systems-regular/48/FFFFFF/long-arrow-left.png" alt="long-arrow-left"
                    className='backarrow'
                    onClick={()=>{navigate('/admin/chats')}}
                   />
                }
                <div className="profileicon">
                  {name.substr(0, 1).toUpperCase()}
                </div>
                <div className="profilename">
                  {name.toUpperCase()}&nbsp;
                  {customerassignedto && (
                    <span className="customerassignmentinfo">
                      Assigned to {customerassignedto.name}
                    </span>
                  )}
                </div>
                <div className="profilephone">+{phoneNumber}</div>
                <div className="phonecontainer">
                  <span title="Messages from admin for this chat.">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="100"
                      height="100"
                      viewBox="0 0 50 50"
                      style={{ fill: "#074158" }}
                      className="adminmsg"
                      onClick={() => {
                        setadminmsg(true);
                        setmore(false);
                        setactivatereply(false);
                      }}
                    >
                      <path d="M 25 4 C 12.316406 4 2 12.972656 2 24 C 2 30.1875 5.335938 36.066406 10.949219 39.839844 C 10.816406 40.890625 10.285156 43.441406 8.183594 46.425781 L 7.078125 47.992188 L 9.054688 48 C 14.484375 48 18.15625 44.671875 19.363281 43.394531 C 21.195313 43.796875 23.089844 44 25 44 C 37.683594 44 48 35.027344 48 24 C 48 12.972656 37.683594 4 25 4 Z"></path>
                    </svg>
                  </span>
                  <img
                    width="50"
                    height="50"
                    src="https://img.icons8.com/ios-filled/50/06384e/ringer-volume.png"
                    alt="ringer-volume"
                    className="phoneicon"
                    title="Call"
                  />

                  <img
                    width="50"
                    height="50"
                    src="https://img.icons8.com/ios-filled/50/06384e/menu-2.png"
                    alt="menu-2"
                    className="threedots"
                    title="Add chat notes"
                    onClick={() => {
                      setmore(true);
                      setadminmsg(false);
                    }}
                  />
                </div>
              </div>

              <div className="chatsarea" 
               style={chatsmobile?{ height: '80vh'}:{}}
              ref={messagescontainerref}>
                {fetched &&
                  fetched.map((chat, index) => {
                    return (
                      <>
                        {chat.recieved ? (
                          <>
                            <div className="recieved " key={index}>
                              {chat.recieved.type === "image" ? (
                                <>
                                  <div className="recievedimagescontainer">
                                    <img
                                      src={`https://do.zopins.com/images/${
                                        chat.recieved.image.id
                                      }.${
                                        chat.recieved.image.mime_type.split(
                                          "/"
                                        )[1]
                                      }`}
                                      alt="not found"
                                      className="recievedimages"
                                      onClick={() =>
                                        handleImageClick(
                                          `https://do.zopins.com/images/${
                                            chat.recieved.image.id
                                          }.${
                                            chat.recieved.image.mime_type.split(
                                              "/"
                                            )[1]
                                          }`
                                        )
                                      }
                                      key={index}
                                    />
                                    <div className="timestamp">
                                      {formatDate(chat.recieved.timestamp)}
                                      <div>
                                        &nbsp;|&nbsp;
                                        {formattime(chat.recieved.timestamp)}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : chat.recieved.type === "document" ? (
                                <>
                                  <div className="maindoccontainer">
                                    <div
                                      className="doccontainer"
                                      key={index}
                                      onClick={() =>
                                        downloadFile(
                                          chat.recieved.document.filename,
                                          chat.recieved.document.id,
                                          chat.recieved.document.mime_type
                                        )
                                      }
                                    >
                                      <div className="doc">
                                        <div className="dochead">
                                          {chat.recieved.document.mime_type
                                            .split("/")[1]
                                            .toUpperCase()}
                                        </div>
                                        {chat.recieved.document.filename}
                                      </div>
                                      <img
                                        src="https://img.icons8.com/external-sbts2018-solid-sbts2018/58/external-install-basic-ui-elements-2.3-sbts2018-solid-sbts2018.png"
                                        alt="external-install-basic-ui-elements-2.3-sbts2018-solid-sbts2018"
                                        className="downloadrecieveddoc"
                                      />
                                    </div>
                                    <div className="timestamp">
                                      {
                                        formatDate(chat.recieved.timestamp)
                                        // getdate(chat.timestamp*1000)
                                      }
                                      <div>
                                        &nbsp;|&nbsp;
                                        {
                                          formattime(chat.recieved.timestamp)
                                          //  gettime(chat.timestamp*1000)
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : chat.recieved.type === "video" ? (
                                <>
                                  <div
                                    className="videocontainer recievedimagescontainer"
                                    key={index}
                                  >
                                    <video
                                      controls
                                      className="video"
                                      width="100%"
                                    >
                                      <source
                                        src={`https://do.zopins.com/videos/${
                                          chat.recieved.video.id
                                        }.${
                                          chat.recieved.video.mime_type.split(
                                            "/"
                                          )[1]
                                        }`}
                                        type={`video/${
                                          chat.recieved.video.mime_type.split(
                                            "/"
                                          )[1]
                                        }`}
                                      />
                                    </video>
                                    <div className="timestamp">
                                      {
                                        formatDate(chat.recieved.timestamp)
                                        // getdate(chat.timestamp*1000)
                                      }
                                      <div>
                                        &nbsp;|&nbsp;
                                        {
                                          formattime(chat.recieved.timestamp)
                                          //  gettime(chat.timestamp*1000)
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="recievedtext" key={index}
                                   style={chat.recieved.text.body.includes('https://')?{backgroundColor:'#b1d1de'}:{}}
                                  >
                                    {chat.recieved.text.body.includes('https://')?
                                    <a href="chat.recieved.text.body" target="_blank" rel='noreferrer'>{chat.recieved.text.body}</a>
                                    :
                                    <span>
                                      {chat.recieved.text.body}
                                    </span>
                                    }
                                    <div className="timestamp timestampforrecieved">
                                      {
                                        formatDate(chat.recieved.timestamp)
                                        // getdate(chat.timestamp*1000)
                                      }
                                      <div>
                                        &nbsp;|&nbsp;
                                        {
                                          formattime(chat.recieved.timestamp)
                                          //  gettime(chat.timestamp*1000)
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="sent" key={index}>
                              {chat.sent.type.split("/")[0] === "image" ? (
                                <>
                                  <div className="sentimagecontainer">
                                    <img
                                      src={`https://do.zopins.com/uploadeddocs/${
                                        chat.sent.id
                                      }.${chat.sent.type.split("/")[1]}`}
                                      alt="not found"
                                      className="sentimages"
                                      ref={imageRef}
                                      onClick={()=>handleImageClick(`https://do.zopins.com/uploadeddocs/${
                                        chat.sent.id
                                      }.${chat.sent.type.split("/")[1]}`)}
                                    />
                                    <div className="timestamp timestampimg">
                                      {getdate(chat.timestamp)}
                                      <div>
                                        &nbsp;|&nbsp;
                                        {gettime(chat.timestamp)}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : chat.sent.type.split("/")[0] === "video" ? (
                                <>
                                  <div
                                    className="sentvideocontainer"
                                    key={index}
                                  >
                                    <video controls className="sentvideo">
                                      <source
                                        src={`https://do.zopins.com/uploadeddocs/${
                                          chat.sent.id
                                        }.${chat.sent.type.split("/")[1]}`}
                                        type={`video/${
                                          chat.sent.type.split("/")[1]
                                        }`}
                                      />
                                    </video>
                                    <div className="timestamp">
                                      {getdate(chat.timestamp)}
                                      <div>
                                        &nbsp;|&nbsp;
                                        {gettime(chat.timestamp)}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : chat.sent.type === "template" ? (
                                <>
                                  <div
                                    className="senttext senttemplatebox"
                                    key={index}
                                  >
                                    <div className="">
                                      <div className="senttemplateinfo">
                                        Sent template
                                        <div>
                                          Template name -{" "}
                                          {chat.sent.message.template.name}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="timestamp">
                                      {getdate(chat.timestamp)}
                                      <div>
                                        &nbsp;|&nbsp;
                                        {gettime(chat.timestamp)}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : chat.sent.type.split("/")[0] === "application" ? (
                                <>
                                  <div className="mainsentdoccontainer">
                                    <div
                                      className="doccontainer"
                                      key={index}
                                      onClick={() =>
                                        downloadsentFile(
                                          chat.sent.id,
                                          chat.sent.id,
                                          chat.sent.type
                                        )
                                      }
                                    >
                                      <div className="doc">
                                        <div className="dochead">
                                          {chat.sent.type.split("/")[1]
                                            .toUpperCase()}
                                        </div>
                                        <span className="sentdocname">
                                        {(chat.sent.id).substr(0,30)}
                                        </span>
                                      </div>
                                      <img
                                        src="https://img.icons8.com/external-sbts2018-solid-sbts2018/58/external-install-basic-ui-elements-2.3-sbts2018-solid-sbts2018.png"
                                        alt="external-install-basic-ui-elements-2.3-sbts2018-solid-sbts2018"
                                        className="downloadrecieveddoc"
                                      />
                                    </div>
                                    <div className="timestamp">
                                      {getdate(chat.timestamp)}
                                      <div>
                                        &nbsp;|&nbsp;
                                        {gettime(chat.timestamp)}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ):
                              (
                                <>
                                  <div className="senttext senttextauto" key={index}
                                  style={(chat.sent.message).includes('https://')?{backgroundColor:'#cdf1ff'}:{}}
                                  >
                                  {(chat.sent.message).includes('https://')?
                                        <a href={chat.sent.message} target="_blank" rel='noreferrer'>{chat.sent.message}</a>
                                        :
                                        <span>
                                        {chat.sent.message}
                                        </span>
                                        }
                                    <div className="timestamp">
                                      {getdate(chat.timestamp)}
                                      <div>
                                        &nbsp;|&nbsp;
                                        {gettime(chat.timestamp)}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}
                {/* latest chats preview */}
                {latestchats &&
                  latestchats.map((chat, index) => {
                    return (
                      <>
                        {/* {chat.recieved.from===activephoneNumber && <> */}
                        {chat.recieved ? (
                          <>
                            {chat.recieved.from === activephoneNumber && (
                              <>
                                <div className="recieved" key={index}>
                                  {chat.recieved.type === "image" ? (
                                    <>
                                      <div className="recievedimagescontainer">
                                        <img
                                          src={`https://do.zopins.com/images/${
                                            chat.recieved.image.id
                                          }.${
                                            chat.recieved.image.mime_type.split(
                                              "/"
                                            )[1]
                                          }`}
                                          alt="not found"
                                          className="recievedimages"
                                          onClick={() =>
                                            handleImageClick(
                                              `https://do.zopins.com/images/${
                                                chat.recieved.image.id
                                              }.${
                                                chat.recieved.image.mime_type.split(
                                                  "/"
                                                )[1]
                                              }`
                                            )
                                          }
                                          key={index}
                                        />
                                        <div className="timestamp">
                                          {formatDate(chat.recieved.timestamp)}
                                          <div>
                                            &nbsp;|&nbsp;
                                            {formattime(
                                              chat.recieved.timestamp
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : chat.recieved.type === "document" ? (
                                    <>
                                      <div className="maindoccontainer">
                                        <div
                                          className="doccontainer"
                                          key={index}
                                          onClick={() =>
                                            downloadFile(
                                              chat.recieved.document.filename,
                                              chat.recieved.document.id,
                                              chat.recieved.document.mime_type
                                            )
                                          }
                                        >
                                          <div className="doc">
                                            <div className="dochead">
                                              {chat.recieved.document.mime_type
                                                .split("/")[1]
                                                .toUpperCase()}
                                            </div>
                                            {chat.recieved.document.filename}
                                          </div>
                                          <img
                                            src="https://img.icons8.com/external-sbts2018-solid-sbts2018/58/external-install-basic-ui-elements-2.3-sbts2018-solid-sbts2018.png"
                                            alt="external-install-basic-ui-elements-2.3-sbts2018-solid-sbts2018"
                                            className="downloadrecieveddoc"
                                          />
                                        </div>
                                        <div className="timestamp">
                                          {
                                            formatDate(chat.recieved.timestamp)
                                            // getdate(chat.timestamp*1000)
                                          }
                                          <div>
                                            &nbsp;|&nbsp;
                                            {
                                              formattime(
                                                chat.recieved.timestamp
                                              )
                                              //  gettime(chat.timestamp*1000)
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : chat.recieved.type === "video" ? (
                                    <>
                                      <div
                                        className="videocontainer recievedimagescontainer"
                                        key={index}
                                      >
                                        <video
                                          controls
                                          className="video"
                                          width="100%"
                                        >
                                          <source
                                            src={`https://do.zopins.com/videos/${
                                              chat.recieved.video.id
                                            }.${
                                              chat.recieved.video.mime_type.split(
                                                "/"
                                              )[1]
                                            }`}
                                            type={`video/${
                                              chat.recieved.video.mime_type.split(
                                                "/"
                                              )[1]
                                            }`}
                                          />
                                        </video>
                                        <div className="timestamp">
                                          {
                                            formatDate(chat.recieved.timestamp)
                                            // getdate(chat.timestamp*1000)
                                          }
                                          <div>
                                            &nbsp;|&nbsp;
                                            {
                                              formattime(
                                                chat.recieved.timestamp
                                              )
                                              //  gettime(chat.timestamp*1000)
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="recievedtext" key={index}>
                                      {chat.recieved.text.body.includes('https://')?
                                    <a href="chat.recieved.text.body" target="_blank" rel='noreferrer'>{chat.recieved.text.body}</a>
                                    :
                                    <span>
                                      {chat.recieved.text.body}
                                    </span>
                                    }
                                        <div className="timestamp">
                                          {
                                            formatDate(chat.recieved.timestamp)
                                            // getdate(chat.timestamp*1000)
                                          }
                                          <div>
                                            &nbsp;|&nbsp;
                                            {
                                              formattime(
                                                chat.recieved.timestamp
                                              )
                                              //  gettime(chat.timestamp*1000)
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="sent" key={index}>
                              {chat.reciever === activephoneNumber && (
                                <>
                                  {chat.sent.type.split("/")[0] === "image" ? (
                                    <>
                                 <div className="sentimagecontainer">
                                    <img
                                      src={`https://do.zopins.com/uploadeddocs/${
                                        chat.sent.id
                                      }.${chat.sent.type.split("/")[1]}`}
                                      alt="not found"
                                      className="sentimages"
                                      ref={imageRef}
                                      onClick={()=>handleImageClick(`https://do.zopins.com/uploadeddocs/${
                                        chat.sent.id
                                      }.${chat.sent.type.split("/")[1]}`)}
                                    />
                                    <div className="timestamp timestampimg">
                                      {getdate(chat.timestamp)}
                                      <div>
                                        &nbsp;|&nbsp;
                                        {gettime(chat.timestamp)}
                                      </div>
                                    </div>
                                  </div>
                                    </>
                                    
                                  ) : chat.sent.type.split("/")[0] ===
                                    "video" ? (
                                    <>
                                      <div className="sentvideocontainer">
                                        <video controls className="sentvideo">
                                          <source
                                            src={`https://do.zopins.com/uploadeddocs/${
                                              chat.sent.id
                                            }.${chat.sent.type.split("/")[1]}`}
                                            type={`video/${
                                              chat.sent.type.split("/")[1]
                                            }`}
                                          />
                                        </video>
                                      </div>
                                    </>
                                  ) : chat.sent.type === "template" ? (
                                    <>
                                      <div
                                        className="senttext senttemplatebox"
                                        key={index}
                                      >
                                        <div className="">
                                          <div className="senttemplateinfo">
                                            Sent template
                                            <div>
                                              Template name -{" "}
                                              {chat.sent.message.template.name}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="timestamp">
                                          {getdate(chat.timestamp)}
                                          <div>
                                            &nbsp;|&nbsp;
                                            {gettime(chat.timestamp)}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) 
                                  : chat.sent.type.split("/")[0] === "application" ? (
                                    <>
                                      <div className="mainsentdoccontainer">
                                        <div
                                          className="doccontainer"
                                          key={index}
                                          onClick={() =>
                                            downloadsentFile(
                                              chat.sent.id,
                                              chat.sent.id,
                                              chat.sent.type
                                            )
                                          }
                                        >
                                          <div className="doc">
                                            <div className="dochead">
                                              {chat.sent.type.split("/")[1]
                                                .toUpperCase()}
                                            </div>
                                            <span className="sentdocname">
                                            {(chat.sent.id).substr(0,30)}
                                            </span>
                                          </div>
                                          <img
                                            src="https://img.icons8.com/external-sbts2018-solid-sbts2018/58/external-install-basic-ui-elements-2.3-sbts2018-solid-sbts2018.png"
                                            alt="external-install-basic-ui-elements-2.3-sbts2018-solid-sbts2018"
                                            className="downloadrecieveddoc"
                                          />
                                        </div>
                                        <div className="timestamp">
                                          {getdate(chat.timestamp)}
                                          <div>
                                            &nbsp;|&nbsp;
                                            {gettime(chat.timestamp)}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                  : 
                                  
                                  (
                                    <>
                                      <div className="senttext" key={index}
                                      >
                                        {(chat.sent.message).includes('https://')?
                                        <a href={chat.sent.message} target="_blank" rel='noreferrer'>{chat.sent.message}</a>
                                        :
                                        <span>
                                        {chat.sent.message}
                                        </span>
                                        }
                                        <div className="timestamp">
                                          {getdate(chat.timestamp)}
                                          <div>
                                            &nbsp;|&nbsp;
                                            {gettime(chat.timestamp)}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                        {/* </>} */}
                      </>
                    );
                  })}

                {istemplatemessageactive && (
                  <>
                    <div className=" dummymessage ">
                      {templatemessage}
                      <div className="timestamp">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style={{ fill: "#FFFFFF" }}
                          className="singletick"
                        >
                          <path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"></path>
                        </svg>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {more && (
                <>
                  <div className="morebox">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/ios-glyphs/30/074158/multiply.png"
                      alt="multiply"
                      className="crossicon"
                      onClick={() => setmore(false)}
                    />
                    <div className="morehead">Assign Client</div>
                    <div className="employeecontainer">
                      {employees &&
                        employees.map((employee, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="pemployee"
                                //  onClickCapture={()=>{setselectedemployee(employee.name)}}
                                onClick={() => {
                                  setselectedemployee(employee.name);
                                }}
                                style={
                                  selectedemployee === employee.name
                                    ? {
                                        backgroundColor: "#052b3a",
                                        color: "white",
                                      }
                                    : {}
                                }
                              >
                                {employee.name.toUpperCase()}&nbsp; (+
                                {employee.phone_number})
                              </div>
                              {selectedemployee === employee.name && (
                                <>
                                  <div
                                    className="selectemployeebtn"
                                    onClick={() => {
                                      handlecustomerassignment(
                                        selectedcustomer,
                                        employee
                                      );
                                      setselectedemployee(null);
                                      setmore(!more);
                                    }}
                                  >
                                    Assign client to {selectedemployee}
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}
              {emojiactive && (
                <>
                  <div className="emojiscontainer">
                    {emojis.map((emoji, index) => {
                      return (
                        <>
                          <span
                            key={index}
                            className="emoji"
                            //  onClick={()=>setmessage(message+emoji)}
                            onClickCapture={() => {
                              if (message === "") {
                                setmessage(emoji);
                              } else {
                                setmessage(message + emoji);
                              }
                            }}
                          >
                            {emoji}
                          </span>
                        </>
                      );
                    })}
                  </div>
                </>
              )}

                {/* <LinkPreview url='https://www.youtube.com/watch?v=dQw4w9WgXcQ' width='400px'
                    // className="thumbnail"
                    style={{display:'block'}}
                    />;
                   */}
                
              <div className="messagearea"
               style={chatsmobile?{backgroundColor:'transparent'}:{}}
              >
                <div className="file-element">
                  <img
                    width="48"
                    height="48"
                    src="https://img.icons8.com/pulsar-line/48/053548/prototype.png"
                    alt="prototype"
                    className="emojiicon"
                    onClick={() => {
                      setistemplatesending(!istemplatesending);
                      fetchtemplates();
                    }}
                  />
                  <img
                    width="60"
                    height="60"
                    src="https://img.icons8.com/external-vitaliy-gorbachev-fill-vitaly-gorbachev/60/06384e/external-file-home-office-vitaliy-gorbachev-fill-vitaly-gorbachev.png"
                    alt="external-file-home-office-vitaliy-gorbachev-fill-vitaly-gorbachev"
                    className="fileicon"
                    onClick={openFileDialog}
                  />

                  <img
                    width="50"
                    height="50"
                    src="https://img.icons8.com/ios-filled/50/06384e/happy--v1.png"
                    alt="happy--v1"
                    className="emojiicon2"
                    onClick={() => {
                      setemojiactive(!emojiactive);
                    }}
                  />
                </div>
                <input
                  type="text"
                  className="message-input"
                  placeholder="Your message here..."
                  value={message}
                  style={isLink?{color:'blue'}:{}}
                  ref={inputRef}
                  onPaste={handlePaste}
                  onClick={()=>{
                    if(isMobile){
                      setchatsmobile(true);
                    }
                  }}
                  onChange={(ev) =>{ setmessage(ev.target.value);
                    const urlRegex = /(https?:\/\/[^\s]+)/g;
                    const containsLink = urlRegex.test(message);
                    setisLink(containsLink);
                  }}
                  onInputCapture={(ev) =>{ setmessage(ev.target.value);
                    const urlRegex = /(https?:\/\/[^\s]+)/g;
                    const containsLink = urlRegex.test(message);
                    setisLink(containsLink);
                  }}
                  onKeyDown={handlekeydown}
                />
                <button className="send-button" onClick={handlemessagesent}>
                  <img
                    width="64"
                    height="64"
                    src="https://img.icons8.com/ios-filled/50/FFFFFF/sent.png"
                    alt="sent"
                    className="sendicon"
                  />
                </button>
              </div>
            </>
          )}
        </div>
      </AnimatedContainer>
      :<>
      <div className="chatsentrycontainer"
      style={!isMobile?{marginLeft:'30%'}:{}}
      >
                <div className="chatscontainerhead">Chats</div>
                <div className="chatscontainerinfo">
                 Please complete whatsapp embedded signup in your dashboard to access this feature.
                </div>
              </div>
      </>}
    </>
  );
};

export default Crmpage;
