import React, { useEffect } from 'react';

import { useState } from 'react';

import {useDispatch} from 'react-redux';
import {setphoneid,setwabaid} from'../Reduxstore/store.js';
import './facebooklogin.css'
import { auth } from '../Firebase/firebase.js';

function FacebookLogin() {
    const [loginData, setLoginData] = useState(null);
    const dispatch=useDispatch();

    const updateapp=async()=>{
        fetch(`https://do.zopins.com/createchattingarea`,{
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin':'*',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
               authid:auth.currentUser.uid,
               name:'',
               phoneNumber:'',
               phoneNumberId:'',
               wabaid:''
            }),
          })
          .then(response => { 
              console.log(response);
          })
          .catch(error=>{
              console.log(error);
          })
    
    }

  useEffect(() => {  
    window.fbAsyncInit = function () {
      // Facebook SDK initialization
      window.FB.init({
        appId: '1737444063400013', // Facebook App ID
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v19.0' // Graph API version
      });
    };
    // Load the JavaScript SDK asynchronously
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  // Facebook Login with JavaScript SDK
  const launchWhatsAppSignup = () => {
  
    if (window.fbq) {
      window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: '1737444063400013', feature: 'whatsapp_embedded_signup' });
    }
    // Launch Facebook login

    window.FB.login(function (response) {
        console.log(response);
      if (response.authResponse) {
        console.log(response.authResponse);
        console.log(response.data);
        const code = response.authResponse.code;
        setLoginData({ code });
        console.log(code);
        console.log(loginData);
        dispatch(setphoneid(code.data.phone_number_id));
        dispatch(setwabaid(code.data.waba_id));

        // The returned code must be transmitted to your backend, 
        // which will perform a server-to-server call from there to our servers for an access token
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      config_id: '275190725637175', 
      response_type: 'code',    // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        setup: {
        }
      }
    });
  };

  return <>
  <div className='mainlogincontainer'>
  <div className='logincontainer'>
<div className='loginhead'>Admin Login</div>
  <button onClick={launchWhatsAppSignup} className='loginbtn'>
  <img width="48" height="48" src="https://img.icons8.com/fluency/48/facebook-new.png" alt="facebook-new" className='facebookicon'/>
     <span className='logintextinfo'>
     Login With Facebook
        </span>
        </button>
  </div>
  <div className='loginstyle'>
    <img src="https://i.postimg.cc/zGG2PTZh/newloginpic-removebg.png" alt="login img" className='loginimage' />
  </div>

      <div className='stylebubble b1'></div>
      <div className='stylebubble b2'></div>
      <div className='stylebubble b3'></div>
  </div>
  </>
    
}

export default FacebookLogin;
