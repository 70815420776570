import './App.css';

import 'rsuite/dist/rsuite.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Crmpage from './Components/Userpanel/Crmpage/Crmpage';
import {Provider} from 'react-redux';
import {store,persistor} from './Components/Reduxstore/store';
import NewSidebar from './Components/Userpanel/Sidebar/NewSidebar';
import Actionbar from './Components/Userpanel/actionbar/Actionbar';
import Dashboard from './Components/Userpanel/Dashboard/Dashboard';
import Notes from './Components/Userpanel/Notes/Notes';
import Settings from './Components/Userpanel/Settings/Settings';
import Adminsidebar from './Components/Adminpanel/Adminsidebar/Adminsidebar';
import Adminpage from './Components/Adminpanel/Adminpage/Adminpage';
import Adminactionbar from './Components/Adminpanel/Adminsidebar/Adminactionbar';
import Landingmain from './Components/Landingpage/Landingmain/Landingmain';
import Privacypolicy from './Components/Landingpage/Landingmain/Privacypolicy';
import Terms from './Components/Landingpage/Landingmain/Terms';
import Supersidebar from './Components/SuperAdminPanel/Supersidebar/Supersidebar';
import Superadminhome from './Components/SuperAdminPanel/Elements/Superadminhome';
import Admindashboard from './Components/Adminpanel/Admindashboard/Admindashboard';
import Adminsettings from './Components/Adminpanel/Adminsettings/Adminsettings';
import Mytemplates from './Components/Adminpanel/Admindashboard/Mytemplates';
import NewTemplates from './Components/Adminpanel/Admindashboard/NewTemplates';
import FacebookLogin from './Components/Authentication/FacebookLogin';
import EmployeeLogin from './Components/Authentication/Login';
import Signup from './Components/Authentication/Signup';
import Admincrm from './Components/Adminpanel/Adminpage/Admincrm';
import Home from './Components/Landingpage/Home/Home';
import Navbar from './Components/Landingpage/Navbar/Navbar';
import { useMediaQuery } from 'rsuite/esm/useMediaQuery/useMediaQuery';
import Superadminlogin from './Components/Authentication/SuperadminLogin';
import Employeeverify from './Components/Authentication/Employeeverify';

import { PersistGate } from 'redux-persist/integration/react'
import Mainsignup from './Components/Authentication/MainSignup';
import Forgetpassword from './Components/Authentication/Forgetpassword';
import Chatbotchats from './Components/Adminpanel/Chatbotchats/Chatbotchats';
import Adminchatbotdashboard from './Components/Adminpanel/Adminchatbotdashboard/Adminchatbotdashboard';
import Createqa from './Components/Adminpanel/Adminchatbotdashboard/Createquestionanswer/Createqa';
import Chatbotsidebar from './Components/Adminpanel/Chatbotchats/Chatbotsidebar'
import Pathdecider from './Components/Pathdecider/Pathdecider';

function App() {
  const isMobile=useMediaQuery('(max-width:412px)');
  return <>

  <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
  <BrowserRouter>
  <Routes>
    <Route element={<Navbar/>}>
    <Route path='/' element={<Home/>}/>
    <Route path='/privacypolicy' element={<Privacypolicy/>}/>
      <Route path='/termsandconditions' element={<Terms/>}/>
      <Route path='/facebooklogin'  element={<FacebookLogin/>}/>
        <Route path='/login' element={<EmployeeLogin/>}/>
        <Route path='/signup' element={<Mainsignup/>}/>
        <Route path='/verifyadmin' element={<Signup/>}/>
        <Route path='/forgetpassword' element={<Forgetpassword/>}/>
        <Route path='employeeverify' element={<Employeeverify/>}/>
        <Route path='/superadminlogin' element={<Superadminlogin/>}/>
    </Route>

    <Route element={<Actionbar/>}>
      <Route path='/dashboard' element={<Dashboard/>}/>
      <Route path='/chats' element={<Crmpage/>}/>
      <Route path='/notes' element={<Notes/>}/>
      <Route path='/settings' element={<Settings/>}/>
    </Route>
    <Route element={<Adminactionbar/>}>
     {/* for responsive */}
      <Route path='/admin/chats' element={<Adminsidebar/>} />
      <Route path='/admin/users' element={<Admincrm/>} />
      <Route path='/chatbotchats' element={<Chatbotchats/>}/>
      <Route path='/chatbotdashboard' element={<Adminchatbotdashboard/>}/>
      <Route path='/chatbotflow' element={<Createqa/>}/>
      <Route path='/admindashboard' element={<Admindashboard/>}/>
      <Route path='/adminsettings' element={<Adminsettings/>}/>
    </Route>
      <Route path='/chatbot/chats' element={<Chatbotsidebar/>}/>
    <Route>
   
      <Route path='/chatbox' element={<Chatbotchats/>}/>
      
    </Route>
      <Route element={<Supersidebar/>}>
        <Route path='/superadmin' element={<Superadminhome/>}/>
      </Route>
      <Route path='/selectservice' element={<Pathdecider/>}/>
  </Routes>
  
  </BrowserRouter>
  </PersistGate>
  </Provider>
  </>
}

export default App;
