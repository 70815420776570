import React, { useEffect, useState, useRef } from "react";

import "./employeelogin.css";
import "./facebooklogin.css";
import { auth } from "../Firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setemployeeauth, setallchats } from "../Reduxstore/store";

import { signInWithEmailAndPassword } from "firebase/auth";
import { setphoneid } from ".././Reduxstore/store";
import HALO from "vanta/dist/vanta.halo.min";
import * as THREE from "three";
import { useMediaQuery, useModelState } from "../Custom_hooks/Custom";

const EmployeeLogin = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:412px)");
  const [isadmin, setisadmin] = useState(false);
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [error, seterror] = useState();
  const employeeauth = useSelector((state) => state.employeeauth);
  const [employeelogindetails, setemployeelogindetails] = useState(null);
  const [issuperadmin, setissuperadmin] = useState(false);

  const handlefindlogintype = async () => {
    const response = await fetch(
      `https://do.zopins.com/findlogintype/${email}/${password}`,
      { method: "GET" }
    );
    const data = await response.json();
    console.log(data);
    if (data.validated === "admin") {
      localStorage.setItem("adminauthid", data.doc.auth_id);
      return handleadminlogin();
      //  navigate('/admindashboard')
    } else if (data.validated === "employee") {
      return handleemployeelogin();
    }

    return seterror({ message: "Database:Error(auth/No user found.)" });
  };

  const handleadminlogin = async () => {
    // signInWithEmailAndPassword(auth, email, password)
    // .then(async(result)=>{
    const response = await fetch(
      `https://do.zopins.com/getcompany/${localStorage.getItem("adminauthid")}`
    );
    const data = await response.json();
    console.log(data);
    if (data.validated === true) {
      localStorage.setItem("isadminAuthenticated", "true");
      navigate("/selectservice");
    } else {
      seterror({ message: "Database:Error(auth/No user found.)" });
    }
    // })
    // .catch(error=>{
    //   seterror(error);
    // })
  };

  const handleemployeelogin = async () => {
    const response = await fetch(
      `https://do.zopins.com/employeelogin/${email}/${password}`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    console.log(data.validated);
    if (data.validated) {
      console.log("data", data.employeedetails.assigned_clients);
      dispatch(setallchats(data.employeedetails.assigned_clients));
      dispatch(setemployeeauth(data));
      dispatch(setphoneid(data.EmployeeLogin));
      setemployeelogindetails(data);
      console.log("auth in sidebar", data);
      navigate("/chats");
    } else {
      seterror({ message: "Database:Error(auth/No user found.)" });
    }
  };

  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);
  useEffect(() => {
    // if(isMobile){
    //   if (!vantaEffect) {
    //     setVantaEffect(HALO({
    //       THREE,
    //       el: myRef.current,
    //       mouseControls: true,
    //       touchControls: true,
    //       gyroControls: false,
    //       minHeight: 200.00,
    //       minWidth: 200.00,
    //       amplitudeFactor: 2,
    //       xOffset: 0,
    //       yOffset: -0.00005,
    //       size: 0.3
    //     }))
    //   }
    // }
    // else{
    //   if (!vantaEffect) {
    //     setVantaEffect(HALO({
    //       THREE,
    //       el: myRef.current,
    //       mouseControls: true,
    //       touchControls: true,
    //       gyroControls: false,
    //       minHeight: 200.00,
    //       minWidth: 200.00,
    //       amplitudeFactor: 2,
    //       xOffset: 0.25,
    //       yOffset: -0.05,
    //       size: 1.8
    //     }))
    //   }
    // }
    // return () => {
    //   if (vantaEffect) vantaEffect.destroy()
    // }
  }, []);
  return (
    <>
      <div className="mainlogincontainer"
      style={!isMobile?{height:'100vh'}:{}}
      >
        <div className="logincontainer" style={{ zIndex: "2000" }}>
          <div className="loginhead eloginhead">Login</div>
          {error ? (
            <>
              <div className=" ebox2">
                {error.message.split("/")[1].split(")")[0]}
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="loginform lgin">
            <input
              type="email"
              placeholder="Email"
              className="employeeauthinput"
              value={email}
              onChange={(ev) => setemail(ev.target.value)}
              onInputCapture={(ev) => {
                setemail(ev.target.value);
              }}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              className="employeeauthinput"
              value={password}
              onChange={(ev) => setpassword(ev.target.value)}
              onInputCapture={(ev) => {
                setpassword(ev.target.value);
              }}
            />
          </div>

          <button onClick={handlefindlogintype} className="loginbtn">
            <span className="">Login</span>
          </button>
          <div
            className="forgetpassword"
            onClick={() => {
              navigate("/forgetpassword");
            }}
          >
            Forget Password {">"}
          </div>

        </div>
        <div
          ref={myRef}
          className="homebackground"
          style={{ zIndex: "200" }}
        ></div>
        <div className="loginstyle">
          <img src="https://i.postimg.cc/zGG2PTZh/newloginpic-removebg.png" alt="login img" className='loginimage' />
        </div>
      </div>
    </>
  );
};

export default EmployeeLogin;
