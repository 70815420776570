import React, { useEffect, useState } from 'react'

import './employeelogin.css'
import './facebooklogin.css'
import { auth } from '../Firebase/firebase'
import {useNavigate} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux';
import {setemployeeauth,setallchats} from '../Reduxstore/store'

import {signInWithEmailAndPassword } from "firebase/auth";
import {setphoneid} from '.././Reduxstore/store'

import {setissupervalidated} from '../Reduxstore/store'


const Superadminlogin = () => {
  const dispatch=useDispatch();
    const navigate=useNavigate();
    const [email,setemail]=useState('');
    const [password,setpassword]=useState('');
    const [error,seterror]=useState();
 

    const handlesuperadminlogin=async()=>{
        if(!email && !password){
           return  seterror(
                {message:'Database:Error(auth/Invalid email or password.)'} 
                )
        }
        const response=await fetch(`https://do.zopins.com/superadminlogin/${email}/${password}`)
         const data=await response.json();
         console.log(data);
         dispatch(setissupervalidated(data.validated))
        //  setissuperadmin(data.validated)
         if(data.validated){
            localStorage.setItem('issupervalidated',true);
          navigate('/superadmin')
         }
         else{
            seterror(
              {message:'Database:Error(auth/Invalid email or password.)'} 
              )
         }
    }
    useEffect(()=>{
        localStorage.setItem('issupervalidated',false)
    },[])
  return <>
  <br /><br />
     <div className='mainlogincontainer'>
  <div className='logincontainer'>
    
<div className='loginhead eloginhead'>Login</div>
{
        error?<>
        <div className=' ebox2'>{((error.message).split('/')[1]).split(')')[0]}</div>
        </>:<>
        
        </>
     }
    
<div className='loginform'>
   <input type="email" placeholder='Email' className='employeeauthinput'
     value={email}
     onChange={(ev)=>setemail(ev.target.value)}
     onInputCapture={(ev)=>{setemail(ev.target.value)}}
   />
</div>
<div>
   <input type="password" placeholder='Password' className='employeeauthinput' 
     value={password}
     onChange={(ev)=>setpassword(ev.target.value)}
     onInputCapture={(ev)=>{setpassword(ev.target.value)}}
   />
</div>
 
     <button onClick={handlesuperadminlogin} className='loginbtn loginadmin'>
     <span className=''>
    Login
        </span>
        </button>
 
   
   
        
    
        
         
  </div>
  <div className='loginstyle'>
    <img src="https://i.postimg.cc/zGG2PTZh/newloginpic-removebg.png" alt="login img" className='loginimage' />
  </div>
  </div>
  
  </>
    
}

export default Superadminlogin
