// import { useCallback, useState } from 'react';
// import { Handle, Position,NodeToolbar} from 'reactflow';

// import { useSelector,useDispatch} from 'react-redux';
// import {setainodes,setnewnodes} from '../../Reduxstore/store';
// import {nodes} from './Aicustomhook'

// const handleStyle = { left: 10 };

// function Outgoingtextmessage({ data, isConnectable }) {
//     const dispatch=useDispatch();
//     const [text,settext]=useState('');
//     let ainodess=useSelector(state=>state.user.ainodes);
//     const [savedtext,setsavedtext]=useState('');
//     const [issaved,setissaved]=useState(false);

//   const onChange =(evt) => {
//     settext(evt.target.value);
//     console.log(evt.target.value)
//   };

//   const handlesavetext=()=>{
//     setsavedtext(text)
//     console.log(savedtext);
//     setissaved(!issaved);
//     OnNodeUpdate()
//   }
//   const OnNodeUpdate=async()=>{

//     const updatedNodes =ainodess.map(node => {
//         if (node.data.id === data.id) {
//             return {
//                 ...node,
//                 data: {
//                   ...node.data,
//                   message:{
//                     type:'text',
//                     value:text
//                   }
//                 }
//               };
//         }
//         return node;
//       });
//       console.log(updatedNodes)
//       dispatch(setainodes(updatedNodes));
//     settext('')
//     console.log(data);
//   }

//   return (
//     <div className="text-updater-node" >
//       <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
//       <div className='simpletextaitemp'>
//         <div className='incomingtextmessage'>Outgoing Text Message</div>
//         <div className='simpletext'>
//         {data.message.value}
//         </div>
//         <div htmlFor="text" className='simpletextlable'>Message</div>
//         <textarea id="text" name="text"
//          onChange={onChange} 

//          className="textinput" 
//          value={text} />

//       <button className='savetextbtn' 
//      onClickCapture={()=>{handlesavetext()}}
//      onClick={()=>{handlesavetext()}}
//      >Save</button>
//       </div>
//       <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
//     </div>
//   );
// }

// export default Outgoingtextmessage;


import { useCallback, useState,useEffect } from 'react';
import { Handle, Position} from 'reactflow';


import {useCustomState} from './Aicustomhook'
import { initialEdges, initialNodes } from './node-and-edges'; 
import { useSelector,useDispatch} from 'react-redux';
import {setainodes,setnewnodes} from '../../Reduxstore/store'

const handleStyle = { left: 10 };


function Outgoingtextmessage({ data, isConnectable }) {
    const dispatch=useDispatch();
    let ainodess=useSelector(state=>state.user.ainodes);
    const [text,settext]=useState('');
    const [savedtext,setsavedtext]=useState('');
    const [issaved,setissaved]=useState(false);

  const onChange = (ev) => {
    settext(ev.target.value);
    console.log(ev.target.value)
  };

  const handlesavetext=()=>{
      setsavedtext(text)
      setissaved(!issaved);
      OnNodeUpdate();
  }

  const OnNodeUpdate=async()=>{
    // const id=data.id;
     const newMessage=text;
    // dispatch(updateMessage({id,newMessage}));
    const updatedNodes =ainodess.map(node => {
        if (node.data.id === data.id) {
            return {
                ...node,
                data: {
                  ...node.data,
                  message: {
                    type:'text',
                    value:newMessage
                  }
                }
              };
        //  return { ...node, data: { ...node.data, message: newMessage } };
        }
        return node;
      });
      console.log(updatedNodes)
      
      dispatch(setainodes(updatedNodes));
    settext('')
  }


  return (
    <div className="text-updater-node"
    >
      
      <Handle type="target" position={Position.Top} isConnectable={isConnectable}
      style={{backgroundColor:'white',border:'2px solid green'}}
      />
      <div className='simpletextaitemp'>
        <div className='incomingtextmessage'>Outgoing Text Message</div>
        <div className='simpletext'>
        {data.message.value}
        </div>
        <div htmlFor="text" className='simpletextlable'>Message</div>
        <textarea id="text" name="text" onChange={onChange} className="textinput" value={text}
        />
      <button className='savetextbtn' 
     // onClick={()=>{handlesavetext()}}
      onClickCapture={()=>{handlesavetext()}}
      >Save</button>


      </div>
      <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable}
      style={{backgroundColor:'white',border:'2px solid green'}}
      />
    </div>
  );
}

export default Outgoingtextmessage;

