
import React, { useState,useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setPhoneNumber,setName,setUserChat,setselectedcustomer,setbotchats} from '../../Reduxstore/store';
import {useModelState,useMediaQuery} from '../../Custom_hooks/Custom'
import Drawercomp from '../../Userpanel/Crmpage/Drawer';
import '../../Userpanel/Sidebar/Sidebar.css'

import socket from '../Adminpage/Socket';

import './Chatbot.css'

import {useNavigate} from 'react-router-dom'

const Adminsidebar = ({setlatestchats}) => {

  const isMobile=useMediaQuery('(max-width:412px)');
  const navigate=useNavigate();
  const {isOpen,open,close}=useModelState();

    const [sname,setsname]=useState('');
    const [filtered,setfiltered]=useState([]);
  const phoneNumber = useSelector(state => state.user.phoneNumber);
  const allchats = useSelector(state => state.user.botchats);
  const dispatch = useDispatch();
  const firebaseauthid=localStorage.getItem('adminauthid')

  const [newclientname,setnewclientname]=useState('');
  const [newclientphone,setnewclientphone]=useState('');
  const [newclientphoneid,setnewclientphoneid]=useState('');
  const [message,setmessage]=useState('');
  const [newchat,setnewchat]=useState();
  const [error,seterror]=useState('');

const handlesearched=()=>{
  const client=allchats.visitors.filter((c)=>((c.visitorid).toUpperCase()).includes((sname).toUpperCase() ) || (c.visitorid).includes(sname));
   setfiltered(client);
  console.log(filtered);

}


useEffect(()=>{

   if(localStorage.getItem('isadminAuthenticated')!=='true'){
   return navigate('/login');
   }
   else{
    fetchadmin();
   // fetchnewchats()
   }
   async function fetchadmin(){
  console.log(allchats);
  if(!isMobile)
  setlatestchats([]);
  }


},[phoneNumber])

const handleparticularchats=(phonenumber)=>{

  const particularchats=allchats.visitors.filter(visitor=>visitor.visitorid===phonenumber);
  console.log('particular chat',particularchats[0]);
  dispatch(setUserChat(particularchats[0]));
  if(isMobile)
  navigate('/chatbox');
  }

async function fetchnewchats(){
    const response=await fetch(`https://do.zopins.com/getbots/${firebaseauthid}`,{
        method:'GET',
        headers:{
          'Access-Control-Allow-origin':'*'
        }
    })
    const data=await response.json();
    console.log('bot chats',data);
   dispatch(setbotchats(data));
    console.log('all chats',allchats);

   
}

const handleneaddnewcustomer=()=>{
  
}
 const getlatestchats=async()=>{
    const response=await fetch(`https://do.zopins.com/getdomainchats/${allchats.app_token}`,{
        method:'GET',
        headers:{
          'Access-Control-Allow-origin':'*'
        }
    })
    const data=await response.json();
    dispatch(setbotchats(data));
    console.log(data);
}

const getlastmessage=(chat)=>{

    const chatlength=chat.length;
    const date=new Date(chat[chatlength-1].body.timeStamp)
    const newmessage=chat[chatlength-1].body.message+' @ '+date.toLocaleTimeString() + '#' +date.toLocaleDateString()
     console.log(newmessage)
     return newmessage

}
const gettemplastmessage=(chat)=>{

  const chatlength=chat.length;
  const date=new Date(chat[chatlength-1].timestamp)
 // const newmessage2=(chat[chatlength-1].timestamp).toLocaleTimeString() + "@" + (chat[chatlength-1].timestamp).toLocaleDateString()
  const newmessage=date.toLocaleTimeString() + '#' +date.toLocaleDateString()
   console.log(newmessage)
   return newmessage

}

  return <>
    {newchat && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="close-button" onClick={()=>{setnewchat(!newchat)}}>Close</button>
             <div className='newcustomerconainer'>
                <input type="text" placeholder='Client Name' className='newclientinputs' required 
                value={newclientname}
                 onChange={(ev)=>{setnewclientname(ev.target.value)}}
                 onInputCapture={(ev)=>{setnewclientname(ev.target.value)}}
                />
                <input type="text" placeholder='Client Phone Number' className='newclientinputs' required
                 value={newclientphone}
                 onChange={(ev)=>{setnewclientphone(ev.target.value)}}
                 onInputCapture={(ev)=>{setnewclientphone(ev.target.value)}}
                />
                <input type="text" placeholder='Client Phone Number Id' className='newclientinputs' required
                 value={newclientphoneid}
                 onChange={(ev)=>{setnewclientphoneid(ev.target.value)}}
                 onInputCapture={(ev)=>{setnewclientphoneid(ev.target.value)}}
                />
                 <div className='addnewchatbtn'
                  onClick={handleneaddnewcustomer}
                 >
                  {message===''?<>
                  Add New Chat
                  </>:
                  message==='creating'?
                  <>
                  <div className='loader-container'>
                      <div className='loader'></div>
                      </div>
                  </>:
                  message==='created'?
                  <>
                  client created successfully
                  </>:
                  <>Add New Chat</>}
                 </div>

                
             </div>
            
          </div>
        </div>
      )}
     <Drawercomp isOpen={isOpen} close={close}/>
    <div className='main-search-container'>
        <span className='chatbotname'>
        <img
                      width="48"
                      height="48"
                      src="https://img.icons8.com/fluency-systems-regular/48/FFFFFF/long-arrow-left.png"
                      alt="long-arrow-left"
                      // className="backarrow"
                      className='backtobotdashboardicon'
                      onClick={() => {
                        navigate('/chatbotdashboard');
                      }}
                    />
        {/* <img width="48" height="48" src="https://img.icons8.com/fluency/48/left.png" alt="left" className='backtobotdashboardicon'
        onClick={()=>{navigate('/chatbotdashboard')}}
        /> */}
            {allchats.admin} Bot Chats </span>
    <div  
    onClick={()=>{setnewchat(!newchat)}}
    >
      {/* <img width="48" height="48" src="https://img.icons8.com/fluency/48/add-to-chat.png" alt="add-to-chat" className='newchatbtn'/> */}
      </div>
      <div className='search-container'>
      <input type="text" className='search' placeholder='Search...'
         value={sname}
         onChange={(ev)=>{
          setsname(ev.target.value);
          handlesearched();
      }}
         onInputCapture={(ev)=>{
           setsname(ev.target.value);
           handlesearched();
   
         }}
        />
         <svg xmlns="http://www.w3.org/2000/svg" style={{fill:'#031218'}} className='searchicon'  width="100" height="100" viewBox="0 0 24 24">
<path d="M 10 2 C 5.5965257 2 2 5.5965291 2 10 C 2 14.403471 5.5965257 18 10 18 C 11.752132 18 13.370523 17.422074 14.691406 16.458984 L 19.845703 21.613281 A 1.250125 1.250125 0 1 0 21.613281 19.845703 L 16.458984 14.691406 C 17.422074 13.370523 18 11.75213 18 10 C 18 5.5965291 14.403474 2 10 2 z M 10 4.5 C 13.052375 4.5 15.5 6.947627 15.5 10 C 15.5 13.052373 13.052375 15.5 10 15.5 C 6.9476251 15.5 4.5 13.052373 4.5 10 C 4.5 6.947627 6.9476251 4.5 10 4.5 z"></path>
</svg>
      </div>

    </div> 
    <div className='listcontacts' >

    </div>
  <div className=' sidebar'
  style={!isMobile?{marginLeft:'-95%',marginTop:'16vh',height:'84vh'}:{}}
  >
  {filtered.map((contact,index)=>{
                return <>
                   <div key={index}  className='particularemployeesearched' >

                   <div className='maincontactname'
                    key={index}
                    onClick={()=>{
                        dispatch(setselectedcustomer(contact))
                        handleparticularchats(contact.visitorid);
                        dispatch(setPhoneNumber(contact.visitorid));
                        dispatch(setName(contact.visitorid));
                        getlatestchats()
                       socket.emit('join',{authid:phoneNumber}) 
                    }}
                    
                    style={phoneNumber===contact.sender_phone_number?{backgroundColor:'#06384e',borderRadius:'.4vw'}:{}}
                    >
                     <div className='contacticon'>{(((contact.visitorid).split('-')[1]).substring(0,1)).toUpperCase()}</div>
                    <div className='contact'> 
                    {
                     (contact.visitorid).length>=14?<>
                      {
                        // (((contact.visitorid).split('-')[1]).toUpperCase()).substring(0,15)
                         (((contact.visitorid).split('-')[1]).toUpperCase()).substring(0,14)
                      }....
                      </>:<>
                        {(((contact.visitorid).split('-')[1]).toUpperCase())}
                        {/* {(((contact.visitorid).split('-')[1]).toUpperCase())}  */}
                        
                      </>
                    
                    }
                     </div>
                    
                    </div>
                   </div>
                </>
            })}
    
            {allchats.visitors && allchats.visitors.map((contact,index)=>{
                return <>
                   <div key={index} >
                   <div className='maincontactname'
                    onClick={()=>{
                      dispatch(setselectedcustomer(contact))
                      handleparticularchats(contact.visitorid);
                      dispatch(setPhoneNumber(contact.visitorid));
                      dispatch(setName(contact.visitorid));
                      getlatestchats()
                     socket.emit('join',{authid:phoneNumber}) 
                    }}
                    style={contact.visitorid===phoneNumber?{backgroundColor:'#06384e',borderRadius:'4vw',display:'block'}:{display:'block'}}
                    >
                    <div style={!isMobile?{display:'flex'}:{display:'flex'}}>

                    <div className='contacticon'>{(((contact.visitorid).split('-')[1]).substring(0,1)).toUpperCase()}</div>
                    <div className='contact'> 

                     {
                     (contact.visitorid).length>=14?<>
                      {
                        // (((contact.visitorid).split('-')[1]).toUpperCase()).substring(0,15)
                         (((contact.visitorid).split('-')[1]).toUpperCase()).substring(0,14)
                      }....
                      </>:<>
                        {(((contact.visitorid).split('-')[1]).toUpperCase())}
                        {/* {(((contact.visitorid).split('-')[1]).toUpperCase())}  */}
                        
                      </>
                    
                    }
                     </div>
                     </div>
                     {contact.chat[contact.chat.length-1].type==="text" || contact.chat[contact.chat.length-1].type==="aicontent"  ?<>
                     {
                      (getlastmessage(contact.chat).split('@')[0]).length>=40 ?<>
                      <span className='lastmessagepreview'>{(getlastmessage(contact.chat).split('@')[0]).substring(0,40)}....</span>
                      
                      </>:<>
                      <span className='lastmessagepreview'>{(getlastmessage(contact.chat).split('@')[0])}</span>

                      </>
                     }
                     <div className='lastmessagepreview'>{(getlastmessage(contact.chat).split('@')[1]).split('#')[0]} ({(getlastmessage(contact.chat).split('#')[1])})</div>  
                     </>
                     :
                     <>
                     <div className='lastmessagepreview'> 
                      Template Message
                     </div>
                     <div className='lastmessagepreview'>
                     {(gettemplastmessage(contact.chat).split('#')[0])} ({(gettemplastmessage(contact.chat).split('#')[1])})
                     </div>
                     </>}
                    </div>
                   </div>
                   
                </>
            })}
  </div>
  </>
}

export default Adminsidebar
