import React, { useState } from 'react'
import Actionbar from '../actionbar/Actionbar'
import './Dashboard.css'
import NewTemplates from './NewTemplates'

const Dashboard = () => {
  const [active,setactive]=useState('analytics')
  return <>
    <div className='maindashcontainer'>
    {/* <div className='dashboardhead'>Dashboard</div> */}
    <div className='dashnav'>
    <div className='dele' onClick={()=>setactive('analytics')} style={active==='analytics'?{backgroundColor:'#06435b',border:'1px solid white'}:{}}> Data Analytics</div>
     <div className='dele' onClick={()=>setactive('newtemp')} style={active==='newtemp'?{backgroundColor:'#06435b',border:'1px solid white'}:{}}>New Templates</div>
     <div className='dele' onClick={()=>setactive('mytemp')} style={active==='mytemp'?{backgroundColor:'#06435b',border:'1px solid white'}:{}}>My Templates</div>
     <div className='dele'></div>
     <div className='dele'></div>
     <div className='dele'></div>
    </div>
    </div>
    {active==='newtemp' && <NewTemplates/>}
  </>
}

export default Dashboard
