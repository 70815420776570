import React, { useEffect, useState } from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import './Actionbar.css';
import {useSelector,useDispatch} from 'react-redux';
import { setName,setPhoneNumber,setemployeeauth } from '../../Reduxstore/store';

import styled, { keyframes } from 'styled-components';
const fadeInAnima = keyframes`
  from {
    opacity: 0;
    transform: translateX(-5vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Styled component with animation
const AnimatedContainer = styled.div`
  animation: ${fadeInAnima} 0.55s ease-in-out;
`;

const Actionbar = () => {
  const dispatch=useDispatch();
    const navigate=useNavigate();
    const [active,setactive]=useState('chats')
    const handlenavigation=(action,path)=>{
        // setactive(action);
        navigate(path)
        dispatch(setName(''));
        dispatch(setPhoneNumber(''));

    }

    const handlelogout=async ()=>{
       dispatch(setemployeeauth({validated:false}))
      dispatch(setPhoneNumber(''));
        navigate('/login');
    }

  return <>
  <AnimatedContainer>
    <div className='actionbar'>
    <div className='actionelements'>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 72 72"
 className='hamburgermenu'


 onClick={()=>{setactive('dashboard');handlenavigation('dashboard','/dashboard')}}
style={active==='dashboard'?{fill:"#16aee8",backgroundColor:'#094962',borderRadius:'.3vw'}:{fill:"#16aee8"}}
>
<path d="M56 48c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 48 54.798 48 56 48zM56 32c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 32 54.798 32 56 32zM56 16c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 16 54.798 16 56 16z"></path>
</svg>
<img width="48" height="48" src="https://img.icons8.com/fluency/48/chat--v1.png" alt="chat--v1" className='hamburgermenu'
onClick={()=>{setactive('chats');handlenavigation('chats','/chats')}}

title='chats'
style={active==='chats'?{fill:"#16aee8",backgroundColor:'#094962',borderRadius:'.3vw'}:{fill:"#16aee8"}}
/>
<img width="50" height="50" src="https://img.icons8.com/ios-filled/50/16aee8/task.png" alt="task" className='hamburgermenu'
onClick={()=>{setactive('notes');handlenavigation('notes','/notes')}}
title='my chat notes'
style={active==='notes'?{fill:"#16aee8",backgroundColor:'#094962',borderRadius:'.3vw'}:{fill:"#16aee8"}}

/>
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50"
onClick={()=>{setactive('settings');handlenavigation("settings",'/settings')}}

style={active==='settings'?{fill:"#16aee8",backgroundColor:'#094962',borderRadius:'.3vw'}:{fill:"#16aee8"}} 
className='hamburgermenu'>
    <path d="M47.16,21.221l-5.91-0.966c-0.346-1.186-0.819-2.326-1.411-3.405l3.45-4.917c0.279-0.397,0.231-0.938-0.112-1.282 l-3.889-3.887c-0.347-0.346-0.893-0.391-1.291-0.104l-4.843,3.481c-1.089-0.602-2.239-1.08-3.432-1.427l-1.031-5.886 C28.607,2.35,28.192,2,27.706,2h-5.5c-0.49,0-0.908,0.355-0.987,0.839l-0.956,5.854c-1.2,0.345-2.352,0.818-3.437,1.412l-4.83-3.45 c-0.399-0.285-0.942-0.239-1.289,0.106L6.82,10.648c-0.343,0.343-0.391,0.883-0.112,1.28l3.399,4.863 c-0.605,1.095-1.087,2.254-1.438,3.46l-5.831,0.971c-0.482,0.08-0.836,0.498-0.836,0.986v5.5c0,0.485,0.348,0.9,0.825,0.985 l5.831,1.034c0.349,1.203,0.831,2.362,1.438,3.46l-3.441,4.813c-0.284,0.397-0.239,0.942,0.106,1.289l3.888,3.891 c0.343,0.343,0.884,0.391,1.281,0.112l4.87-3.411c1.093,0.601,2.248,1.078,3.445,1.424l0.976,5.861C21.3,47.647,21.717,48,22.206,48 h5.5c0.485,0,0.9-0.348,0.984-0.825l1.045-5.89c1.199-0.353,2.348-0.833,3.43-1.435l4.905,3.441 c0.398,0.281,0.938,0.232,1.282-0.111l3.888-3.891c0.346-0.347,0.391-0.894,0.104-1.292l-3.498-4.857 c0.593-1.08,1.064-2.222,1.407-3.408l5.918-1.039c0.479-0.084,0.827-0.5,0.827-0.985v-5.5C47.999,21.718,47.644,21.3,47.16,21.221z M25,32c-3.866,0-7-3.134-7-7c0-3.866,3.134-7,7-7s7,3.134,7,7C32,28.866,28.866,32,25,32z"></path>
</svg>
<img width="50" height="50" src="https://img.icons8.com/ios-filled/50/16aee8/logout-rounded-left.png" alt="logout-rounded-left" className='logout'
onClick={handlelogout}
/>
    </div>
      </div>
      </AnimatedContainer>
      <Outlet/>
  </>
}

export default Actionbar
